export const global_config = {
    INACTIVITY_DURATION : 30,
    LOCKOUT_DURATION : 30,

    PUSHER_BROADCASTER: process.env.VUE_APP_WS_BROADCASTER,
    PUSHER_KEY: process.env.VUE_APP_WS_PUSHER_KEY,
    PUSHER_HOST: process.env.VUE_APP_WS_PUSHER_HOST,
    PUSHER_PORT: process.env.VUE_APP_WS_PUSHER_PORT,
    PUSHER_FORCE_TLS: process.env.VUE_APP_WS_PUSHER_TLS,
    PUSHER_DISABLED_STATES: process.env.VUE_APP_WS_PUSHER_STATES,

    GAME_STARTUP_DURATION : 180,
    
    THEME_CLASSES: ['PurpleTheme', 'BlueTheme', 'GreenTheme', 'LimeTheme', 'OrangeTheme', 'PinkTheme', 'RedTheme', 'TealTheme', 'YellowTheme'],
    LOGO_EXTENSIONS : ['image/jpeg', 'image/jpg', 'image/png','image/gif'],
	LIVECHAT_LICENSE: process.env.VUE_APP_LIVECHAT_LICENSE, //'12332502',
    MESSAGES: {
        "flask_error" : "Flask API server doesn't work now!",
        "ip_not_allowed" : "The user agent and Ip is not allowed to this Client!<br>Need to start again by the admin manager!",
        "pod_need_deployment" : "Please deploy this POD first!",
        "multi_navs_login" : "You only have permission to be logged in on one Gamepod at a time. Would you like to play here instead and logout of",
        "unable_to_login" : "Unable to log you in, please try again later!",
        "remote_not_verified" : "Unable to verify the remote id you provided!",
        "remote_verifying" : "Please wait, we are validating your remote id!",
        "required_message" : "Please fill all required fields",
        "register_error_message" : "Please fill all required highlighted fields",
        "password_success" : "Your password has been changed successfully, login now",
        "error_class" : "error",
        "success_class" : "success",
        "warning_class" : "warning",
    },
    XD_ICONS: {
        'xb' : {'icon' : 'fa-brands fa-xbox', 'text' : 'Play Xbox', 'xd' : 'Xbox'},
        'ps' : {'icon' : 'fa-brands fa-playstation', 'text' : 'Play PS5', 'xd' : 'PS5'},
        'sw' : {'icon' : 'fa-brands fa-bilibili', 'text' : 'Play Switch', 'xd' : 'Switch'},
        'pc' : {'icon' : 'fa-brands fa-windows', 'text' : 'Play PC', 'xd' : 'PC'},
        'disabled': 'Locked'
    },
    ACTIVITIES: {
        'IDLE' : 'session_idle',
        'UI_HOME': 'session_ui_home',
        'UI_SIGNUP': 'session_ui_signup',
        'UI_PASSWORD': 'session_ui_password',
        'UI_ALERTS': '',
        'UI_DASHBOARD': 'session_ui_dashboard',
        'UI_GAMES': 'session_ui_games',
        'UI_BUY': 'session_ui_buy',
        'UI_XP': 'session_ui_xp',
        'UI_LOGOUT': 'session_ui_logout',
        'PLAY_SAVE': 'session_play_save',
        'PLAY_XBOX': 'session_play_xbox',
        'PLAY_PS5': 'session_play_ps5',
        'PLAY_SWITCH': 'session_play_switch',
        'PLAY_PC': 'session_play_pc',
        'PLAY_ARCADE': 'session_play_arcade',
        'END': 'session_end',
        'ASLEEP': 'session_asleep',
        'UI_REMOTE': 'session_ui_remote',
        'UI_ADMIN': 'session_admin',
    },
    TIMEZONES_LIST: [
        {'id': '', 'value': 'Select a timezone'},
        {'id' : 'America/Adak', 'value' : 'America/Adak'},
        {'id' : 'America/Anchorage', 'value' : 'America/Anchorage'},
        {'id' : 'America/Boise', 'value' : 'America/Boise'},
        {'id' : 'America/Chicago', 'value' : 'America/Chicago'},
        {'id' : 'America/Denver', 'value' : 'America/Denver'},
        {'id' : 'America/Detroit', 'value' : 'America/Detroit'},
        {'id' : 'America/Indiana/Indianapolis', 'value' : 'America/Indiana/Indianapolis'},
        {'id' : 'America/Indiana/Knox', 'value' : 'America/Indiana/Knox'},
        {'id' : 'America/Indiana/Marengo', 'value' : 'America/Indiana/Marengo'},
        {'id' : 'America/Indiana/Petersburg', 'value' : 'America/Indiana/Petersburg'},
        {'id' : 'America/Indiana/Tell_City', 'value' : 'America/Indiana/Tell_City'},
        {'id' : 'America/Indiana/Vevay', 'value' : 'America/Indiana/Vevay'},
        {'id' : 'America/Indiana/Vincennes', 'value' : 'America/Indiana/Vincennes'},
        {'id' : 'America/Indiana/Winamac', 'value' : 'America/Indiana/Winamac'},
        {'id' : 'America/Juneau', 'value' : 'America/Juneau'},
        {'id' : 'America/Kentucky/Louisville', 'value' : 'America/Kentucky/Louisville'},
        {'id' : 'America/Kentucky/Monticello', 'value' : 'America/Kentucky/Monticello'},
        {'id' : 'America/Los_Angeles', 'value' : 'America/Los_Angeles'},
        {'id' : 'America/Menominee', 'value' : 'America/Menominee'},
        {'id' : 'America/Metlakatla', 'value' : 'America/Metlakatla'},
        {'id' : 'America/New_York', 'value' : 'America/New_York'},
        {'id' : 'America/Nome', 'value' : 'America/Nome'},
        {'id' : 'America/North_Dakota/Beulah', 'value' : 'America/North_Dakota/Beulah'},
        {'id' : 'America/North_Dakota/Center', 'value' : 'America/North_Dakota/Center'},
        {'id' : 'America/North_Dakota/New_Salem', 'value' : 'America/North_Dakota/New_Salem'},
        {'id' : 'America/Phoenix', 'value' : 'America/Phoenix'},
        {'id' : 'America/Shiprock', 'value' : 'America/Shiprock'},
        {'id' : 'America/Sitka', 'value' : 'America/Sitka'},
        {'id' : 'America/Yakutat', 'value' : 'America/Yakutat'},
        {'id' : 'Pacific/Honolulu', 'value' : 'Pacific/Honolulu'},
    ],
    
    QR_MAIN_TIMEOUT: 60,
    QR_EXTRA_TIMEOUT: 60,
    QR_MAX_TIMEOUT: 99,
    GAME_TYPES: [
        {'id': '', 'value' : 'Select a type'}, 
        {'id': 'local', 'value' : 'LOCAL'}, 
        {'id': 'cloud', 'value' : 'CLOUD'},
    ],
    GAME_ESRBS: [
        {'id': '', 'value' : 'Select a value'}, 
        {'id': 'Everyone', 'value' : 'Everyone'}, 
        {'id': 'Everyone 10+', 'value' : 'Everyone 10+'}, 
        {'id': 'Teen 13+', 'value' : 'Teen 13+'},
        {'id': 'Mature 17+', 'value' : 'Mature 17+'},
    ],
    GAME_GENERES: [
        {'id': '', 'value' : 'Select a genre'}, 
        {'id': 'Action & adventure', 'value' : 'Action & adventure'}, 
        {'id': 'Fighting', 'value' : 'Fighting'}, 
        {'id': 'Indie', 'value' : 'Indie'},
        {'id': 'Kids & family', 'value' : 'Kids & family'},
        {'id': 'Racing & flying', 'value' : 'Racing & flying'}, 
        {'id': 'Roleplaying', 'value' : 'Roleplaying'}, 
        {'id': 'Shooter', 'value' : 'Shooter'},
        {'id': 'Sports', 'value' : 'Sports'},
        {'id': 'Strategy', 'value' : 'Strategy'},
    ],
    ALERT_SCOPES: [
        {'id': 'global', 'value' : 'Global'}, 
        {'id': 'client', 'value' : 'Client'}, 
        {'id': 'lab', 'value' : 'Lab'}
    ],
    ALERT_TYPES: [
        {'id': 'success', 'value' : 'Success'}, 
        {'id': 'failure', 'value' : 'Failure'}, 
        {'id': 'warning', 'value' : 'Warning'},
        {'id': 'check', 'value' : 'Check'},
    ],
    GUEST_POD_TYPES: {
        gp_duo: 'gp_duo',
        gp_arc: 'gp_arc'
    },
    STRIPE_PK_KEY: process.env.VUE_APP_STRIPE_PK_KEY,
};