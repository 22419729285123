<template>
    <div class="main-layout-section admin-portal" :class="[user_data.role_id == 4 ? '' : 'secondary']">
        <AdminHeader @setPodData="setPodData($event)" v-if="user_data.role_id == 3" />
        <SuperAdminHeader v-if="user_data.role_id == 4" />
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null && user_data.role_id !== 4"/>
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="user_data.role_id == 4"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeInUp animate__delay-1s">Create Report</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    
                    <div class="row">
                        <div class="admin-general-section-buttons col-md-12">
                            <router-link :to="dashboard_link" class="admin-general-section-button">
                                Back To Dashboard
                            </router-link>
                        </div>
                        <div class="admin-general-section-buttons col-md-12 mb-3">
                            <router-link :to="dashboard_link" class="alerts-breadcrumb">
                                Dashboard
                            </router-link>
                            <span class="alerts-breadcrumb">></span>
                            <span class="alerts-breadcrumb">Create New Report</span>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 col-sm-12 alerts-section-heading first d-none">
                            <h5>Create New Report</h5>
                        </div>
                        <div class="col-sm-12">
                            <div class="reports-menu-header">
                                <router-link class="report-menu-header-button active-report-menu-header-button" :to="`/common/reports/status`">Status</router-link>
                                <router-link class="report-menu-header-button" :to="`/common/reports/summary`">Activity</router-link>
                            </div>
                            <hr class="alerts-border mt-2 mb-3">
                        </div>
                        <div class="col-sm-12 col-md-12">
                            <div class="overflow-auto p-0 m-0">
                                <table id="reports-table" role="table" aria-busy="false" aria-colcount="6" class="table b-table table-striped">
                                    <tbody role="rowgroup">
                                        <tr role="row" class="">
                                            <td aria-colindex="1" role="cell" class="report-td-button">
                                                <div class="report-buttons">
                                                    <button class="report-button" @click="setIntervalType('today')" :class="[report_form.interval == 'today' ? 'active-report-button' : '']">Now</button>
                                                </div>
                                            </td>
                                            <td aria-colindex="2" role="cell" class="report-td-client" v-if="user_data.role_id == 4">
                                                <div class="report-items">
                                                    <span class="report-item-client">
                                                        <select v-model="report_form.client_id" @change="labsByClient">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in clients" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                            <td aria-colindex="4" role="cell" class="report-td-location">
                                                <div class="report-items report-items-location">
                                                    <div class="report-item-location">
                                                        <select v-model="report_form.lab_id">
                                                            <option 
                                                            v-for="(selectOption, indexOpt) in labs" 
                                                            :key="indexOpt"
                                                            :value="selectOption.id"
                                                            >
                                                                {{ selectOption.value }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </td>
                                            <td aria-colindex="6" role="cell" class="report-td-action">
                                                <div class="report-action-button">
                                                    <button class="btn-sm" @click="handleReportReq(false)">
                                                        Create Report
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <hr class="alerts-border mt-3">
                        </div>
                        <div class="col-sm-12 col-md-12 mt-3">
                            <div class="report-stats-table">
                                <div class="report-stats-table-item">
                                    <div class="report-stats-table-item-label">Total Pods</div>
                                    <div class="report-stats-table-item-content">{{ stats_data.total }}</div>
                                </div>
                                <div class="report-stats-table-item">
                                    <div class="report-stats-table-item-label">Idle Pods</div>
                                    <div class="report-stats-table-item-content">{{ stats_data.idle }}</div>
                                </div>
                                <div class="report-stats-table-item">
                                    <div class="report-stats-table-item-label">Missing</div>
                                    <div class="report-stats-table-item-content">{{ stats_data.missing }}</div>
                                </div>
                                <div class="report-stats-table-item">
                                    <div class="report-stats-table-item-label">Pods With Users</div>
                                    <div class="report-stats-table-item-content">{{ stats_data.has_user }}</div>
                                </div>
                                <div class="report-stats-table-item">
                                    <div class="report-stats-table-item-label">Occupancy</div>
                                    <div class="report-stats-table-item-content">{{ stats_data.occu.toFixed(2) + '%'  }}</div>
                                </div>
                            </div>
                            <hr class="alerts-border mt-3" />
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12 col-sm-12">
                            <b-overlay :show="api_state" rounded="sm" variant="dark" opacity="0.75">
                                <div class="report-container">

                                    <!-- start of games/feedback panel -->
                                    <div class="report-gameplay-feedback-wrapper">
                                        <div class="report-gameplay-feedback">
                                            <div class="report-gameplay">
                                                <div class="report-panel">
                                                    <div class="report-panel-body">
                                                        <ul class="report-panel-body-list">
                                                            <li class="report-panel-body-list-item item-header">
                                                                <div class="report-panel-body-list-item-text">
                                                                    Location
                                                                </div>
                                                                <div class="report-panel-body-list-item-text">
                                                                    Nav ID
                                                                </div>
                                                                <div class="report-panel-body-list-item-text">
                                                                    Status
                                                                </div>
                                                                <div class="report-panel-body-list-item-text">
                                                                    User
                                                                </div>
                                                                <div class="report-panel-body-list-item-text">
                                                                    Time
                                                                </div>
                                                                <div class="report-panel-body-list-item-text">
                                                                    Game
                                                                </div>
                                                            </li>
                                                            <template v-if="report_data.length > 0">
                                                                <li class="report-panel-body-list-item" v-for="(item, key) in report_data" :key="key">
                                                                    <div class="report-panel-body-list-item-value client-lab-container">
                                                                        <template v-if="item.is_showing">
                                                                            <div class="report-client-name">
                                                                                {{ item.client.name }}
                                                                            </div>
                                                                            <div class="report-lab-name">
                                                                                {{ item.lab.lab_name }}
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        <template v-if="(Object.prototype.hasOwnProperty.call(item, 'pod') && item.pod !== null && Object.prototype.hasOwnProperty.call(item.pod, 'podnav') && item.pod.podnav !== null)">
                                                                            <router-link class="report-anchor-link" :to="root_link+'clients/'+item.client.id+'/pods/'+item.lab.id" v-if="user_data.role_id == 4">
                                                                                {{ item.pod.podnav.license }}
                                                                                <i class="fa fa-link"></i>
                                                                            </router-link>
                                                                            <router-link class="report-anchor-link" :to="root_link+'labs/'+item.lab.id+'/pods/'+item.pod.id" v-if="user_data.role_id == 3">
                                                                                {{ item.pod.podnav.license }}
                                                                                <i class="fa fa-link"></i>
                                                                            </router-link>
                                                                        </template>
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        {{ (Object.prototype.hasOwnProperty.call(item, 'pod') && item.pod !== null && Object.prototype.hasOwnProperty.call(item.pod, 'latestsession') && item.pod.latestsession !== null && (item.pod.latestsession.session_status == 1 || item.pod.latestsession.session_status == 2) && item.pod.latestsession.up_time <= 5 && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'latestsessionlog') && item.pod.latestsession.latestsessionlog !== null) ? item.pod.latestsession.latestsessionlog.activity : "MISSING" }}
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        <template v-if="(Object.prototype.hasOwnProperty.call(item, 'pod') && item.pod !== null && Object.prototype.hasOwnProperty.call(item.pod, 'latestsession') && item.pod.latestsession !== null && (item.pod.latestsession.session_status == 1 || item.pod.latestsession.session_status == 2) && item.pod.latestsession.up_time <= 5 && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'user') && item.pod.latestsession.user !== null && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'latestsessionlog') && item.pod.latestsession.latestsessionlog !== null && idle_asleep.indexOf(item.pod.latestsession.latestsessionlog.activity) == -1)">
                                                                            <template v-if="Object.prototype.hasOwnProperty.call(item.pod.latestsession.user, 'player') && item.pod.latestsession.user.player !== null">
                                                                                <router-link class="report-anchor-link" :to="'/common/users/view/'+item.pod.latestsession.user.id">
                                                                                    {{ item.pod.latestsession.user.player.email }}
                                                                                    <i class="fa fa-link"></i>
                                                                                </router-link>
                                                                            </template>
                                                                            <template v-else>
                                                                                <router-link class="report-anchor-link" :to="'/common/users/view/'+item.pod.latestsession.user.id">
                                                                                    {{ item.pod.latestsession.user.email }}
                                                                                    <i class="fa fa-link"></i>
                                                                                </router-link>
                                                                            </template>
                                                                        </template>
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        <template v-if="(Object.prototype.hasOwnProperty.call(item, 'lab') && item.lab !== null && item.lab.lab_mode_type == 'paid')">
                                                                            {{ (Object.prototype.hasOwnProperty.call(item, 'pod') && item.pod !== null && Object.prototype.hasOwnProperty.call(item.pod, 'latestsession') && item.pod.latestsession !== null && (item.pod.latestsession.session_status == 1 || item.pod.latestsession.session_status == 2) && item.pod.latestsession.up_time <= 5 && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'user') && item.pod.latestsession.user !== null && Object.prototype.hasOwnProperty.call(item.pod.latestsession.user, 'player') && item.pod.latestsession.user.player !== null && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'latestsessionlog') && item.pod.latestsession.latestsessionlog !== null && idle_asleep.indexOf(item.pod.latestsession.latestsessionlog.activity) == -1) ? formatTimeString(item.pod.latestsession.user.player.time_balance) : "" }}
                                                                        </template>
                                                                    </div>
                                                                    <div class="report-panel-body-list-item-value">
                                                                        <template>
                                                                            {{ (Object.prototype.hasOwnProperty.call(item, 'pod') && item.pod !== null && Object.prototype.hasOwnProperty.call(item.pod, 'latestsession') && item.pod.latestsession !== null && (item.pod.latestsession.session_status == 1 || item.pod.latestsession.session_status == 2) && item.pod.latestsession.up_time <= 5 && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'user') && item.pod.latestsession.user !== null && Object.prototype.hasOwnProperty.call(item.pod.latestsession.user, 'player') && item.pod.latestsession.user.player !== null && Object.prototype.hasOwnProperty.call(item.pod.latestsession, 'latestsessionlog') && item.pod.latestsession.latestsessionlog !== null && idle_asleep.indexOf(item.pod.latestsession.latestsessionlog.activity) == -1 && Object.prototype.hasOwnProperty.call(item.pod.latestsession.latestsessionlog, 'game') && item.pod.latestsession.latestsessionlog.game !== null) ? item.pod.latestsession.latestsessionlog.game.game_title : "" }}
                                                                        </template>
                                                                    </div>
                                                                </li>
                                                            </template>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end of games/feedback panel -->

                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" v-if="user_data.role_id == 3" />
            <SuperAdminFooter type="inner" v-if="user_data.role_id == 4" />
        </div>
        <!-- end of footer section -->
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminGetClientsList, adminGetLabsList, adminGetReportStatus } from "../../services/AdminService";
import AdminFooter from "@/views/admin/AdminFooter.vue";
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';
import moment from 'moment';
import moment_timezone from 'moment-timezone';
import { global_config } from "@/config/config.js";

export default {
    name: "StatusReport-view",
    components: { AdminHeader, AdminFooter, SuperAdminHeader, SuperAdminFooter },
    data() {
        return {
            api_state: false,
            search_client_id: '',
            user_data: null,
            dashboard_link: '',
            root_link: '',
            clients: [],
            labs:[{'id' : '', 'value' : 'All Labs'}],
            report_form: {
                interval:'today',
                client_id: '',
                lab_id:'',
            },
            user_timezone: moment.tz.guess(),
            mtz: moment_timezone,
            report_data : [],
            xds_icons: global_config.XD_ICONS,
            report_polling: null,
            idle_asleep: ['session_idle', 'session_asleep'],
            stats_data: {}
        };
    },

    validations: {
        report_form : {
            client_id:{required:false},
            lab_id:{required:false},
            interval:{required}
        }
    },
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        setIntervalType(type) {
            this.report_form.interval = type;
        },
        labsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: (this.report_form.client_id !== '') ? this.report_form.client_id : user_data.client_id
            };
            adminGetLabsList(params).then(response => {
                if(response.status) {
                    this.labs = response.data.labs;
                    // add empty option as placeholder
                    this.labs.splice(0, 0, {'id' : '', 'value' : 'All Labs'});
                }
                else {
                    this.labs = [{'id' : '', 'value' : 'All Labs'}];
                }
            });
        },
        makeToast(variant = "default", message, message1='') {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message, 'message1': message1});
        },
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            this.user_data = JSON.parse(localStorage.user_sess_data);
            if(!Object.prototype.hasOwnProperty.call(this.user_data, 'role_id')) {
                this.$router.push("/admin/login");
            }
            this.dashboard_link = this.user_data.role_id == 3 ? '/admin/dashboard' : '/superadmin/dashboard';
            this.root_link = this.user_data.role_id == 3 ? '/admin/' : '/superadmin/';
            if(this.user_data.role_id == 4) {
                this.clientsList();
            }
        },
        clientsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token
            };
            adminGetClientsList(params).then(response => {
                this.clients = response.data.clients;
                // add empty option as placeholder
                this.clients.splice(0, 0, {'id' : '', 'value' : 'All clients'});
            });
        },
        labsByClient() {
            if(this.report_form.client_id == "") {
                this.labs = [{'id' : '', 'value' : 'All Labs'}];
            }
            else {
                this.labsList();
            }
        },
        handleReportReq(is_loop=false) {
            // fill in client id if user is not superadmin
            if(this.user_data.role_id !== 4) {
                this.report_form.client_id = this.user_data.client_id;
            }
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "Please fill all required fields");
            } 
            else {
                this.api_state = is_loop ? false : true;
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.report_form;
                data.token = user_data.token;
                data.user_timezone = this.user_timezone;
                adminGetReportStatus(data).then(response => {
                    this.api_state = false;
                    if(response.status) {
                        this.report_data = response.data.report;
                        this.stats_data = response.data.stats;
                    }
                    else {
                        this.makeToast("error", response.message);
                    }
                });
            }
        },
        formatTimeString(time) {
            if(typeof time == 'undefined' || time == 'null' || time == '') {
                return '0m';
            }
            else {
                // convert into hours and minutes
                let hours = Math.floor(time / 60);
                let minutes = Math.ceil(time % 60); 
                if(hours > 0) {
                    return hours + "h " + minutes + "m";
                }
                else {
                    return minutes + "m";
                }
            }
        },
    },

    mounted() {
        this.labsList();
        this.handleReportReq();
        // add polling if not added
        clearInterval(this.report_polling);
        this.report_polling = setInterval(() => {
            this.handleReportReq(true);
        }, process.env.VUE_APP_STATUS_REPORT_DURATION);
    },
    created() {
        this.setUserData();
    },
    beforeDestroy() {
        clearInterval(this.report_polling);
    }
};
</script>
<style scoped>
#reports-table {
    width: auto;
}
#reports-table > tbody > tr > td.report-td-button {
    width: auto;
    min-width: auto;
    max-width: max-content;
}
.report-container .report-gameplay-feedback-wrapper .report-gameplay-feedback .report-gameplay {
    width: 100%;
}
.report-container .report-panel .report-panel-body .report-panel-body-list {
    padding: 0;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item.item-header {
    padding-left: 0px;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item.item-header div {
    font-size: inherit;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item div:nth-child(1) {
    width: 35%;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item {
    align-items: center;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item:not(.item-header) .report-panel-body-list-item-value:nth-child(1) {
    /* border: 2px solid var(--client-primary); */
    padding: 5px 0px;
    border-radius: 8px;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item:not(.item-header) .report-panel-body-list-item-value {
    font-size: 14px;
    font-family: 'Open Sans';
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.client-lab-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 47px;
}
.report-client-name {
    font-size: 18px;
    font-family: 'Open Sans';
    width: fit-content !important;
    margin-right: 15px;
}
.report-lab-name {
    font-size: 14px;
    font-family: 'Open Sans';
    width: fit-content !important;
}
.report-anchor-link {
    color: inherit;
    text-decoration: none;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item div:nth-child(4) {
    width: 15%;
}
.report-stats-table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 10px;
}
.report-stats-table .report-stats-table-item {
    display: flex;
    flex-direction: column;
    color: #d8d8d8;
    font-family: 'Open Sans';
}
.report-stats-table .report-stats-table-item .report-stats-table-item-label {
    font-weight: 700;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item > div:nth-child(1) {
    width: 311px;
    min-width: 311px;
}
.report-container .report-panel .report-panel-body .report-panel-body-list .report-panel-body-list-item > div {
    min-width: 211px;
    width: 211px;
}
.report-container .report-panel .report-panel-body {
    width: 100%;
    overflow-x: auto;
}
.report-container .report-panel .report-panel-body .report-panel-body-list {
    min-width: max-content;
}
</style>