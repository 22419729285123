<template>
  <div class="main-layout-section">
    
    <PlayerHeader @setPodData="setPodData($event)" :class="[(!pod_locked && !game_init) ? 'games-page-header' : 'games-page-header']" />

    <MainKeyboard :main_element="current_component" />

    <div class="main-content" v-if="!pod_locked && !game_init && !is_guest_pod">
        <!-- start of games container section -->
        <div class="col-md-11 mx-auto">

            <div class="games-section-container">
                <!-- start of images section -->
                <div class="games-section-images">
                    <!-- <div class="games-section-image-item" v-if="game_selected !== null">
                        <img :src="game_selected.ImageURL" />
                    </div> -->
                </div>
                <!-- end of images section -->
                <!-- start of filters/list section -->
                <div class="games-section-list">
                    <b-input-group class="d-flex text-center align-items-center games-section-list-search" v-if="(Object.prototype.hasOwnProperty.call(pod_data, 'with_key_board') && pod_data.with_key_board)">
                        <b-input-group-prepend class="search-icon">
                            <i class="animate__animated animate__fadeInUp animate__delay-1s fa-solid fa-search icon-button"></i>
                        </b-input-group-prepend>
                        <b-form-input
                        type="text"
                        placeholder="search for a title"
                        class="search-box h-53"
                        autocomplete="off"
                        v-model="searchQuery" 
                        @focus="setFocusedElement($event, 'searchQuery', '')"
                        ></b-form-input>
                        <b-input-group-append class="xbox-icon">
                            <span class="animate__animated animate__fadeInUp animate__delay-1s game-count">{{ (searchQuery !== '') ? filteredGames.length : total_games }}</span>
                            <i class="animate__animated animate__fadeInUp animate__delay-1s fa-brands fa-xbox icon-button"></i>
                        </b-input-group-append>
                    </b-input-group>

                    <b-overlay :show="api_state" rounded="sm" variant="light" opacity="1" class="overlay-bg">
                        <div class="games-section-list-filters mt-3">
                            <div class="games-section-list-filter-item">
                                <div class="games-section-list-filter-item-header">
                                    Sort By
                                </div>
                                <div class="games-section-list-filter-item-body">
                                    <span :class="[selected_sort == sort_options.popular ? 'sort-active' : '']" @click="handleSortReq(sort_options.popular)">Popular</span>
                                    <span :class="[selected_sort == sort_options.newest ? 'sort-active' : '']" @click="handleSortReq(sort_options.newest)">Newest</span>
                                    <span :class="[selected_sort == sort_options.abc ? 'sort-active' : '']" @click="handleSortReq(sort_options.abc)">ABC</span>
                                </div>
                            </div>
                            <div class="games-section-list-filter-item">
                                <div class="games-section-list-filter-item-header">
                                    Filters
                                </div>
                                <div class="games-section-list-filter-item-body filters-list">
                                    <div class="filters-list-item">
                                        <span @click="handleFilterDropdown('rating-filter')" :class="[selected_filters.game_esrb.length > 0 ? 'sort-active' : '']">Rating</span>
                                        <div class="filters-list-item-menu" id="rating-filter">
                                            <ul>
                                                <template v-for="item in game_esrbs">
                                                    <li :key="item.id" v-if="item.id !== ''" @click="handleFilterGamesList('game_esrb', item.id)" :class="[selected_filters.game_esrb.indexOf(item.id) !== -1 ? 'active' : '']">
                                                        {{ item.value }}
                                                    </li>
                                                </template>
                                                <hr>
                                                <li @click="handleFilterDropdown('done')">Done</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="filters-list-item">
                                        <span @click="handleFilterDropdown('genre-filter')" :class="[selected_filters.game_genre.length > 0 ? 'sort-active' : '']">Genre</span>
                                        <div class="filters-list-item-menu" id="genre-filter">
                                            <ul>
                                                <template v-for="item in game_genres">
                                                    <li :key="item.id" v-if="item.id !== ''" @click="handleFilterGamesList('game_genre', item.id)" :class="[selected_filters.game_genre.indexOf(item.id) !== -1 ? 'active' : '']">
                                                        {{ item.value }}
                                                    </li>
                                                </template>
                                                <hr>
                                                <li @click="handleFilterDropdown('done')">Done</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- <span>Released</span> -->
                                    <div class="filters-list-item">
                                        <span @click="handleFilterDropdown('storage-filter')" :class="[selected_filters.game_type.length > 0 ? 'sort-active' : '']">Storage</span>
                                        <div class="filters-list-item-menu" id="storage-filter">
                                            <ul>
                                                <template v-for="item in game_types">
                                                    <li :key="item.id" v-if="item.id !== ''" @click="handleFilterGamesList('game_type', item.id)" :class="[selected_filters.game_type.indexOf(item.id) !== -1 ? 'active' : '']">
                                                        {{ item.value }}
                                                    </li>
                                                </template>
                                                <hr>
                                                <li @click="handleFilterDropdown('done')">Done</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="games-section-list-data mt-3">
                            <div class="games-section-list-data-title" :class="[api_state ? 'zero-opacity' : '']">
                                <h3>{{ game_selected !== null ? game_selected.GameName : 'Select A Game' }}</h3>
                                <div class="games-section-list-data-title-attribs">
                                    <div class="games-section-list-data-title-attribs-item">
                                        <span class="heading">Genre</span>
                                        <span class="body">{{ game_selected !== null ? game_selected.GameGenre : '-' }}</span>
                                    </div>
                                    <div class="games-section-list-data-title-attribs-item">
                                        <span class="heading">Released</span>
                                        <span class="body">{{ game_selected !== null ? game_selected.ReleasedDate : '-' }}</span>
                                    </div>
                                    <div class="games-section-list-data-title-attribs-item">
                                        <span class="heading">Storage</span>
                                        <span class="body">{{ game_selected !== null ? game_selected.GameType[0].toUpperCase() + game_selected.GameType.slice(1) : '-' }}</span>
                                    </div>
                                    <div class="games-section-list-data-title-attribs-item">
                                        <span class="heading">Platform</span>
                                        <span class="body">
                                            <template v-if="game_selected !== null">
                                                <span v-for="(pt, index) in game_selected.PlatformsFE" :key="index">
                                                    {{ (pt !== "" && Object.prototype.hasOwnProperty.call(xds_icons, pt)) ? xds_icons[pt].text.split(" ")[1] : "" }}
                                                </span>
                                            </template>
                                            <span v-else>-</span>
                                            <!-- {{ (game_selected.GamePlatform !== "" && Object.prototype.hasOwnProperty.call(xds_icons, game_selected.GamePlatform)) ? xds_icons[game_selected.GamePlatform].text.split(" ")[1] : "" }} -->
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="games-section-list-data-list" id="games-list" :class="[api_state ? 'zero-opacity' : '']">
                                <div class="games-section-list-data-list-inner" id="games-listtt">
                                    <div class="games-section-list-data-list-item animate__animated animate__fadeIn animate__fast" tabindex="0" v-for="game in filteredGames" :key="game.GameId" @click="selectGame(game)" v-show="loaded_games.indexOf(game.ImageURL) !== -1">
                                        <img 
                                        :src="`${game.ImageURL}`" 
                                        alt="" 
                                        :class="[ game_selected !== null && game_selected.GameId == game.GameId ? 'selected-game' : '' ]" @load="loadGameImage(game.ImageURL)">
                                        <!-- <b-img-lazy 
                                        v-bind="lazy_load_props" 
                                        :src="`${game.ImageURL}`" 
                                        :alt="game.GameName" 
                                        :class="[ game_selected !== null && game_selected.GameId == game.GameId ? 'selected-game' : '' ]"
                                        ></b-img-lazy> -->
                                    </div>
                                    <p class="text-left no-results" v-if="filteredGames.length <= 0" :key="filteredGames.length">No games found at the moment!</p>
                                </div>
                            </div>
                            <div class="games-section-list-data-play">
                                <div class="games-section-list-data-play-buttons">
                                    <!-- <router-link to="" class="btn" @click="handleBack">
                                        Back
                                    </router-link> -->
                                    <a href="javascript:;" class="btn" @click="handleBack">
                                        Back
                                    </a>
                                    <template v-if="Object.prototype.hasOwnProperty.call(guest_pod_types, pod_type)">
                                        <button 
                                        class="btn"
                                        @click="handleGuestSelection(game_selected.GameKey, game_selected.GameSearch, xds_data[xd_type], game_selected.GameType)"
                                        :disabled="typeof xds_data[xd_type] == undefined || xds_data[xd_type] == '' || xds_data[xds_data[xd_type]+'_disabled']" 
                                        v-if="game_selected !== null"
                                        >
                                        {{ xds_icons[xds_data[xd_type]].text }}
                                        </button>
                                        <button 
                                        class="btn"
                                        disabled="disabled" 
                                        v-else
                                        >
                                        {{ (typeof xds_data[xd_type] == undefined || xds_data[xd_type] == '' || xds_data[xds_data[xd_type]+'_disabled']) ? '' : xds_icons[xds_data[xd_type]].text }}
                                        </button>
                                    </template>
                                    <template v-else>
                                        <button 
                                        class="btn"
                                        @click="playGame(game_selected.GameKey, game_selected.GameSearch, xds_data[xd_type], game_selected.GameType)"
                                        :disabled="typeof xds_data[xd_type] == undefined || xds_data[xd_type] == '' || xds_data[xds_data[xd_type]+'_disabled']" 
                                        v-if="game_selected !== null"
                                        >
                                        {{ xds_icons[xds_data[xd_type]].text }}
                                        </button>
                                        <button 
                                        class="btn"
                                        disabled="disabled" 
                                        v-else
                                        >
                                        {{ (typeof xds_data[xd_type] == undefined || xds_data[xd_type] == '' || xds_data[xds_data[xd_type]+'_disabled']) ? '' : xds_icons[xds_data[xd_type]].text }}
                                        </button>
                                    </template>
                                </div>
                                <div class="games-section-list-data-play-chat">
                                    <div class="w-chat-box" @click="$root.$emit('live-chat-widget-visibility', 'show-hide');">
                                        <!-- <span><i class="fa-brands fa-rocketchat"></i></span> -->
                                        <span><img src="../../assets/images/chat-icon.png" alt="chat with us"></span>
                                    </div>
                                </div>

                            </div>
                            
                            <!-- show notification -->
                            <div class="w-login-show notification-container w-100 mx-auto nogame-notification">
                                <div
                                    class="
                                    animate__animated animate__fadeIn animate__delay-1s 
                                    w-100
                                    "
                                    v-if="nogame_error !== '' && nogame_message !== ''"
                                >
                                    <div class="notification-block warning w-100">
                                        <div class="notification-message w-100">
                                            <h5>{{ nogame_message }}</h5>
                                        </div>
                                        <div class="icon-warning"><i class="fa-solid fa-exclamation-circle"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-overlay>
                </div>
                <!-- end of filters/list section -->
                <!-- start of images section -->
                <div class="games-section-info">
                    <!-- <h2>About This Game</h2>
                    <p>Ship of Fools is a seafaring roguelite co-op game where you play the Fools,
                    the only creatures fool enough to brave the sea. The Great Lighthouse that
                    once protected the Archipelago is broken and a storm of malice and cor-
                    ruption is coming.</p>
                    <p>Together, you and your ship mates will jump aboard The Stormstrider and
                    make your voyage across the sea. Man the cannons, ready your sails and
                    protect your ship from sea monsters over multiple runs. It’s up to you to
                    defend your home from the almighty Aquapocalypse.</p>
                    <p>In a nutshell, Ship of Fools is a game about blasting away your seaborne
                    foes with mighty cannons as you defend your ship. Inspired by modern
                    classic roguelites and built for co-op, you’ll want to bring your ﬁrst mate on
                    deck and enjoy oarsome combat as you unlock new trinkets and artifacts
                    to help you save the world from catastrophe.</p>
                    <p>Uncover lost treasures and remote shops on the islands of the Archipela-
                    go, but plan your moves carefully! As you chart your way through the
                    sector, the Everlasting Storm shifts and changes, blocking your paths with
                    its fury. You’ll need to adjust your course in reaction to storm movements
                    and decide when you can brave a trip to discover what lurks inside the
                    storm.</p>
                    <p>Man your ship as a solo sailor and ﬁght your way through dangerous
                    waters and keep the glory for yourself, Ship of Fools oﬀers solo play as well
                    as multiplayer. As they sing in old sailor songs: it’s sometimes better to
                    have no crew than a dysfunctional one!</p>
                    <p>With many Fools to play with, unique items to ﬁnd and unlock, combat en-
                    counters to ﬁght in and many islands to visit, each run will bring something
                    new to your voyage. Bolster your strength each time, ready to defeat the
                    monsters of the deep sea.</p> -->
                </div>
                <!-- end of images section -->
            </div>
        </div>
        <!-- end of games container section -->
    </div>

    <template v-if="!pod_locked && !game_init && is_guest_pod">
        <!-- show widget to select guests -->
        <GuestSelectWidget :game_data="guest_game_data" @handleGuestGamePlay="handleGuestGamePlay($event)" />
    </template>

    <div class="" v-if="!pod_locked && game_init">
        <div class="smaller-screen-header">
            <div class="smaller-screen-header-logo">
                <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                    v-if="logo_file !== '' && logo_file !== null"
                />
            </div>
            <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
                <span class="pod-info-nav-name">
                    {{ nav_name }}
                </span>
            </div>
        </div>
        
        <div class="w-logo mb-5 pt-5 larger-screen-header">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content games-page-init-game">
            <h2 class="page-heading animate__animated animate__fadeIn animate__delay-1s">Getting your game ready!</h2>
            <h3 class="page-subheading mb-1 animate__animated animate__fadeIn animate__delay-1s">Hold tight...</h3>
            <!-- start of buttons section -->
            <div class="dashboard-buttons-container col-md-3">
                <div class="logout-loading-image mx-auto animate__animated animate__fadeIn animate__delay-1s">
                    <object type="image/svg+xml" :data="require(`../../assets/images/bits.svg`)" style="width:120px;"></object>
                </div>
                <div class="logout-reason mt-70 animate__animated animate__fadeIn animate__delay-1s">
                    <!-- <p class="text-center" style="font-weight: 600;">Whenever you are ready to<br>logout or return to the menu,<br>use the physical button panel.</p> -->
                </div>

                <div class="w-login-show notification-container w-alert-340 mx-auto">
                    <div
                        class="
                        animate__animated animate__fadeIn animate__delay-1s 
                        w-alert-340
                        "
                        v-if="!game_started"
                    >
                        <div class="notification-block warning">
                            <div class="notification-message w-alert-290">
                                <h5>{{ (game_start_error.is_exist) ? this.game_start_error.text_head : "Still Here? Something went wrong." }}</h5>
                                <p>{{ (game_start_error.is_exist) ? this.game_start_error.text_sub : "Chat with us below and we’ll sort this out for you" }}</p>
                            </div>
                            <div class="icon-warning"><i class="fa-solid fa-exclamation-circle"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end of buttons section -->
        </div>
    </div>

    <div class="main-content" v-if="pod_locked">
      <div class="d-flex justify-content-center">
        <h2 class="text-white mt-25">You have been locked out by Admin. Please wait...</h2>
      </div>
    </div>

    <!-- start of footer section -->
    <div class="col-sm-12 mx-auto player-games-footer">
        <PlayerFooter type="inner" class="animate__animated animate__fadeIn animate__delay-3s" />
    </div>
    <!-- end of footer section -->

  </div>
</template>

<script>
import PlayerHeader from "@/views/player/PlayerHeader.vue";
import { getGamesList, getPlayerRemainingTime } from "../../services/PlayerService";
import { global_config } from "@/config/config.js";
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import { sessionActivitySave } from "../../services/HomepageService";
import { handleDocumentHeight } from "../../services/Guard";
import GuestSelectWidget from "@/components/GuestSelectWidget.vue";
import { getFlaskGamePlaying } from "../../services/FlaskService";

export default {
    name: "PlayerGames-view",
    components: { PlayerHeader, PlayerFooter, GuestSelectWidget },
    data() {
        return {
            xd_type: this.$route.params.xd,
            games: [],
            original_games: [],
            searchQuery:'',
            pod_id_key : "",
            pod_locked: false,
            pod_data: {},
            api_state: false,
            page: 1,
            last_page: 1,
            game_selected: null,
            window_width: window.innerWidth,
            xds_data: {
                "xd1" : "", 
                "xd2" : "", 
                "xb_disabled": true, 
                "ps_disabled": true, 
                "sw_disabled": true, 
                "pc_disabled": true, 
                "pod_disabled": true
            },
            xds_icons: global_config.XD_ICONS,
            game_init: false,
            game_started: true,
            lazy_load_props: {
                blank: true,
                blankColor: '#d3d3d3'
            },
            sort_options: {
                'newest' : 'newest',
                'popular' : 'popular',
                'abc' : 'abc',
            },
            selected_sort: 'popular',
            selected_filters: {
                'game_type': [],
                'game_genre': [],
                'game_esrb': []
            },
            game_genres: global_config.GAME_GENERES,
            game_types: global_config.GAME_TYPES,
            game_esrbs: global_config.GAME_ESRBS,
            loaded_games: [],
            filters_state:'',
            total_games:0,
            nogame_error: '',
            nogame_message: '',
            is_guest_pod: false,
            guest_pod_types: global_config.GUEST_POD_TYPES,
            guest_game_data: {
                gid: '',
                search: '',
                platform: '',
                install: null,
                guests: '',
            },
            pod_type: '',
            current_component: this,
        };
    },

    computed: {
        filteredGames () {
            if(this.searchQuery) {
                return this.games.filter(item => {
                return item.GameName.toLowerCase().includes(this.searchQuery.toLowerCase())
                });
            }
            else {
                return this.games;
            }
        },
        game_start_error() {
            return this.$store.getters.getMessageDetail('player-games-start-error');
        },
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        nav_name() {
            return this.$store.getters.getNavName();
        }
    },

    methods: {
        gamesList() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
            session_id_key : pod_data.session_id_key,
            token: user_data.token,
            user_id: user_data.user_id,
            type: 'player',
            page: this.page,
            screen: this.window_width < 768 ? 'sm' : 'lg',
            xd: this.xds_data[this.xd_type],
            sort: this.selected_sort,
            filters: this.selected_filters,
        };
        this.api_state = true;
        getGamesList(params).then(response => {
            this.api_state = false;
            this.games = response.data.games;
            this.page = response.data.current_page + 1;
            this.last_page = response.data.last_page;
            this.total_games = response.data.total_rows;
            this.original_games = JSON.parse(JSON.stringify(this.games));
            // if(this.games.length > 0) {
            //     this.game_selected = this.games[0];
            // }
            // else {
            //     this.game_selected = null;
            // }
        })
        },
        setPodData(obj) {
        this[obj.key] = obj.value;
        if(obj.key == 'xds_data') {
            this.gamesList();
        }
        },
        selectGame(game) {
            if(this.game_selected == null) {
                this.game_selected = game;
            }
            else {
                if(this.game_selected.GameId == game.GameId) {
                    // this.game_selected = null;
                }
                else {
                    this.game_selected = game;
                }
            }
        },
        playGame(gid, search, platform, install=null, guests="") {
            // call payment save function here
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id
            };
            getPlayerRemainingTime(params).then(rmt => {
                // check for time
                if(rmt.data.time <= 0 && rmt.data.mode == 'paid') {
                    this.$router.push('/player/buy-time');
                }
                else {
                    if(typeof this.xds_data[platform] !== undefined && !this.xds_data[platform+"_disabled"]) {
                        document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
                        document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
                        setTimeout(() => {
                            document.getElementById('main-header-player').scrollIntoView()
                            this.$children.forEach(function(item) {
                                if(item.$options._componentTag == "PlayerHeader") {
                                    item.playGame(gid, search, platform, install, guests);
                                }
                            });
                        }, 1500);
                        // hide guest pod screen
                        setTimeout(() => {
                            this.is_guest_pod = false;
                        }, 2000);
                    }
                } 
            });
        },
        handleBack() {
            document.getElementsByClassName('main-content')[0].classList.add('animate__animated');
            document.getElementsByClassName('main-content')[0].classList.add('animate__fadeOut');
            setTimeout(() => {
                this.$router.push({ path: '/player/dashboard' });
            }, 500);
        },
        handleSortReq(type) {
            if(this.selected_sort !== type) {
                this.selected_sort = type;
                this.page = 1;
                this.last_page = 1;
                this.gamesList();
            }
        },
        handleFilterDropdown(type='') {
            // hide all dropdowns
            var elems = document.getElementsByClassName('filters-list-item-menu');
            for (var i = 0; i < elems.length; i ++) {
                elems[i].style.display = 'none';
            }
            // now open clicked one
            if(type !== '' && type !== 'done') {
                document.getElementById(type).style.display = 'block';
                this.filters_state = JSON.stringify(this.selected_filters);
            }
            if(type == 'done' && JSON.stringify(this.selected_filters) !== this.filters_state) {
                this.page = 1;
                this.last_page = 1;
                this.gamesList();
            }
        },
        handleFilterGamesList(type, value) {
            if(this.selected_filters[type].indexOf(value) !== -1) {
                this.selected_filters[type].splice(this.selected_filters[type].indexOf(value), 1);
            }
            else {
                this.selected_filters[type].push(value);
            }
        },
        loadGameImage(game) {
            this.loaded_games.push(game);
        },
        disabledPlay() {
            // this.$root.$emit("toast_message", {'type' : 'warning', 'message' : 'Please select a game to continue.'});
            this.nogame_error = 'warning';
            this.nogame_message = 'Please select a game to continue.';
            // hide error div
            setTimeout(() => {
                this.nogame_error = '';
                this.nogame_message = '';
            }, 5000);
        },
        handleGuestSelection(gid, search, platform, install=null) {
            this.guest_game_data.gid = gid;
            this.guest_game_data.search = search;
            this.guest_game_data.platform = platform;
            this.guest_game_data.install = install;
            this.is_guest_pod = true;
        },
        setPodType() {
            let sess_data = JSON.parse(localStorage.pod_sess_data);
            if(Object.prototype.hasOwnProperty.call(sess_data, 'pod_type')) {
                this.pod_type = sess_data.pod_type;
            }
        },
        handleGuestGamePlay(params) {
            this.playGame(params.gid, params.search, params.platform, params.install, params.guests);
            // this.is_guest_pod = false;
        },
        confirmLabMode() {
            // call payment save function here
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id
            };
            getPlayerRemainingTime(params).then(rmt => {
                this.$root.$emit("lab_mode_message", rmt.data.mode);
            });
        },
        setFocusedElement(event, ref, form) {
            this.$store.commit("setFocusedElement", ref);
            this.$store.commit("setFocusedElementForm", {'form': form, 'target' : event});
        },
    },

    mounted() {
        handleDocumentHeight();
        // call activity record api
        getFlaskGamePlaying().then(gp => {
            if (
                Object.prototype.hasOwnProperty.call(gp, "success") && gp.success &&
                Object.prototype.hasOwnProperty.call(gp, "data") &&
                Object.prototype.hasOwnProperty.call(gp.data, "platform") &&
                gp.data.platform.length > 0
            ) {
                if(typeof localStorage.game_playing !== 'undefined') {
                    let game_data = JSON.parse(localStorage.game_playing);
                    if (typeof game_data.gid !== 'undefined') {
                        // do nothing as game play already in progress
                    } 
                    else{
                        // record activity as no game play at the moment
                        sessionActivitySave({'activity': global_config.ACTIVITIES.UI_GAMES}).then((response) => {
                            console.log("ACTIVITY API CALLED : ", response);
                        });
                    }
                }
                else {
                    // record activity as no game play at the moment
                    sessionActivitySave({'activity': global_config.ACTIVITIES.UI_GAMES}).then((response) => {
                        console.log("ACTIVITY API CALLED : ", response);
                    });
                }
            }
            else {
                // record activity as no game play at the moment
                sessionActivitySave({'activity': global_config.ACTIVITIES.UI_GAMES}).then((response) => {
                    console.log("ACTIVITY API CALLED : ", response);
                });    
            }
        });
        // this.gamesList();
        document.getElementById('games-list').onscroll = () => {
            //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
            let bottomOfWindow = document.getElementById('games-list').scrollTop + document.getElementById('games-list').offsetHeight === document.getElementById('games-list').scrollHeight;
            if (bottomOfWindow && !this.api_state && this.page <= this.last_page) {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                let params = {
                    session_id_key : pod_data.session_id_key,
                    token: user_data.token,
                    user_id: user_data.user_id,
                    type: 'player',
                    page: this.page,
                    screen: this.window_width < 768 ? 'sm' : 'lg',
                    xd: this.xds_data[this.xd_type],
                    sort: this.selected_sort,
                    filters: this.selected_filters,
                };
                this.api_state = true;
                getGamesList(params).then(response => {
                    this.api_state = false;
                    this.page = response.data.current_page + 1;
                    this.last_page = response.data.last_page;
                    this.total_games = response.data.total_rows;
                    this.games = this.games.concat(response.data.games);
                })
            }
        };
        // capture game messages
        if(
            Object.prototype.hasOwnProperty.call(this.$root, "_events") && 
            Object.prototype.hasOwnProperty.call(this.$root._events, "game_message")
        ) {
            this.$root.$off("game_message");
        }
        this.$root.$on('game_message', (message) => {
            if(message.type == 'init') {
                this.game_init = true;
                this.game_started = true;
            }
            else if(message.type == 'success') {
                // this.game_init = false;
                // this.game_started = true;
                this.$router.push('/player/dashboard');
            }
            else if(message.type == 'fail') {
                this.game_started = false;
            }
        });

        this.confirmLabMode();
    },
    created() {
        if(typeof this.$route.params.xd == 'undefined' || this.$route.params.xd == '') {
            this.$router.push({ path: '/player/dashboard' })
        }
        // this.gamesList();
        // set pod type on mount
        this.setPodType();
    }
};
</script>
<style scoped>
.main-layout-section .main-content .games-section-container {
    min-height: 87vh;
}
.w-footer-block-inner {
    padding-top: 0px;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.disabled-btn {
    opacity: 0.7;
}
.main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
    height: 52vh;
}
.nogame-notification {
    margin-top: 10px;
}
@media screen and (max-width: 1024px) and (min-width: 769px) {
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
        height: 48vh;
    }
}
@media screen and (max-width: 768px) {
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
        height: 46vh;
    }
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play .games-section-list-data-play-chat {
        display: flex;
        width: 25%;
        justify-content: end;
    }
    .main-layout-section .player-games-footer {
        display: none;
    }
}
@media screen and (max-width: 768px) and (min-width: 426px) {
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
        height: 44vh;
    }
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play {
        margin-bottom: 0px;
    }
}
@media screen and (max-width: 425px) {
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-list {
        height: 46vh;
    }
    .main-layout-section .main-content .games-section-container .games-section-list .games-section-list-data .games-section-list-data-play {
        margin-bottom: 0px;
    }
}
.main-layout-section .main-content .games-section-container .games-section-list .overlay-bg {
    z-index: 100;
}
</style>