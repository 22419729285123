export const flask_api_routes = {
    AUTHENTICATE_SESSION: '/kiosk/authenticate_session',
    
    // pod info
    GET_POD_ID: '/kiosk/check/pod_id',
    WRITE_POD_ID: '/kiosk/set/pod_id',
    GET_XD_TYPE: '/kiosk/check/xds',
    GET_MAC_ADDRESS: '/kiosk/check/mac_address',
    GET_POD_VOLUME : '/kiosk/check/vol_now',
    SET_POD_VOLUME : '/kiosk/set/vol_change',
    GET_POD_TYPE : '/kiosk/check/pod_type',
    SET_POD_TYPE : '/kiosk/set/pod_type',
    SET_POD_DISABLED : '/kiosk/set/pod_disabled',
    CHECK_POD_IDLE : '/kiosk/check/pod_idle',
    CHECK_POD_ASLEEP : '/kiosk/check/pod_asleep',
    SET_POD_SCHEDULE : '/kiosk/set/schedule_start_sleep',
    CHECK_POD_PUSHMENU : '/kiosk/check/push_menu',
    SET_POD_WEB_TIME : '/kiosk/set/player_web_time',
    SET_XD_DISABLED: '/kiosk/set/xd_disabled',
    SET_POD_EMERGENCY : '/kiosk/set/pod_emergency',
    GET_POD_TOUCH: '/kiosk/check/pod_touch',
    SET_POD_RESERVED : '/kiosk/check/pod_reserved',
    GET_POD_KB: '/kiosk/check/pod_kb',
    SET_POD_WEB_SSO : '/kiosk/check/web_sso',
    SET_POD_WEB_RESET : '/kiosk/check/web_reset',
    SET_SSO_LOGOUT : '/kiosk/check/sso_logout',
 
    // game checking
    SET_GAME_START: '/game/play/game_start',
    CHECK_GAME_STARTED : '/game/check/game_started',
    CHECK_GAME_PLAYING: '/game/check/game_playing',

    // logout manage
    CHECK_LOGOUT_POD: '/kiosk/check/logout_pod',
    LOGOUT_POD_ACKNOWLEDGE: '/kiosk/acknowledge/logout_pod',
    SET_LOGOUT_WEB: '/kiosk/set/logout_web',
    SET_LOGIN_POD : '/kiosk/set/login_pod',
    CHECK_READY_WEB: '/kiosk/check/ready_web',
    CHECK_READY_POD: '/kiosk/check/ready_pod',
    
    // stream manage
    SET_STREAM_DEFAULT : '/kiosk/set/stream_default',
    SET_STREAM : '/kiosk/set/streaming',
    
    
    // led manage
    SET_LED1_DEFAULT : '/kiosk/set/led1_default',
    SET_LED1_CHANGE : '/kiosk/set/led1_change',
    SET_LED2_DEFAULT : '/kiosk/set/led2_default',
    SET_LED2_CHANGE : '/kiosk/set/led2_change',
    SET_LED1_LOCK : '/kiosk/set/led1_lock',
    SET_LED2_LOCK : '/kiosk/set/led2_lock',
    
    // pod status
    MUTE_UNMUTE_COMMAND: '/kiosk/check/mute_unmute',
    RESET_COMMAND: '/kiosk/set/reset',
    REBOOT_COMMAND: '/kiosk/set/reboot',

    // activity manage
    SET_SESSION_ACTIVITY : '/kiosk/set/session_activity',

    // push functions
    CREATE_POD_PUSHMENU : '/kiosk/create/push_menu',
    CREATE_POD_PUSHEND : '/kiosk/create/push_end',
    CHECK_POD_SCREEN_BUTTONS : '/kiosk/check/pod_screen_buttons',
};
