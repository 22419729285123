import Vue from "vue";
import VueRouter from "vue-router";
import Login from "@/views/auth/Login.vue";
import MobileLogin from "@/views/auth/MobileLogin.vue";
import Register from "@/views/auth/Register.vue";
import Forgotpass from "@/views/auth/Forgotpass.vue";
import PlayerLayout from "@/views/player/PlayerLayout.vue";
import PlayerDashboard from "@/views/player/PlayerDashboard.vue";
import PlayerGames from "@/views/player/PlayerGames.vue";
import FacLayout from "@/views/facilitator/FacLayout.vue";
import FacDashboard from "@/views/facilitator/FacDashboard.vue";
import FacPods from "@/views/facilitator/FacPods.vue";
import FacGames from "@/views/facilitator/FacGames.vue";
import AdminLogin from "@/views/auth/AdminLogin.vue";
import AdminMobileLogin from "@/views/auth/AdminMobileLogin.vue";
import AdminLayout from "@/views/admin/AdminLayout";
import AdminDashboard from "@/views/admin/AdminDashboard.vue";
import AdminLabs from "@/views/admin/AdminLabs.vue";
import AdminNewLab from "@/views/admin/AdminNewLab.vue";
import AdminEditLab from "@/views/admin/AdminEditLab.vue";
import AdminPods from "@/views/admin/AdminPods.vue";
import AdminNewPod from "@/views/admin/AdminNewPod.vue";
import AdminEditPod from "@/views/admin/AdminEditPod.vue";
import AdminGames from "@/views/admin/AdminGames.vue";
import AdminSettings from "@/views/admin/AdminSettings.vue";
import SuperAdminLayout from "@/views/superadmin/SuperAdminLayout";
import SuperAdminDashboard from "@/views/superadmin/SuperAdminDashboard.vue";
import SuperAdminClients from "@/views/superadmin/SuperAdminClients.vue";
import SuperAdminClientsDetails from "@/views/superadmin/SuperAdminClientsDetails.vue";
import SuperAdminNewClient from "@/views/superadmin/SuperAdminNewClient.vue";
import SuperAdminClientsPods from "@/views/superadmin/SuperAdminClientsPods.vue";
import SuperAdminGames from "@/views/superadmin/SuperAdminGames.vue";
import SuperAdminNewGame from "@/views/superadmin/SuperAdminNewGame.vue";
import SuperAdminEditGame from "@/views/superadmin/SuperAdminEditGame.vue";
import { checkIfValidPlayer, checkIfValidFacilitator, checkIfValidAdmin, checkIfValidSuperAdmin, checkIfValidCommonUser } from "../services/Guard";
import PlayerLogout from "@/views/player/PlayerLogout.vue";
import CommonLayout from "@/views/common/CommonLayout";
import UsersList from "@/views/common/UsersList";
import NewUser from "@/views/common/NewUser";
import EditUser from "@/views/common/EditUser";
import ViewUser from "@/views/common/ViewUser";
import AlertsList from "@/views/common/AlertsList";
import SystemMessagesList from "@/views/superadmin/SystemMessagesList.vue";
import PlayerOffers from "@/views/player/PlayerOffers";
import SuperAdminDbBackups from "@/views/superadmin/SuperAdminDbBackups.vue";
import SummaryReport from "@/views/common/SummaryReport";
import StatusReport from "@/views/common/StatusReport";
import AdminPodGames from "@/views/admin/AdminPodGames.vue";
import AdminReservations from "@/views/admin/AdminReservations.vue";
import AdminNewReservation from "@/views/admin/AdminNewReservation.vue";
import AdminEditReservation from "@/views/admin/AdminEditReservation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    alias: "/login",
    component: Login,
    meta: {title: 'GameLab - Login'}
  },
  {
    path: "/mobile-login/:qr_code",
    name: "Mobile Login",
    alias: "//mobile-login/:qr_code",
    component: MobileLogin,
    meta: {title: 'GameLab - Mobile Login'}
  },
  {
    path: "/register/:qr_code?",
    name: "Register",
    alias: "/register",
    component: Register,
    meta: {title: 'GameLab - Register'}
  },
  {
    path: "/forgot-password/:qr_code?",
    name: "Forgot Password",
    alias: "/forgot-password",
    component: Forgotpass,
    meta: {title: 'GameLab - Forgot Password'}
  },
  {
    path: "/admin/login",
    name: "Admin Login",
    alias: "/admin/login",
    component: AdminLogin,
    meta: {title: 'GameLab - Admin Login'}
  },
  {
    path: "/admin/mobile-login",
    name: "Admin Mobile Login",
    alias: "/admin/mobile-login",
    component: AdminMobileLogin,
    meta: {title: 'GameLab - Admin Mobile Login'}
  },
  {
    path: "/player/logout",
    name: "Player Logout",
    alias: "/player/logout",
    meta: { reason: "", title: 'GameLab - Logout' },
    component: PlayerLogout,
  },
  {
    path: "/player",
    name: '/player',
    component: PlayerLayout,
    beforeEnter: checkIfValidPlayer,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        alias: "dashboard",
        component: PlayerDashboard,
        meta: {title: 'GameLab - Player Dashboard'}
      },
      {
        path: "games/:xd?",
        name: "Games",
        alias: "games",
        component: PlayerGames,
        meta: {title: 'GameLab - Games List'}
      },
      {
        path: "buy-time",
        name: "Buy Time",
        alias: "buy-time",
        component: PlayerOffers,
        meta: {title: 'GameLab - Buy Time'}
      }
    ]
  },
  {
    path: "/facilitator",
    name: '/facilitator',
    component: FacLayout,
    beforeEnter: checkIfValidFacilitator,
    children: [
      {
        path: "dashboard",
        name: "Facility Dashboard",
        alias: "dashboard",
        component: FacDashboard,
        meta: {title: 'GameLab - Facility Dashboard'}
      },
      {
        path: "pods",
        name: "Facility Pods",
        alias: "pods",
        component: FacPods,
        meta: {title: 'GameLab - Facility Pods'}
      },
      {
        path: "games",
        name: "Facility Games",
        alias: "games",
        component: FacGames,
        meta: {title: 'GameLab - Facility Games'}
      }
    ]
  },
  {
    path: "/admin",
    name: '/admin',
    component: AdminLayout,
    beforeEnter: checkIfValidAdmin,
    children: [
      {
        path: "dashboard",
        name: "Admin Dashboard",
        alias: "dashboard",
        component: AdminDashboard,
        meta: {title: 'GameLab - Admin Dashboard'}
      },
      {
        path: "settings",
        name: "Admin Settings",
        alias: "settings",
        component: AdminSettings,
        meta: {title: 'GameLab - Admin Settings'}
      },
      {
        path: "labs",
        name: "Admin Labs",
        alias: "labs",
        component: AdminLabs,
        meta: {title: 'GameLab - Admin Labs'}
      },
      {
        path: "labs/deploy",
        name: "Admin Lab Create",
        alias: "labs/deploy",
        component: AdminNewLab,
        meta: {title: 'GameLab - Deploy New Lab'}
      },
      {
        path: "labs/:lab_id/pods",
        name: "Admin Pods",
        alias: "pods",
        component: AdminPods,
        meta: {title: 'GameLab - Admin Lab Pods'}
      },
      {
        path: "labs/edit/:lab_id",
        name: "Admin Edit Lab",
        alias: "labs/edit/:lab_id",
        component: AdminEditLab,
        meta: {title: 'GameLab - Admin Edit Lab'}
      },
      {
        path: "labs/:lab_id/pods/deploy",
        name: "Admin Pod Create",
        alias: "labs/:lab_id/pods/deploy",
        component: AdminNewPod,
        meta: {title: 'GameLab - Deploy New Pod'}
      },
      {
        path: "pods/deploy",
        name: "Admin Pod Create",
        alias: "pods/deploy",
        component: AdminNewPod,
        meta: {title: 'GameLab - Deploy New Pod'}
      },
      {
        path: "labs/:lab_id/pods/:pod_id",
        name: "Admin Edit Pod",
        alias: "pods/:pod_id",
        component: AdminEditPod,
        meta: {title: 'GameLab - Admin Edit Pod'}
      },
      {
        path: "games",
        name: "Admin Games",
        alias: "games",
        component: AdminGames,
        meta: {title: 'GameLab - Admin Games'}
      },
      {
        path: "pod_games/:xd",
        name: "Admin Pod Games",
        alias: "pod_games",
        component: AdminPodGames,
        meta: {title: 'GameLab - Admin Games'}
      },
      {
        path: "reservations",
        name: "Admin Reservations",
        alias: "reservations",
        component: AdminReservations,
        meta: {title: 'GameLab - Admin Reservations'}
      },
      {
        path: "reservations/create",
        name: "Admin Create Reservation",
        alias: "create_reservation",
        component: AdminNewReservation,
        meta: {title: 'GameLab - Admin New Reservation'}
      },
      {
        path: "reservations/edit/:reservation_id",
        name: "Admin Edit Reservation",
        alias: "edit_reservation",
        component: AdminEditReservation,
        meta: {title: 'GameLab - Admin Edit Reservation'}
      },
    ]
  },
  {
    path: "/superadmin",
    name: '/superadmin',
    component: SuperAdminLayout,
    beforeEnter: checkIfValidSuperAdmin,
    children: [
      {
        path: "dashboard",
        name: "Super Admin Dashboard",
        alias: "dashboard",
        component: SuperAdminDashboard,
        meta: {title: 'GameLab - Super Admin Dashboard'}
      },
      {
        path: "clients",
        name: "Super Admin Clients",
        alias: "clients",
        component: SuperAdminClients,
        meta: {title: 'GameLab - Super Admin Clients'}
      },
      {
        path: "clients/deploy",
        name: "Super Admin Client Deploy",
        alias: "clients/deploy",
        component: SuperAdminNewClient,
        meta: {title: 'GameLab - Deploy New Client'}
      },
      {
        path: "clients/:client_id",
        name: "Super Admin Client Detail",
        alias: "clients/:client_id",
        component: SuperAdminClientsDetails,
        meta: {title: 'GameLab - Super Admin Client Detail'}
      },
      {
        path: "clients/:client_id/pods/:lab_id",
        name: "Super Admin Client Pods",
        alias: "clients/:client_id/pods/:lab_id",
        component: SuperAdminClientsPods,
        meta: {title: 'GameLab - Super Admin Lab Pods'}
      },
      {
        path: "games",
        name: "Super Admin Games",
        alias: "games",
        component: SuperAdminGames,
        meta: {title: 'GameLab - Super Admin Manage Games'}
      },
      {
        path: "games/deploy",
        name: "Super Admin Deploy Game",
        alias: "games/deploy",
        component: SuperAdminNewGame,
        meta: {title: 'GameLab - Deploy New Game'}
      },
      {
        path: "games/edit/:game_id",
        name: "Super Admin Edit Game",
        alias: "games/deploy",
        component: SuperAdminEditGame,
        meta: {title: 'GameLab - Super Admin Edit Game'}
      },
      {
        path: "system_messages",
        name: "Super Admin System Messages",
        alias: "system_messages",
        component: SystemMessagesList,
        meta: {title: 'GameLab - Super Admin System Messages'}
      },
      {
        path: "db_backups",
        name: "Super Admin DB Backups",
        alias: "db_backups",
        component: SuperAdminDbBackups,
        meta: {title: 'GameLab - Super Admin DB Backups'}
      },
    ]
  },
  {
    path: "/common",
    name: '/common',
    component: CommonLayout,
    beforeEnter: checkIfValidCommonUser,
    children: [
      {
        path: "users",
        name: "Users List",
        alias: "users",
        component: UsersList,
        meta: {title: 'GameLab - Manage Users'}
      },
      {
        path: "users/create",
        name: "Create User",
        alias: "users/create",
        component: NewUser,
        meta: {title: 'GameLab - Create New User'}
      },
      {
        path: "users/edit/:user_id",
        name: "Edit User",
        alias: "users/edit/:user_id",
        component: EditUser,
        meta: {title: 'GameLab - Edit User'}
      },
      {
        path: "users/view/:user_id",
        name: "View User",
        alias: "users/view/:user_id",
        component: ViewUser,
        meta: {title: 'GameLab - View User'}
      },
      {
        path: "alerts",
        name: "Alerts List",
        alias: "alerts",
        component: AlertsList,
        meta: {title: 'GameLab - Alerts List'}
      },
      {
        path: "reports/summary",
        name: "Summary Report",
        alias: "reports/summary",
        component: SummaryReport,
        meta: {title: 'GameLab - Summary Report'}
      },
      {
        path: "reports/status",
        name: "Status Report",
        alias: "reports/status",
        component: StatusReport,
        meta: {title: 'GameLab - Status Report'}
      }
    ]
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
});

export default router;
