export const cloud_api_routes = {
    KIOSK_VALIDATE_USERAGENTIP: '/kiosk/check/allowlisted',
    KIOSK_VALIDATE_POD_ID: '/kiosk/validate/pod_id_key',
    KIOSK_VALIDATE_REMOTE_ID: '/kiosk/validate/remote',
    KIOSK_CHECK_SESSION_ID: '/kiosk/check/session',
    USER_LOGIN: '/user/login',
    USER_MOBILE_LOGIN: '/user/mobile_login',
    USER_REGISTER: '/user/register',
    USER_CHECK_EMAIL: '/user/check_email',
    USER_LOGOUT: '/user/logout',
    USER_EXPIRE_SESSIONS: '/user/expire_sessions',
    USER_UNLOCK: '/user/unlock_user',

    PASS_RESET_MAIL: '/user/reset-pass/send_code',
    PASS_RESET_CODE: '/user/reset-pass/verify_code',
    PASS_RESET_CHANGE: '/user/reset-pass/save_password',

    PLAYER_GAMES_LIST: '/game/list',
    PLAYER_STREAM_FLAG: '/user/update/streaming',
    PLAYER_POD: '/pod',
    PLAYER_POD_SET_VOLUME: '/pod/set_volume',
    PLAYER_POD_SET_LOCKOUT: '/pod/set_lockout',
    PLAYER_OFFERS_LIST: '/user/player_offers',
    PLAYER_GET_PAYMENT_INTENT: '/user/payment/create_intent',
    PLAYER_CONFIRM_PAYMENT_INTENT: '/user/payment/confirm_intent',
    PLAYER_GET_PAYMENT_METHODS: '/user/payment/available_methods',
    PLAYER_UPDATE_SAVE_CARD: '/user/payment/update_save_card',
    PLAYER_DELETE_PAYMENT_METHOD: '/user/payment/delete_method',
    PLAYER_GET_REMAINING_TIME: '/user/remaining_time',
    PLAYER_GET_PAYMENT_METHOD_ID: '/user/payment/create_method',

    FAC_RUN_COMMAND: '/facilitator/run/command',
    FAC_PODS_LIST: '/facilitator/pods/list',
    FAC_RGB_PALLETS: '/kiosk/rgb_pallets',
    FAC_PLAYER_THEME_LOGO: '/kiosk/theme_logo',

    ADMIN_LOGIN: '/admin/login',
    ADMIN_LOGOUT: '/admin/logout',
    ADMIN_LABS: '/admin/kiosk/labs',
    ADMIN_PODS: '/admin/kiosk/pods',
    ADMIN_CLIENTS: '/admin/kiosk/clients',
    ADMIN_SAVE_LAB: '/admin/kiosk/deployment/lab',
    ADMIN_EDIT_LAB: '/admin/kiosk/edit/lab',
    ADMIN_SAVE_LAB_SCHEDULE: '/admin/kiosk/deployment/lab_schedule',
    ADMIN_SAVE_LAB_SCHEDULE_OVERRIDE: '/admin/kiosk/deployment/lab_override_schedule',
    ADMIN_DELETE_LAB_SCHEDULE_OVERRIDE: '/admin/kiosk/delete/override_schedule',
    ADMIN_UPLOAD_LAB_ALLOWEDIPS: '/admin/kiosk/upload/lab_allowedips',
    ADMIN_DELETE_ALLOWEDIP: '/admin/kiosk/delete/lab_allowedip',
    ADMIN_POD_TYPES: '/admin/kiosk/pods_type',
    ADMIN_NAVS: '/admin/kiosk/navs',
    ADMIN_MAC_VALIDATE: '/admin/kiosk/validate/mac_address',
    ADMIN_SAVE_POD: '/admin/kiosk/deployment/pod',
    ADMIN_GAMES_LIST: '/admin/kiosk/games_list',
    ADMIN_SAVE_ADD_GAMEID: '/admin/kiosk/add_gameids',
    ADMIN_SAVE_DEL_GAMEID: '/admin/kiosk/del_gameids',
    ADMIN_SAVE_CLIENT: '/admin/kiosk/deployment/client',
    ADMIN_UPDATE_CLIENT: '/admin/kiosk/clients/update',
    ADMIN_CLIENT_DETAILS: '/admin/kiosk/client',
    ADMIN_CLIENT__LAB_PODS: '/admin/kiosk/client/pods',
    ADMIN_RUN_COMMAND: '/admin/run/command',
    ADMIN_SAVE_GAME: '/admin/game/create',
    ADMIN_EDIT_POD: '/admin/kiosk/edit/pod',
    ADMIN_UPDATE_POD: '/admin/kiosk/update/pod',
    ADMIN_DELETE_POD: '/admin/kiosk/delete/pod',
    ADMIN_RGB_PALLETS: '/admin/kiosk/rgb_pallets',
    ADMIN_UPDATE_RGB_PALLET: '/admin/kiosk/update_client_rgb_pallet',
    ADMIN_UPDATE_THEME_LOGO: '/admin/kiosk/update_client_theme_logo',
    ADMIN_GET_THEME_LOGO: '/admin/kiosk/get_client_theme_logo',

    ADMIN_EDIT_GAME: '/admin/game/edit',
    ADMIN_UPDATE_GAME: '/admin/game/update',
    ADMIN_DELETE_GAME: '/admin/game/delete',

    ADMIN_KIOSK_VALIDATE_POD_ID: '/admin/kiosk/validate/pod_id_key',

    ADMIN_GET_DOMAINS: '/admin/kiosk/domains',
    ADMIN_ADD_DOMAIN: '/admin/kiosk/domains/add',
    ADMIN_DELETE_DOMAIN: '/admin/kiosk/domains/delete',

    SESSION_SAVE: '/session/save',
    SESSION_FLASK: '/session/flask',
    SESSION_UPDATE_ACTIVITY: '/session/activity_update',

    // users management
    USERS_LIST: '/admin/kiosk/users',
    USERS_SAVE: '/admin/kiosk/users/save',
    USERS_EDIT: '/admin/kiosk/users/edit',
    USERS_SWITCHES: '/admin/kiosk/users/update_switches',
    USERS_ADD_COMP_TIME: '/admin/kiosk/users/add_comp_time',
    USERS_DELETE_COMP_TIME: '/admin/kiosk/users/delete_comp_time',
    ROLES_LIST: '/admin/kiosk/roles',
    USERS_PLAYING_ACTIVITIES: '/common/players/playing_activities',
    USERS_PAYMENTS: '/common/players/payments',

    // alerts management
    ALERTS_LIST: '/common/alerts/list',
    ALERTS_SAVE: '/common/alerts/save',
    ALERTS_DELETE: '/common/alerts/delete',

    // system messages management
    SYSTEM_MESSAGES_LIST: '/admin/system_messages/list',
    SYSTEM_MESSAGES_SAVE: '/admin/system_messages/save',
    SYSTEM_MESSAGES_GET: '/system_messages/get_fe',

    // db backups management
    ADMIN_DB_BACKUPS_LIST: '/admin/db_backups/list',
    ADMIN_DB_BACKUPS_DELETE: '/admin/db_backups/delete',

    // reports management
    REPORT_SUMMARY: '/common/reports/summary',
    REPORT_STATUS: '/common/reports/status',

    // survey management
    SURVEY_GAMES_LIST: '/survey/games',
    SURVEY_SAVE: '/survey/save',

    // orientation
    ORIENTATION_MARK: '/orientation/mark',

    // lab images
    ADMIN_UPLOAD_LAB_HOMEIMAGE: '/admin/kiosk/upload/lab_homeimage',
    ADMIN_DELETE_LAB_HOMEIMAGE: '/admin/kiosk/delete/lab_homeimage',

    // admin pod dashboard
    ADMIN_FEEDBACK_LIST: '/admin/feedback/list',
    ADMIN_LOGS_LIST: '/admin/logs/list',
    ADMIN_LOGS_SAVE: '/admin/logs/save',

    // admin lab rss feed settings
    ADMIN_SAVE_LAB_RSS_FEED: '/admin/kiosk/deployment/lab_rss_feed',
    ADMIN_DELETE_LAB_RSS_FEED: '/admin/kiosk/delete/lab_rss_feed',

    // admin login with websocket
    USER_ADMIN_LOGIN: '/user/login_admin',

    // reservations management
    RESERVATIONS_LIST: '/admin/reservations/list',
    RESERVATIONS_SAVE: '/admin/reservations/save',
    RESERVATIONS_EDIT: '/admin/reservations/edit',
    RESERVATIONS_DELETE: '/admin/reservations/delete',
    // admin client sso settings
    ADMIN_SAVE_CLIENT_SSO_SETTING: '/admin/kiosk/client/save_sso_metadata',

    // admin lab api keys settings
    ADMIN_SAVE_LAB_API_KEYS: '/admin/kiosk/deployment/lab_api_keys',
    ADMIN_DELETE_LAB_API_KEYS: '/admin/kiosk/delete/lab_api_keys',
};
