<template>
    <div>
        <RssFeedWidget />
        <IdleWidgetTouch @getPodIdFromFlask="getPodIdFromFlask($event)" />
        <ConcurrentLoginWidget @redirectPlayerToDashboard="redirectPlayerToDashboard($event)" @makeToast="makeToast($event)" :info_message="conc_info_message" :params="conc_params" :response="conc_response" v-if="conc_info_message !== ''" />
        <MainKeyboard :main_element="current_component" />
        <!-- new view -->
        <section class="login-page" :class="[(flask_connected && all_checked && (!ip_verified || !pod_verified)) ? 'black' : 'blue', !with_keyboard ? 'without-keyboard' : '']" v-if="flask_connected && (ip_verified || pod_verified || falsk_server)">
            <AllTheme />

            <div class="smaller-screen-header">
                <div class="smaller-screen-header-logo">
                    <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                        v-if="logo_file !== '' && logo_file !== null"
                    />
                </div>
                <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
                    <span class="pod-info-nav-name">
                        {{ nav_name }}
                    </span>
                </div>
            </div>

            <div class="homepage-images">
                <div class="homepage-images-left">
                    <img :src="left_home_image" class="animate__animated animate__slideInLeft animate__delay-4s" alt="" v-if="left_home_image !== ''">
                </div>

                <div class="homepage-images-center">
                    <div class="w-logo larger-screen-header">
                        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                            v-if="logo_file !== '' && logo_file !== null"
                        />
                    </div>
                    <div class="w-form-block h-72 mt-5">
                    <!-- flas error section -->
                    <div
                        class="
                        w-login-show
                        animate__animated animate__fadeInUp animate__delay-3s 
                        move-to-header
                        "
                        v-if="!ip_verified && !falsk_server"
                    >
                        <div class="notification-block error">
                        <div class="notification-message">
                            <h5>{{ (system_flask_error.is_exist) ? system_flask_error.text_head : messages.flask_error }}</h5>
                            <p>{{ (system_flask_error.is_exist) ? system_flask_error.text_sub : messages.flask_error }}</p>
                        </div>
                        <div class="icon-error"><i class="fa-solid fa-times-circle"></i></div>
                        </div>
                    </div>

                    <!-- pod deployment section -->
                    <div class="main-action-box" v-if="(!ip_verified || !pod_verified) && falsk_server">
                        <div
                        class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-3s
                        "
                        >
                        <p
                            v-if="!flask_pod_need_deployment"
                            v-html="messages.ip_not_allowed"
                        ></p>
                        <p
                            v-if="flask_pod_need_deployment"
                            v-html="messages.pod_need_deployment"
                        ></p>
                        </div>
                        <div
                        class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-3s
                        "
                        >
                        <router-link to="/admin/login">Go to Deployment</router-link>
                        </div>
                    </div>

                    <!-- login form section -->
                    <form action="" class="" v-if="ip_verified && pod_verified && with_keyboard && !sso_active">
                        <div
                        class="
                            w-form-control w-input-fields
                            animate__animated animate__fadeInUp animate__delay-2s
                        "
                        >
                        <span><i class="fa-solid fa-user"></i></span>
                        <input
                            type="text"
                            name="email"
                            value=""
                            v-model="username"
                            placeholder="email@domain.com"
                            ref="emailinput" 
                            @focus="setFocusedElement($event, 'username', '')"
                        />
                        </div>
                        <div
                        class="
                            w-form-control w-input-fields
                            animate__animated animate__fadeInUp animate__delay-2s
                        "
                        >
                        <span><i class="fa-solid fa-lock"></i></span>
                        <input
                            type="password"
                            name="password"
                            value=""
                            v-model="password"
                            placeholder="s3cretp@ssword" 
                            @keyup.enter="onPressEnter" 
                            @focus="setFocusedElement($event, 'password', '')" 
                            ref="passinput"
                        />
                        </div>
                        <div class="main-action-box w-login-hide">
                            <div
                                class="
                                w-form-control
                                animate__animated animate__fadeInUp animate__delay-2s
                                "
                            >
                                <router-link :to="{name: 'Register', params: {uname:username, pass:password}}" class="flex-fill">Sign Up</router-link>
                                <a
                                href="javascript:void(0)"
                                @click="handleLoginReq"
                                class="flex-fill"
                                >Login</a
                                >
                            </div>
                            <div
                                class="w-form-control height-0" 
                                id="push-down-qr"
                            >
                                <router-link :to="{name: 'Forgot Password', params: {uname:username}}" class="flex-fill animate__animated animate__fadeIn animate__delay-1s" v-if="login_attempts > 0">Reset My Password</router-link>
                            </div>
                        </div>
                        <div class="w-login-show" style="width: 370px !important; text-align: center; margin: auto;">
                            <div class="notification-block p-1"></div>
                        </div>
                    </form>

                    <div class="main-action-box w-login-hide" v-if="ip_verified && pod_verified && sso_active">
                        <!-- <div
                            class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-2s
                            "
                        >
                            <p class="">Login using your official <b>{{ client_name }}</b> credentials.</p>
                        </div> -->
                        <div
                            class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-2s
                            "
                        >
                            <a
                            :href="sso_urls.login" 
                            target="_blank"
                            class="flex-fill" 
                            v-if="!is_pod_updating"
                            >Login with SSO</a
                            >
                            <a
                            href="javascript:void(0);" 
                            class="flex-fill login-disabled-button" 
                            disabled="disabled"
                            v-else
                            >Login with SSO</a
                            >
                        </div>
                    </div>

                    <div class="w-form-control pod-info-section animate__animated animate__fadeInUp animate__delay-2s" v-if="(xds_data.xd1 !== '' && xds_data.xd1 !== 'na') || (xds_data.xd2 !== '' && xds_data.xd2 !== 'na')">
                        <div class="pod-info-section-left">
                            <i class="fa-solid fa-wave-square"></i>
                        </div>
                        <div class="pod-info-section-right">
                            <template v-if="xds_data.xd1 !== '' && xds_data.xd1 !== 'na' && xds_data.xd1 !== 'start'">
                                <div class="pod-info-section-right-button" v-if="!xds_data[xds_data.xd1+'_disabled']">
                                    <div class="pod-info-section-right-button-left">
                                        <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd1)" tabindex="-1"></object>
                                        {{ xds_icons[xds_data.xd1].xd }}
                                    </div>
                                    <div class="pod-info-section-right-button-right">
                                        <div class="pod-info-section-right-button-right-text">
                                            Ready
                                        </div>
                                        <div class="pod-info-section-right-button-right-icon">
                                            <i class="fas fa-check"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="pod-info-section-right-button" v-if="xds_data[xds_data.xd1+'_disabled']">
                                    <div class="pod-info-section-right-button-left pod-info-section-disabled">
                                        <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd1)" tabindex="-1"></object>
                                        {{ xds_icons[xds_data.xd1].xd }}
                                    </div>
                                    <div class="pod-info-section-right-button-right disabled">
                                        <div class="pod-info-section-right-button-right">
                                            <div class="pod-info-section-right-button-right-text pod-info-section-disabled">
                                                Offline
                                            </div>
                                            <div class="pod-info-section-right-button-right-icon">
                                                <i class="fas fa-lock"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="xds_data.xd2 !== '' && xds_data.xd2 !== 'na' && xds_data.xd2 !== 'start'">
                                <div class="pod-info-section-right-button" v-if="!xds_data[xds_data.xd2+'_disabled']">
                                    <div class="pod-info-section-right-button-left">
                                        <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd2)" tabindex="-1"></object>
                                        {{ xds_icons[xds_data.xd2].xd }}
                                    </div>
                                    <div class="pod-info-section-right-button-right">
                                        <div class="pod-info-section-right-button-right">
                                            <div class="pod-info-section-right-button-right-text">
                                                Ready
                                            </div>
                                            <div class="pod-info-section-right-button-right-icon">
                                                <i class="fas fa-check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="pod-info-section-right-button" v-if="xds_data[xds_data.xd2+'_disabled']">
                                    <div class="pod-info-section-right-button-left pod-info-section-disabled">
                                        <object type="image/svg+xml" class="" :data="getXdButtonIcons(xds_data.xd2)" tabindex="-1"></object>
                                        {{ xds_icons[xds_data.xd2].xd }}
                                    </div>
                                    <div class="pod-info-section-right-button-right disabled">
                                        <div class="pod-info-section-right-button-right">
                                            <div class="pod-info-section-right-button-right-text pod-info-section-disabled">
                                                Offline
                                            </div>
                                            <div class="pod-info-section-right-button-right-icon">
                                                <i class="fas fa-lock"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="main-action-box w-login-hide" v-if="ip_verified && pod_verified && sso_active">
                        <div
                            class="
                            w-form-control
                            animate__animated animate__fadeInUp animate__delay-2s
                            "
                        >
                            <p class="admin-login-lock" @click="showAdminLogin">
                                <i class="fas fa-lock"></i>
                            </p>
                        </div>
                        
                        <div class="w-form-block m-0 admin-portal" style="background:transparent;" v-if="is_admin_login_active">
                            <!-- login form section -->
                            <form action="" class="animate__animated animate__delay-1s" :class="is_admin_login_active_class ? 'animate__fadeIn' : 'animate__fadeOut'" @submit.stop.prevent>
                                <div class="w-form-control w-input-fields">
                                    <span><i class="fa-solid fa-user"></i></span>
                                    <input
                                    type="text"
                                    name="email"
                                    value=""
                                    v-model="username"
                                    placeholder="Please Enter your Email"
                                    autofocus
                                    />
                                </div>
                                <div
                                    class="w-form-control w-input-fields"
                                >
                                    <span><i class="fa-solid fa-lock"></i></span>
                                    <input
                                    type="password"
                                    name="password"
                                    value=""
                                    v-model="password"
                                    placeholder="Password"
                                    />
                                </div>
                                <div class="main-action-box w-login-hide">
                                    <div class="w-form-control login-buttons">
                                    <a
                                        href="javascript:void(0)"
                                        @click="handleAdminLoginReq"
                                        class="flex-fill m-0"
                                        >Login As Admin</a
                                    >
                                    </div>
                                </div>
                                <div class="w-login-show">
                                    <div class="notification-block"></div>
                                </div>
                            </form>
                        </div>

                    </div>

                    <!-- error container -->
                    <div 
                    class="w-login-show animate__animated animate__fadeInUp animate__delay-2s notification-block warning mt-3 move-to-header"
                    v-if="is_pod_updating"
                    >
                        <div class="notification-message">
                            <h5>{{ (system_pod_updating.is_exist) ? system_pod_updating.text_head : 'This Gamepod is disabled or updating.' }}</h5>
                            <p>{{ (system_pod_updating.is_exist) ? system_pod_updating.text_sub : 'Please select another seat...' }}</p>
                        </div>
                        <div class="icon-warning">
                            <i class="fa-solid fa-info-circle"></i>
                        </div>
                    </div>
                    <div
                        class="w-login-show animate__animated animate__fadeInUp mt-3 w-alert-340 mx-auto move-to-header"
                        v-if="login_class !== '' && login_message !== ''"
                    >
                        <div class="notification-block" :class="login_class">
                            <div class="notification-message w-alert-290">
                                <h5>{{ login_message }}</h5>
                                <p>{{ login_message1 !== '' ? login_message1 : login_message }} <span v-if="!ip_verified || (ip_verified && !pod_verified)" v-html="ip_address"></span></p>
                            </div>
                            <div class="icon-success" v-if="login_class == 'success'">
                                <i class="fa-solid fa-circle-check"></i>
                            </div>
                            <div class="icon-error" v-if="login_class == 'error'">
                                <i class="fa-solid fa-times-circle"></i>
                            </div>
                            <div class="icon-warning" v-if="login_class == 'warning'">
                                <i class="fa-solid fa-exclamation-circle"></i>
                            </div>
                        </div>
                    </div>

                    <div 
                    class="w-login-show animate__animated animate__fadeInUp animate__delay-2s notification-block warning mt-3 move-to-header alert-generic"
                    v-if="is_reservation_start"
                    >
                        <div class="alert-generic-message" v-if="is_reservation_start && !is_reservation_started">
                            <h5>This Gamepod has an upcoming reservation.</h5>
                            <p>You will be logged out automatically at {{ reservation_data.start_time }}</p>
                        </div>
                        <div class="alert-generic-message" v-if="is_reservation_start && is_reservation_started">
                            <h5>This GamePod is reserved for an event.</h5>
                            <p>Please try another GamePod or return after {{ reservation_data.stop_time }}</p>
                        </div>
                        <div class="icon-warning">
                            <i class="fa-solid fa-info-circle"></i>
                        </div>
                    </div>
                    
                    <div
                        class="main-action-box w-login-hide qr-code-bottom-stick"
                        v-if="ip_verified && pod_verified && (!with_keyboard || with_keyboard) && !sso_active" 
                    >
                        <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-2s mt-3 larger-screen-header">
                            <span class="pod-info-nav-name">
                                {{ nav_name }}
                            </span>
                        </div>

                        <!-- <div
                            class="w-form-control animate__animated animate__fadeInUp animate__delay-2s"
                            style="width: 370px !important; text-align: center; margin: auto;"
                            v-if="!qr_code_refresh"
                        >
                            <p class="" v-if="with_keyboard">
                                Scan the QR code with your<br>phone to login faster.
                            </p>
                            <p class="w-300-bold" v-if="!with_keyboard">
                                Scan the QR code with your<br>phone to get started.
                            </p>
                        </div> -->
                        <div
                            class="w-form-control mt-3" 
                            :class="with_keyboard ? 'h-16666' : ''"
                            v-if="!qr_code_refresh"
                        >
                            <div class="qr-code animate__animated animate__fadeInUp animate__delay-2s" :class="with_keyboard ? 'qr-code-with-keyboard' : ''">
                                <qr-code :text="remote_url" class="qr-code-container" error-level="L"></qr-code>

                                <p class="" v-if="with_keyboard">
                                    Scan QR Code<br>for faster login.
                                </p>
                                <p class="w-300-boldd" v-if="!with_keyboard">
                                    Scan the QR code with your<br>phone to get started.
                                </p>
                            </div>
                        </div>
                        <div
                            class="w-form-control animate__animated animate__fadeInUp animate__delay-2s"
                            style="width: 370px !important; text-align: center; margin: auto;"
                            v-if="qr_code_refresh"
                        >
                            <p class="mt-3" :class="with_keyboard ? '' : 'w-300-bold'">
                                Connected...login with your<br>phone before timer expires.
                            </p>
                        </div>
                        <div
                            class="w-form-control timer animate__animated animate__fadeInUp animate__delay-2s" 
                            :class="with_keyboard ? 'timer_with_kb' : 'timer_without_kb'"
                            v-if="qr_code_refresh"
                        >
                            {{ qr_login_count }}
                        </div>
                    </div>

                    <!-- <div
                        class="main-action-box w-login-hide"
                        v-if="!ip_verified || (ip_verified && !pod_verified)"
                    >
                        <div
                            class="w-form-control h-166" 
                        >
                            <div class="qr-code animate__animated animate__fadeInUp animate__delay-2s qr-code-with-keyboard">
                                <qr-code :text="remote_url" class="qr-code-container" error-level="L"></qr-code>
                            </div>
                        </div>
                    </div> -->

                    </div>
                </div>

                <div class="homepage-images-right">
                    <img :src="right_home_image" alt="" class="animate__animated animate__slideInRight animate__delay-4s" v-if="right_home_image !== ''">
                </div>
            </div>
            <PlayerFooter type="" :visib="with_keyboard ? '' : 'd-none'" class="animate__animated animate__fadeIn animate__delay-3s outer-footer"/>
        </section>

        <!-- default login view when flask is not running -->
        <section class="black admin-portal" v-else-if="flask_connected && !ip_verified && !pod_verified && !falsk_server && all_checked">
            <div class="w-logo">
                <img
                src="../../assets/images/gamelab-logo.svg"
                alt="logo"
                class="
                    w-input-fields
                    animate__animated animate__fadeInUp animate__delay-1s
                "
                />
            </div>
            <div class="w-form-block" style="width: 370px !important; text-align: center; margin: auto;">
                <!-- login form section -->
                <form action="" class="" @submit.stop.prevent>
                <div
                    class="
                    w-form-control w-input-fields
                    animate__animated animate__fadeInUp animate__delay-2s
                    "
                >
                    <span><i class="fa-solid fa-user"></i></span>
                    <input
                    type="text"
                    name="email"
                    value=""
                    v-model="username"
                    placeholder="Please Enter your Email"
                    autofocus
                    />
                </div>
                <div
                    class="
                    w-form-control w-input-fields
                    animate__animated animate__fadeInUp animate__delay-2s
                    "
                >
                    <span><i class="fa-solid fa-lock"></i></span>
                    <input
                    type="password"
                    name="password"
                    value=""
                    v-model="password"
                    placeholder="Password"
                    />
                </div>
                <div class="main-action-box w-login-hide">
                    <div
                    class="
                        w-form-control
                        animate__animated animate__fadeInUp animate__delay-3s 
                        login-buttons
                    "
                    >
                    <a
                        href="javascript:void(0)"
                        @click="handleAdminLoginReq"
                        class="flex-fill"
                        >Login</a
                    >
                    </div>
                </div>
                <div class="w-login-show">
                    <div class="notification-block"></div>
                </div>
                </form>
                <!-- error container -->
                <div
                class="w-login-show animate__animated animate__fadeInUp"
                v-if="login_class !== '' && login_message !== ''"
                >
                <div class="notification-block" :class="login_class">
                    <div class="notification-message">
                    <h5>{{ login_message }}</h5>
                    <p v-if="login_message1 !== ''">{{ login_message1 }}</p>
                    </div>
                    <div class="icon-success" v-if="login_class == 'success'">
                    <i class="fa-solid fa-circle-check"></i>
                    </div>
                    <div class="icon-error" v-if="login_class == 'error'">
                    <i class="fa-solid fa-times-circle"></i>
                    </div>
                    <div class="icon-warning" v-if="login_class == 'warning'">
                    <i class="fa-solid fa-exclamation-circle"></i>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <section class="w-spalsh-screen" v-else-if="!flask_connected">  
            <div class="w-logo">
                <!-- <img src="../../assets/images/gamelab.svg" style="width:300px;" alt="logo" class="animate__animated animate__fadeInUp animate__delay-1s"/> -->
                <object type="image/svg+xml" class="animate__animated animate__fadeInUp animate__delay-1s" tabindex="-1" style="width:300px;" :data="require(`../../assets/images/gamelab.svg`)"></object>
            </div>
        </section>
    </div>
</template>

<script>
import store from "@/store";
import {
  validateUseragentAndIP,
  validateKioskPodId,
  userLogin,
  getClientIP,
  sessionActivitySave,
} from "../../services/HomepageService";
import qr_code from "../../assets/images/qr_code.jpg";
import {
  getPodId,
  setFlaskStreaming,
  setFlaskLoginPod,
  getXdTypes,
  getMacAddress,
  setFlaskPodSchedule
} from "../../services/FlaskService";
import { global_config } from "@/config/config.js";
import AllTheme from "@/components/themes/AllTheme.vue";
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import { adminLogin } from "../../services/AdminService";
import { getPodDetails, setPodLockout } from "../../services/PlayerService";
import { handlePushMenuPolling, getXdButtonIcons } from "../../services/Guard";
// import IdleWidget from "@/components/IdleWidget.vue";
import ConcurrentLoginWidget from "@/components/ConcurrentLoginWidget.vue";
import RssFeedWidget from "@/components/RssFeedWidget.vue";
import IdleWidgetTouch from "@/components/IdleWidgetTouch.vue";

export default {
    name: "login-view",
    components: { AllTheme, PlayerFooter, ConcurrentLoginWidget, RssFeedWidget, IdleWidgetTouch },

    data() {
        return {
            username: "",
            password: "",
            hasAccessToKioskPodType: true,
            sessionActive: true,
            with_keyboard: false,
            qr_code: qr_code,
            ip_verified: false,
            pod_verified: false,
            pod_error: "",
            // remote_url: "",
            pod_id_key: "",
            falsk_server: true,
            flask_pod_need_deployment: false,
            theme_file: "",
            theme_classes: global_config.THEME_CLASSES,
            messages: global_config.MESSAGES,
            login_message: "",
            login_message1: "",
            login_class: "",
            login_attempts: 0,
            flask_connected: false,
            // qr_login_count: global_config.QR_MAIN_TIMEOUT,
            qr_polling: null,
            // qr_code_refresh: false,
            xds_polling: null,
            is_pod_updating: false,
            xds_data: {
                "xd1" : "start", 
                "xd2" : "start", 
                "xb_disabled": false, 
                "ps_disabled": false, 
                "sw_disabled": false, 
                "pc_disabled": false, 
                "pod_disabled": false
            },
            mac_address: "",
            ip_address: "",
            is_idle: false,
            all_checked: false,
            pod_locked: false,
            menu_polling: null,
            conc_info_message: '',
            conc_params: {},
            conc_response: {},
            xds_icons: global_config.XD_ICONS,
            nav_name: '',
            left_home_image: '',
            right_home_image: '',
            sso_active: 0,
            sso_urls: [],
            client_name: '',
            is_admin_login_active: false,
            admin_login_polling: null,
            is_admin_login_active_class: false,
            current_component: this,
            pre_title: "",
        };
    },

    computed: {
        getMessage() {
            return store.getters.getMessage;
        },
        system_flask_error() {
            return this.$store.getters.getMessageDetail('flask-notrunning-error-message');
        },
        system_pod_unauthorized() {
            return this.$store.getters.getMessageDetail('login-pod-unauthorized');
        },
        system_pod_updating() {
            return this.$store.getters.getMessageDetail('login-pod-updating');
        },
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        qr_code_refresh() {
            return this.$store.getters.getQRRefreshState();
        },
        qr_login_count() {
            return this.$store.getters.getQRCounterState();
        },
        remote_url() {
            return this.$store.getters.getQRUrlState();
        },
        is_reservation_start() {
            return this.$store.getters.getReservationStartState();
        },
        reservation_data() {
            return this.$store.getters.getReservationAlertData();
        },
        is_reservation_started() {
            return this.$store.getters.getReservationEndState();
        }
    },

    beforeRouteEnter (to, from, next) {
        to, from;
        if(from.name == null) {
            to.meta.is_refreshed = true;
        }
        else {
            to.meta.is_refreshed = false;
        }
        next();
    },
    methods: {
        handleLoginReq() {
            if (this.password == "" || this.username == "")
            this.makeToast('generic-required-message');
            else if (
            this.hasAccessToKioskPodType == true &&
            this.sessionActive == true
            ) {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let session_id = pod_data.session_id_key;
            userLogin(session_id, this.username, this.password).then((response) => {
                if (response.status) {
                    if((response.data.role_id == 1 || response.data.role_id == 2) && this.is_pod_updating) {
                        this.is_pod_updating = false;
                        setTimeout(() => {
                            this.is_pod_updating = true;
                        }, 500);
                    }
                    else if (response.data.role_id == 1) {
                        // check if player already logged into other navs
                        if (response.data.logged_navs !== "") {
                            let info_message = "You are already logged in on " + response.data.logged_navs + ".";
                            let params = {
                                session_id_key: pod_data.session_id_key,
                                session_id: pod_data.session_id_key,
                            };
                            this.conc_info_message = info_message;
                            this.conc_params = params
                            this.conc_response = response;
                        } else {
                            this.redirectPlayerToDashboard(response);
                        }
                    } else if (response.data.role_id == 2) {
                        // get alerts and remove from response
                        let is_alerts = response.data.is_alerts;
                        let alerts = response.data.alerts;
                        delete response.data.is_alerts;
                        delete response.data.alerts;
                        let is_orientation = response.data.is_orientation;
                        delete response.data.is_orientation;
                        let is_orientation_paid = response.data.is_orientation_paid;
                        delete response.data.is_orientation_paid;
                        let reservation = response.data.reservation;
                        delete response.data.reservation;

                        localStorage.setItem(
                        "user_sess_data",
                        JSON.stringify(response.data)
                        );
                        // call login pod flask api
                        this.setPodLoginData(response.data.user_id, response.data.user_name, response.data.role_name);
                        this.makeToast(response.message, this.messages.success_class, response.message);
                        this.$root.$emit("live-chat-widget", "logged-in");
                        this.$router.push({ name: "Facility Dashboard", params: {'is_alerts': is_alerts, 'alerts': alerts, 'is_orientation': is_orientation, 'is_orientation_paid': is_orientation_paid, 'reservation' : reservation}});
                    } else if(response.data.role_id == 3 || response.data.role_id == 5) {
                        // get alerts and remove from response
                        let is_alerts = response.data.is_alerts;
                        let alerts = response.data.alerts;
                        delete response.data.is_alerts;
                        delete response.data.alerts;
                        let is_orientation = response.data.is_orientation;
                        delete response.data.is_orientation;
                        let is_orientation_paid = response.data.is_orientation_paid;
                        delete response.data.is_orientation_paid;
                        this.makeToast(response.message, this.messages.success_class, response.message);
                        localStorage.setItem('user_sess_data', JSON.stringify(response.data));
                        // call login pod flask api
                        this.setPodLoginData(response.data.user_id, response.data.user_name, response.data.role_name);
                        this.$router.push({ name: "Admin Dashboard", params: {'is_alerts': is_alerts, 'alerts': alerts, 'is_orientation': is_orientation, 'is_orientation_paid': is_orientation_paid}});
                    }
                    else if(response.data.role_id == 4) {
                        this.makeToast(response.message, this.messages.success_class, response.message);
                        localStorage.setItem('user_sess_data', JSON.stringify(response.data));
                        // call login pod flask api
                        this.setPodLoginData(response.data.user_id, response.data.user_name, response.data.role_name);
                        // remove styles before redirection
                        document.getElementById('client-styles').innerText = '';
                        this.$router.push("/superadmin/dashboard");
                    }
                } else {
                    if(response.message == 'notpro') {
                        this.makeToast('login-notpro-error-message');
                    }
                    else if(response.message == 'notauthorized') {
                        this.makeToast('login-notauthorized-error-message');
                    }
                    else if(response.message == 'notregistered') {
                        this.makeToast('login-notregistered-error-message');
                    }
                    else if(response.message == 'reserved') {
                        this.makeToast('', 'warning', 'This Gamepod is currently reserved.', 'Please try another Gamepod or return at ' + response.data.reservation.stop_time);
                    }
                    else {
                        if(this.login_attempts == 0) {
                            document.getElementById('push-down-qr').classList.remove('height-0');
                            document.getElementById('push-down-qr').classList.add('animate-qr-down');
                            setTimeout(() => {
                                this.login_attempts += 1;
                            }, 900);
                            setTimeout(() => {
                                if(response.message == 'invalid') {
                                    this.makeToast('login-invalid-error-message');
                                }
                                else {
                                    this.makeToast(response.message, this.messages.error_class, response.message);
                                }
                            }, 1900);
                        }
                        else {
                            this.login_attempts += 1;
                            if(response.message == 'invalid') {
                                this.makeToast('login-invalid-error-message');
                            }
                            else {
                                this.makeToast(response.message, this.messages.error_class, response.message);
                            }
                        }
                    }
                }
            });
            }
        },

        validateKioskPodId(type='') {
            validateKioskPodId(this.pod_id_key).then((response) => {
                this.all_checked = true;
                if (!response.status) {
                    this.pod_verified = false;
                    this.pod_error = response.message;
                    this.login_class = 'error';
                    this.login_message =  (this.system_pod_unauthorized.is_exist) ? this.system_pod_unauthorized.text_head : 'Gamepod not authorized.';
                    this.login_message1 = this.mac_address;
                } else {
                    this.pod_verified = response.status;
                    this.pod_error = "";
                    this.with_keyboard = response.data.with_keyboard;
                    this.nav_name = response.data.nav_name;
                    this.left_home_image = response.data.left_image;
                    this.right_home_image = response.data.right_image;
                    this.client_name = response.data.client_name;
                    this.sso_active = response.data.sso_active;
                    this.sso_urls = response.data.sso_urls;
                    // set theme file and logo file for pod
                    // this.logo_file = response.data.logo;
                    // this.theme_file = response.data.theme;
                    this.$store.commit("setThemeLogo", response.data);
                    this.getThemeFile();
                    if (response.data.with_keyboard) {
                        localStorage.setItem(
                            "pod_sess_data",
                            JSON.stringify(response.data)
                        );
                        // this.remote_url =
                        //     window.location.origin +
                        //     "/mobile-login/" +
                        //     response.data.remote_id_key;
                        this.$store.commit("setQRUrl", window.location.origin + "/mobile-login/" + response.data.remote_id_key);
                        this.$root.$emit("live-chat-widget", "logged-in");
                        setTimeout(() => {
                            this.$root.$emit("show_hide_chat_icon", {'type' : 'show'});
                            // this.$refs.emailinput.focus();
                        }, 5000);
                    } else {
                        // clear already stored data
                        localStorage.removeItem("user_sess_data");
                        localStorage.removeItem("pod_sess_data");
                        localStorage.setItem(
                            "pod_sess_data",
                            JSON.stringify(response.data)
                        );
                        // this.remote_url =
                        //     window.location.origin +
                        //     "/mobile-login/" +
                        //     response.data.remote_id_key;
                        this.$store.commit("setQRUrl", window.location.origin + "/mobile-login/" + response.data.remote_id_key);
                        setTimeout(() => {
                            this.$root.$emit("show_hide_chat_icon", {'type' : 'hide'});
                        }, 4000);
                    }
                    if(type == 'polling') {
                        // this.qr_code_refresh = false;
                        this.$store.commit("setQRRefresh", false);
                        document.title = this.pre_title;
                    }
                    setTimeout(() => {
                        // call polling function again
                        // if(type == 'polling') {
                        //     this.qr_code_refresh = false;
                        // }
                        // this.pollQRCode();
                    }, 4000);
                    // call activity record api
                    // if(type !== 'polling') {
                    sessionActivitySave({'activity': global_config.ACTIVITIES.UI_HOME}).then((response) => {
                        console.log("ACTIVITY API CALLED : ", response);
                    });
                    // }
                    
                    // call set pod schedule api
                    if(response.data.schedule_start !== '' && response.data.schedule_end !== '') {
                        this.setPodSchedule(response.data.schedule_start, response.data.schedule_end, response.data.schedule_closed);
                    }
                }
            });
        },

        validateUseragentAndIP() {
            validateUseragentAndIP({'pod_id' : this.pod_id_key}).then((response) => {
                if (response.message == "Exist") {
                    this.ip_verified = true;
                    this.validateKioskPodId();
                }
                else {
                    this.ip_address = response.data.ip;
                    this.login_class = 'error';
                    this.login_message = (this.system_pod_unauthorized.is_exist) ? this.system_pod_unauthorized.text_head : 'Gamepod not authorized.';
                    this.login_message1 = this.mac_address;
                    // this.remote_url =
                    // window.location.origin +
                    // "?mac=" + this.mac_address + "&lan=" + this.ip_address;
                    let url = window.location.origin + "?mac=" + this.mac_address + "&lan=" + this.ip_address;
                    this.$store.commit("setQRUrl", url);
                    this.all_checked = true;
                }
            });
        },

        getPodIdFromFlask() {
            getPodId().then((pod) => {
                
                setTimeout(() => {
                    this.flask_connected = true;
                }, 5000);
                if (
                    pod !== null && typeof pod !== 'undefined' && 
                    Object.prototype.hasOwnProperty.call(pod, "success") &&
                    pod.success &&
                    Object.prototype.hasOwnProperty.call(pod, "data") &&
                    Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                    pod.data.pod_id.length > 0
                ) {
                    this.falsk_server = true;
                    this.flask_pod_need_deployment = false;
                    this.pod_id_key = pod.data.pod_id;
                    this.validateUseragentAndIP();
                    this.pollXdTypes();
                    this.$store.commit("setIdleFlagUpater", true);
                    clearInterval(this.menu_polling);
                    this.menu_polling = handlePushMenuPolling(this);
                } else if (
                    Object.prototype.hasOwnProperty.call(pod, "success") &&
                    pod.success
                ) {
                    this.falsk_server = true;
                    this.flask_pod_need_deployment = true;
                    this.all_checked = true;
                    // this.makeToast("error", "Please deploy this POD first!");
                } else {
                    this.falsk_server = false;
                    this.flask_pod_need_deployment = false;
                    this.all_checked = true;
                    // this.makeToast("error", "Flask API server doesn't work now!");
                }
            });
        },

        getMacaddressFromFlask() {
            getMacAddress().then((pod) => {
                if (
                    Object.prototype.hasOwnProperty.call(pod, "success") &&
                    pod.success &&
                    Object.prototype.hasOwnProperty.call(pod, "data") &&
                    Object.prototype.hasOwnProperty.call(pod.data, "mac_address") &&
                    pod.data.mac_address.length > 0
                ) {
                    this.mac_address = pod.data.mac_address;
                    
                }
                else {
                    this.mac_address = " ";
                }
            });
        },

        redirectPlayerToDashboard(response) {
            // get alerts and remove from response
            let is_alerts = response.data.is_alerts;
            let alerts = response.data.alerts;
            delete response.data.is_alerts;
            delete response.data.alerts;
            let is_orientation = response.data.is_orientation;
            delete response.data.is_orientation;
            let is_orientation_paid = response.data.is_orientation_paid;
            delete response.data.is_orientation_paid;
            let reservation = response.data.reservation;
            delete response.data.reservation;
            localStorage.setItem("user_sess_data", JSON.stringify(response.data));
            this.makeToast(response.message, this.messages.success_class, response.message);
            this.$root.$emit("live-chat-widget", "logged-in");

            // send stream default call
            let params = {
                is_streaming: response.data.stream_default ? true : false,
            };
            setFlaskStreaming(params).then((fst) => {
                console.log("STREAM DEFAULT API CALLED : ", fst);
            });
            // call login pod flask api
            this.setPodLoginData(response.data.user_id, response.data.user_name, response.data.role_name);
            this.$router.push({ name: "Dashboard", params: {'is_alerts': is_alerts, 'alerts': alerts, 'is_orientation' : is_orientation, 'is_orientation_paid': is_orientation_paid, 'reservation' : reservation}});
        },

        makeToast(message_key = '', variant = "default", message="", message1="") {
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.login_class = smessage.type == 'failure' ? 'error' : smessage.type;
                this.login_message = smessage.text_head;
                this.login_message1 = smessage.text_sub;
            }
            else {
                this.login_class = variant;
                this.login_message = message;
                this.login_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.login_class = "";
                this.login_message = "";
                this.login_message1 = "";
            }, 5000);
        },
        getThemeFile() {
            // remove existing classes
            for (let i = 0; i < this.theme_classes.length; i++) {
            document.body.classList.remove(this.theme_classes[i]);
            }
            if (this.theme_file !== "" && this.theme_file !== null) {
            let class_name = this.theme_file + "Theme";
            document.body.classList.add(class_name);
            }
        },

        pollQRCode () {
            this.qr_polling = setInterval(() => {
                let value = this.$store.getters.getQRCounterState();
                if(value > 1) {
                    // this.qr_login_count -= 1;
                    this.$store.commit("setQRCounter", value - 1);
                    localStorage.setItem('last_activity', new Date());
                }
                else {
                    clearInterval(this.qr_polling);
                    // this.qr_login_count = 0;
                    this.$store.commit("setQRCounter", 0);
                    this.validateKioskPodId('polling');
                }
            }, 1000);
        },

        pollXdTypes () {
            this.xds_polling = setInterval(() => {
                // get xd types for buttons
                getXdTypes().then(xd => {
                    if(xd.data !== undefined) {
                        this.xds_data = xd.data;
                        if((this.xds_data.xd1 == 'na' && this.xds_data.xd2 == 'na') || (this.xds_data[this.xds_data.xd1+'_disabled'] && this.xds_data[this.xds_data.xd2+'_disabled']) || this.xds_data.pod_disabled) {
                            this.is_pod_updating = true;
                        }
                        else {
                            this.is_pod_updating = false;
                        }
                        if(this.xds_data.pod_disabled !== this.pod_locked) {
                            this.lockoutChange(this.xds_data.pod_disabled);
                        }
                    }
                });
            }, 3000);
        },

        getClientIPFromWeb() {
            getClientIP().then((ip) => {
                if (
                    Object.prototype.hasOwnProperty.call(ip, "ip") &&
                    ip.ip.length > 0
                ) {
                    this.ip_address = ip.ip;
                }
            });
        },

        handleAdminLoginReq() {
            if (this.password == "" || this.username == "")
                this.makeToast('generic-required-message');
            else {
                adminLogin(this.username, this.password).then(response => {
                    if(response.status) {
                        if(response.data.role_id == 3) {
                            this.makeToast(response.message, this.messages.success_class, response.message);
                            localStorage.setItem('user_sess_data', JSON.stringify(response.data));
                            document.getElementById('client-styles').innerText = response.data.styles;
                            this.$router.push("/admin/dashboard");
                        }
                        else if(response.data.role_id == 4) {
                            this.makeToast(response.message, this.messages.success_class, response.message);
                            localStorage.setItem('user_sess_data', JSON.stringify(response.data));
                            document.getElementById('client-styles').innerText = '';
                            this.$router.push("/superadmin/dashboard");
                        }
                        else {
                            this.makeToast('generic-permission-error-message');
                        }
                    }
                    else if(response.message == 'Not authorized') {
                        this.makeToast('login-adminsonly-warning-message');
                    }
                    else {
                        this.makeToast(response.message, this.messages.error_class, response.message);
                    }
                })
            }
        },

        podDetails(type="") {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id : pod_data.session_id_key,
            };
            getPodDetails(this.pod_id_key, params).then(response => {
                if(response.status) {
                    this.pod_locked = response.data.pod.lock_out == 1 ? true : false;
                }
                if(type !== '') {
                    this.pollXdTypes();
                }
            });
        },

        lockoutChange(lockout) {
            clearInterval(this.xds_polling);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                session_id: pod_data.session_id_key,
                pod_id_key: this.pod_id_key,
                lockout: lockout ? 'yes' : 'no',
            };

            setPodLockout(params).then(response => {
                if(response.status) {
                    this.podDetails("poll");
                }
                else {
                    this.podDetails("poll");
                }
            })
        },
  
        setPodLoginData(user_id, user_name, role) {
            if(typeof localStorage.pod_sess_data !== 'undefined') {
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                // call login pod flask api
                let params1 = {
                    session_id: pod_data.session_id_key,
                    pod_id_key: pod_data.pod_id_key,
                    user_id: user_id,
                    user_name: user_name +";" + role,
                };
                setFlaskLoginPod(params1).then((fst) => {
                    fst;
                });   
            }
        },

        onPressEnter() {
            this.handleLoginReq();
        },
        setPodSchedule (start, end, is_closed) {
            // get xd types for buttons
            setFlaskPodSchedule({'scheduled_start': (is_closed ? 'closed' : start), 'scheduled_sleep': (is_closed ? 'closed' : end)}).then(sch => {
                console.log("FLASK POD SCHEDULE CALLED : ", sch);
            });
        },
        getXdButtonIcons(xd) {
            return getXdButtonIcons(xd);
        },
        showAdminLogin() {
            this.is_admin_login_active = true;
            this.is_admin_login_active_class = true;
            this.adminLoginPolling();
        },
        adminLoginPolling() {
            let _self = this;
            clearInterval(_self.admin_login_polling);
            this.admin_login_polling = setInterval(() => {
                if(_self.admin_login_polling) {
                    let last_activity = Math.floor((new Date()).getTime() - (new Date(localStorage.getItem('last_activity'))).getTime());
                    if(last_activity >= 5000) {
                        _self.is_admin_login_active_class = false;
                        clearInterval(_self.admin_login_polling);
                        setTimeout(() => {
                            _self.is_admin_login_active = false;  
                            _self.username = "";
                            _self.password = "";  
                        }, 1200);
                    }
                }
            }, 1000);
        },
        setFocusedElement(event, ref, form) {
            this.$store.commit("setFocusedElement", ref);
            this.$store.commit("setFocusedElementForm", {'form': form, 'target' : event});
        }
    },

    mounted() {
        this.getPodIdFromFlask();
        this.getMacaddressFromFlask();
        this.$store.commit("setQRRefresh", false);
        // this.getClientIPFromWeb();
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "message-send")) {
            // listen for websocket messages
            window.Echo.channel("message-send").listen("SendMessage", (e) => {
                console.log("HERE IS EVENT : ", e.message);
            if (
                e.message.status &&
                e.message.data.pod.pod_id_key == this.pod_id_key && 
                e.message.server_info == process.env.VUE_APP_WS_ENV
            ) {
                if(e.message.message == 'remote_init') {
                    clearInterval(this.qr_polling);
                    // this.qr_login_count = global_config.QR_MAIN_TIMEOUT;
                    // this.qr_code_refresh = true;
                    this.pre_title = document.title;
                    document.title = "GameLab - Remote";
                    this.$store.commit("setQRRefresh", true);
                    this.$store.commit("setQRCounter", global_config.QR_MAIN_TIMEOUT);
                    this.pollQRCode();
                    // record remote activity
                    sessionActivitySave({'activity': global_config.ACTIVITIES.UI_REMOTE}).then((response) => {
                        console.log("ACTIVITY API CALLED : ", response);
                    });
                    this.$forceUpdate();
                }
                else if(e.message.message == 'remote_init_reg_forgot') {
                    clearInterval(this.qr_polling);
                    let value = this.$store.getters.getQRCounterState();
                    if(value + global_config.QR_EXTRA_TIMEOUT <= global_config.QR_MAX_TIMEOUT) {
                        // this.qr_login_count += global_config.QR_EXTRA_TIMEOUT; 
                        this.$store.commit("setQRCounter", value + global_config.QR_EXTRA_TIMEOUT);
                    }
                    else {
                        // this.qr_login_count = global_config.QR_MAX_TIMEOUT;
                        this.$store.commit("setQRCounter", global_config.QR_MAX_TIMEOUT);
                    }
                    this.pollQRCode();
                }
                else if((e.message.data.user.role_id == 1 || e.message.data.user.role_id == 2) && this.is_pod_updating) {
                    return;
                }
                else if (e.message.data.user.role_id == 1) {
                    this.$store.commit("setQRRefresh", false);
                    localStorage.setItem(
                        "pod_sess_data",
                        JSON.stringify(e.message.data.pod)
                    );
                    let pod_data = e.message.data.pod;
                    let response = {
                        data: e.message.data.user,
                        message: e.message.message,
                    };
                    
                    // check if player already logged into other navs
                    if (response.data.logged_navs !== "") {
                        let info_message = "You are already logged in on " + response.data.logged_navs + ".";
                        let params = {
                            session_id_key: pod_data.session_id_key,
                            session_id: pod_data.session_id_key,
                        };
                        this.conc_info_message = info_message;
                        this.conc_params = params;
                        this.conc_response = response;
                    } else {
                        this.redirectPlayerToDashboard(response);
                    }
                } else if (e.message.data.user.role_id == 2) {
                    this.$store.commit("setQRRefresh", false);
                    this.makeToast(e.message.message, this.messages.success_class, e.message.message);
                    localStorage.setItem(
                        "pod_sess_data",
                        JSON.stringify(e.message.data.pod)
                    );
                    // get alerts and remove from response
                    let is_alerts = e.message.data.user.is_alerts;
                    let alerts = e.message.data.user.alerts;
                    delete e.message.data.user.is_alerts;
                    delete e.message.data.user.alerts;
                    let is_orientation = e.message.data.user.is_orientation;
                    delete e.message.data.user.is_orientation;
                    let is_orientation_paid = e.message.data.user.is_orientation_paid;
                    delete e.message.data.user.is_orientation_paid;
                    let reservation = e.message.data.user.reservation;
                    delete e.message.data.user.reservation;

                    localStorage.setItem(
                        "user_sess_data",
                        JSON.stringify(e.message.data.user)
                    );
                    // call login pod flask api
                    this.setPodLoginData(e.message.data.user.user_id, e.message.data.user.user_name, e.message.data.user.role_name);
                    this.$root.$emit("live-chat-widget", "logged-in");
                    this.$router.push({ name: "Facility Dashboard", params: {'is_alerts': is_alerts, 'alerts': alerts, 'is_orientation': is_orientation, 'is_orientation_paid': is_orientation_paid, 'reservation' : reservation}});
                } else if(e.message.data.user.role_id == 3 || e.message.data.user.role_id == 5) {
                    this.$store.commit("setQRRefresh", false);
                    // get alerts and remove from response
                    let is_alerts = e.message.data.user.is_alerts;
                    let alerts = e.message.data.user.alerts;
                    delete e.message.data.user.is_alerts;
                    delete e.message.data.user.alerts;
                    let is_orientation = e.message.data.user.is_orientation;
                    delete e.message.data.user.is_orientation;
                    let is_orientation_paid = e.message.data.user.is_orientation_paid;
                    delete e.message.data.user.is_orientation_paid;
                    localStorage.setItem('user_sess_data', JSON.stringify(e.message.data.user));
                    // call login pod flask api
                    this.setPodLoginData(e.message.data.user.user_id, e.message.data.user.user_name, e.message.data.user.role_name);
                    this.$router.push({ name: "Admin Dashboard", params: {'is_alerts': is_alerts, 'alerts': alerts, 'is_orientation': is_orientation, 'is_orientation_paid': is_orientation_paid}});
                }
                else if(e.message.data.user.role_id == 4) {
                    this.$store.commit("setQRRefresh", false);
                    localStorage.setItem('user_sess_data', JSON.stringify(e.message.data.user));
                    // call login pod flask api
                    this.setPodLoginData(e.message.data.user.user_id, e.message.data.user.user_name, e.message.data.user.role_name);
                    // remove styles before redirection
                    document.getElementById('client-styles').innerText = '';
                    this.$router.push("/superadmin/dashboard");
                }
            } else if (
                !e.message.status &&
                e.message.data.pod.pod_id_key == this.pod_id_key && 
                e.message.server_info == process.env.VUE_APP_WS_ENV
            ) {
                this.makeToast(e.message.message, this.messages.error_class, e.message.message);
            }
            });
        }
        // check if channel already subscribed
        if(!Object.prototype.hasOwnProperty.call(window.Echo.connector.channels, "pod-command-login")) {
            // listen for pod schedule event
            window.Echo.channel('pod-command-login').listen('PodCommandLoginEvent', (e) => {
                
                if(
                e.message.data.pod_ids.indexOf(this.pod_id_key) !== -1 && 
                e.message.server_info == process.env.VUE_APP_WS_ENV
                ) {
                    if(e.message.data.command_type == 'schedule') {
                        if(e.message.data.start !== '' && e.message.data.end !== '' ) {
                            this.setPodSchedule(e.message.data.start, e.message.data.end, e.message.data.is_closed);
                            this.$root.$emit("idle_touch_schedule_message", e.message.data);
                            if(typeof localStorage.pod_sess_data !== 'undefined') {
                                let pod_data = JSON.parse(localStorage.pod_sess_data);
                                pod_data.schedule_start = e.message.data.start;
                                pod_data.schedule_end = e.message.data.end;
                                pod_data.schedule_tomorrow = e.message.data.tomorrow;
                                localStorage.setItem('pod_sess_data', JSON.stringify(pod_data));
                            }
                        }
                    }
                }
            });
        }

        // check if info recieved from register page
        let params = this.$route.params;
        let is_email_transfer = false;
        let is_pass_transfer = false;
        if(Object.prototype.hasOwnProperty.call(params, "username") && params.username !== '') {
            this.username = params.username;
            is_email_transfer = true;
        }
        if(Object.prototype.hasOwnProperty.call(params, "password") && params.password !== '') {
            this.password = params.password;
            is_pass_transfer = true;
        }
        is_email_transfer, is_pass_transfer;
    },
    created() {
        // clear data from local storage
        localStorage.removeItem('qr_sess_data');
        localStorage.removeItem('user_sess_data');
        localStorage.removeItem('pod_sess_data');
        localStorage.removeItem('save_seat');
        localStorage.removeItem('admin_save_seat');
        // remove all cookies as well
        document.cookie.split(';').forEach(function(c) {
          document.cookie = c.trim().split('=')[0] + '=;' + 'expires=Thu, 01 Jan 1970 00:00:00 UTC;';
        });
        if(typeof this.$route.meta.is_refreshed !== 'undefined') {
            if(!this.$route.meta.is_refreshed) {
                this.flask_connected = true;
            }
        }
    }, 
    beforeDestroy () {
        clearInterval(this.qr_polling);
        clearInterval(this.xds_polling);
        clearInterval(this.menu_polling);
        clearInterval(this.admin_login_polling);
    },
};
</script>
<style scoped>
    @import url("https://fonts.googleapis.com/css?family=OpenSans:700");
    div.timer {
        position: relative;
        margin: auto;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        color: #d8d8d8;
        border-radius: 50%;
        font-weight: 700;
        /* font-family: 'OpenSans', sans-serif; */
        font-family: 'Open Sans';
        animation: 60s 0.875s cubic-bezier(0.9, 0, 0.1);
        border-bottom-left-radius: unset;
        border-bottom-right-radius: unset;
        background: var(--client-primary);
    }

    div.timer_with_kb {
        width: 150px;
        height: 150px;
        font-size: 70px;
        border: 2px solid #f48226;
    }

    div.timer_without_kb {
        width: 250px;
        height: 250px;
        font-size: 100px;
        border: 4px solid #f48226;
    }

    @keyframes timer_beat {
        40%,
        80% {
            transform: none; }
        50% {
            transform: scale(1.125);
        }
    }
    div.timer:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: -100;
        width: 100%;
        height: 100%;
        animation: 60s 1s linear forwards timer_indicator;
    }

    @keyframes timer_indicator {
        100% {
            transform: translateY(100%);
        }
    }
    .h-166 {
        height: 166px;
    }

    .homepage-images {
        display: flex;
        flex-direction: row;
        width: 100vw;
        column-gap: 20px;
        justify-content: center;
    }
    .homepage-images .homepage-images-left,
    .homepage-images .homepage-images-right {
        width: calc((100% - 370px) / 2);
        max-height: 800px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 96px;
    }
    .homepage-images .homepage-images-left img,
    .homepage-images .homepage-images-right img {
        max-width: 100%;
        max-height: 100%;
    }
    @media screen and (max-width: 767px) {
        .homepage-images .homepage-images-left,
        .homepage-images .homepage-images-right {
            display: none;
        }
    }
    .admin-login-lock {
        font-size: 20px;
        background: #3f3f3f;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>