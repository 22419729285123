<template>
  <div class="main-layout-section">
    <ReservationWidget v-if="is_reserved" @reservationAcknowledged="reservationAcknowledged($event)" :reservation="reservation" />
    <PlayerHeader @setPodData="setPodData($event)" />

    <div class="smaller-screen-header">
        <div class="smaller-screen-header-logo">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"
                v-if="logo_file !== '' && logo_file !== null"
            />
        </div>
        <div v-if="nav_name !== ''" class="w-form-control pod-info-nav animate__animated animate__fadeInUp animate__delay-1s smaller-screen-header-nav">
            <span class="pod-info-nav-name">
                {{ nav_name }}
            </span>
        </div>
    </div>

    <div class="w-logo mb-5 larger-screen-header">
        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
    </div>

    <!-- notifications section -->
    <!-- <AlertsWidget @alertsComplete="alertsComplete($event)" :alerts="alerts" v-if="!pod_locked && is_alerts" /> -->

    <!-- page main section -->
    <div class="main-content" v-if="!pod_locked">
        <!-- start of no save seat section -->
        <template v-if="!is_save_seat">
            <OrientationWidget @orientationComplete="orientationComplete($event)" :otype="orientation_type" v-if="!pod_locked && is_orientation" />
            <!-- notifications section -->
            <AlertsWidget @alertsComplete="alertsComplete($event)" :alerts="alerts" v-if="!pod_locked && is_alerts && !is_orientation" />
            <template v-if="!pod_locked && !is_alerts && !is_orientation">
                <h2 class="page-heading animate__animated animate__fadeInUp animate__delay-2s">Dashboard</h2>
                <h3 class="page-subheading mb-5 animate__animated animate__fadeInUp animate__delay-2s">Welcome {{ (user_data !== null ? (user_data.name !== '' ? (user_data.name[0].toUpperCase() + user_data.name.slice(1) + '!') : '') : '!') }}</h3>
                
                <!-- start of buy time section -->
                <div class="notification-block-outer dashboard-timebalance-container col-md-3 mx-auto mb-3" v-if="is_verification_needed == true">
                    <div class="notification-block animate__animated animate__fadeInUp animate__delay-2s" :class="[verification_warning.type == 'failure' ? 'error' : verification_warning.type]">
                        <div class="notification-message" style="width: 90%;">
                            <h5>{{ verification_warning.text_head }}</h5>
                            <p v-if="verification_warning.text_sub !== ''" v-html="verification_warning.text_sub"></p>
                        </div>
                        <div class="icon-warning" v-if="verification_warning.type == 'warning'">
                            <i class="fa-solid fa-times-circle" style="cursor: pointer;" @click="closeVerificationMessage"></i>
                        </div>
                    </div>
                </div>
                <!-- let's show this only when paid mode of lab -->
                <div class="dashboard-timebalance-container col-md-3 mx-auto" v-if="is_paid_lab">
                    <div class="timebalance-alert animate__animated animate__fadeInUp animate__delay-2s">
                        <div class="timebalance-alert-heading"><i class="fa-solid fa-stopwatch"></i> <span>Time Balance</span></div>
                        <div class="timebalance-alert-time">{{ remaining_time }}</div>
                    </div>
                </div>

                <!-- start of buttons section -->
                <div class="dashboard-buttons-container col-md-3">
                    <div class="dashboard-buttons animate__animated animate__fadeInUp animate__delay-2s">
                        <template v-if="xds_data.xd1 !== '' && xds_data.xd1 !== 'na'">
                            <router-link to="/player/games/xd1" class="dashboard-button pdash-flex" :class="xds_data.xd2 == 'na' ? 'flex-100' : ''" v-if="!xds_data[xds_data.xd1+'_disabled']">
                                <!-- <i class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :class="xds_icons[xds_data.xd1].icon"></i> -->
                                <object type="image/svg+xml" class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :data="getButtonIcons(xds_data.xd1)" tabindex="-1"></object>
                                {{ xds_icons[xds_data.xd1].text }}
                            </router-link>
                            <a href="javascript:;" class="dashboard-button pdash-flex" :class="xds_data.xd2 == 'na' ? 'flex-100' : ''" disabled="disabled" v-if="xds_data[xds_data.xd1+'_disabled']">
                                <!-- <i class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :class="xds_icons[xds_data.xd1].icon"></i> -->
                                <object type="image/svg+xml" class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :data="getButtonIcons(xds_data.xd1)" tabindex="-1"></object>
                                {{ xds_icons.disabled }}
                            </a>
                        </template>
                        <template v-if="xds_data.xd2 !== '' && xds_data.xd2 !== 'na'">
                            <router-link to="/player/games/xd2" class="dashboard-button pdash-flex" :class="xds_data.xd1 == 'na' ? 'flex-100' : ''" v-if="!xds_data[xds_data.xd2+'_disabled']">
                                <!-- <i class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :class="xds_icons[xds_data.xd2].icon"></i> -->
                                <object type="image/svg+xml" class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :data="getButtonIcons(xds_data.xd2)" tabindex="-1"></object>
                                {{ (xds_data[xds_data.xd2+'_disabled'] ? xds_icons.disabled : xds_icons[xds_data.xd2].text) }}
                            </router-link>
                            <a href="javascript:;" class="dashboard-button pdash-flex" :class="xds_data.xd1 == 'na' ? 'flex-100' : ''" disabled="disabled" v-if="xds_data[xds_data.xd2+'_disabled']">
                                <!-- <i class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :class="xds_icons[xds_data.xd2].icon"></i> -->
                                <object type="image/svg+xml" class="animate__animated animate__fadeInUp animate__delay-1s icon-button" :data="getButtonIcons(xds_data.xd2)" tabindex="-1"></object>
                                {{ xds_icons.disabled }}
                            </a>
                        </template>
                        

                        <!-- buy time -->
                        <router-link to="/player/buy-time" class="dashboard-button" v-if="is_paid_lab">
                            Buy Time
                        </router-link>
                        <!-- show disabled button if sso is active -->
                        <button class="dashboard-button" disabled="disabled" v-if="pod_sess_data.sso_active == 1">
                            Save Seat
                        </button>
                        <button class="dashboard-button" @click="initSaveSeat(true)" v-else>
                            Save Seat
                        </button>

                        <!-- logout -->
                        <a href="javascript:;" @click="handleLogout('', 'web')" class="dashboard-button flex-100">
                            Logout
                        </a>
                        <!-- <a :href="pod_sess_data.sso_urls.logout" target="_blank" class="dashboard-button flex-100" v-if="pod_sess_data.sso_active == 1">
                            Logout
                        </a> -->
                    </div>

                    <div class="notification-block-outer" v-if="remaining_time_zero === true && is_paid_lab">
                        <div class="notification-block animate__animated animate__fadeInUp animate__delay-3s mt-3" :class="[remain_time_error.type == 'failure' ? 'error' : remain_time_error.type]">
                        <div class="notification-message" style="width: 90%;">
                            <h5>{{ remain_time_error.text_head }}</h5>
                            <p v-if="remain_time_error.text_sub !== ''">{{ remain_time_error.text_sub }}</p>
                        </div>
                        <div class="icon-success" v-if="remain_time_error.type == 'success'">
                            <i class="fa-solid fa-circle-check"></i>
                        </div>
                        <div class="icon-error" v-if="remain_time_error.type == 'failure'">
                            <i class="fa-solid fa-times-circle"></i>
                        </div>
                        <div class="icon-warning" v-if="remain_time_error.type == 'warning'">
                            <i class="fa-solid fa-exclamation-circle"></i>
                        </div>
                        </div>
                    </div>

                </div>
                <!-- end of buttons section -->

                <!-- start of stream section -->
                <div class="stream-section-container col-md-3" v-if="false">
                    <div class="stream-section d-flex animate__animated animate__fadeInUp animate__delay-3s">
                    <p><span class="first">Stream Gameplay?</span><span class="second">Whatever is on your screen will be seen by others</span></p>
                    <div>
                        <i 
                        class="fas fa-circle-check fa-2xl icon h2 icon-checked" 
                        v-if="is_streaming"
                        @click="handleStreamingFlag('off')">
                        </i>
                        <i 
                        class="fas fa-circle-check fa-2xl icon h2" 
                        v-if="!is_streaming"
                        @click="handleStreamingFlag('on')">
                        </i>
                    </div>
                    </div>
                </div>
                <!-- end of stream section -->

                <!-- start of buy time section -->
                <div class="dashboard-buytime-container col-md-3 mt-70 d-none">
                    <div class="dashboard-buytime-panels">
                        <div class="panel dashboard-buytime-panel">
                            <div class="panel-heading"><i class="fa-solid fa-stopwatch"></i> <span>12h 07</span></div>
                            <div class="panel-body">Buy Time</div>
                        </div>
                        <div class="panel dashboard-buytime-panel">
                            <div class="panel-heading"><i class="fa-solid fa-basket-shopping"></i> <span>9, 100, 000</span></div>
                            <div class="panel-body">Spend XP</div>
                        </div>
                    </div>
                </div>
                <!-- end of buy time section -->

                <!-- start of volume section -->
                <div class="dashboard-volume-container col-md-3 mt-5 animate__animated animate__fadeInUp animate__delay-3s">
                    <div class="dashboard-volume mb-3">
                        <b-input-group class="d-flex text-center align-items-center">
                            <b-input-group-prepend class="mute-icons">
                                <b-icon 
                                class="icon h4" 
                                icon="volume-down-fill"
                                @click="muteUnmuteColume('mute')"
                                >
                                </b-icon>
                            </b-input-group-prepend>
                            <b-form-input 
                            type="range" 
                            class="volume-input" 
                            min="0" 
                            max="50" 
                            step="1" 
                            tabindex="-1" 
                            v-model="pod_volume"
                            @change="volumeChangeDashboard"
                            v-b-tooltip="{ title: (pod_volume * 2 + '%'), placement: 'top', trigger: 'hover focus', customClass: 'volume-tooltip' }"
                            >
                            </b-form-input>
                            <b-input-group-append class="mute-icons">
                                <b-icon 
                                class="icon h4" 
                                icon="volume-up-fill"
                                @click="muteUnmuteColume('unmute')"
                                >
                                </b-icon>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <!-- <p class="dashboard-volume-message">Need a volume adjustment while in-game?</p>
                    <p class="dashboard-volume-message">Use the physical buttons below the TV.</p> -->
                </div>
                <!-- end of voulme section -->
            </template>
        </template>
        <!-- end of no save seat section -->
        <!-- start of save seat section -->
        <template v-if="is_save_seat">
            <SaveSeatWidget @handleLogout="initSaveSeatLogout($event)" @initSaveSeat="initSaveSeat($event)" />
        </template>
        <!-- end of save seat section -->
    </div>

    <div class="main-content" v-if="pod_locked">
      <div class="d-flex justify-content-center">
        <h2 class="text-white mt-25">You have been locked out by Admin. Please wait...</h2>
      </div>
    </div>

    <!-- start of footer section -->
    <div class="col-md-12 mx-auto" :class="[!pod_locked && is_orientation ? 'footer-orientation' : '']">
        <PlayerFooter type="inner" class="animate__animated animate__fadeIn animate__delay-4s"/>
    </div>
    <!-- end of footer section -->

  </div>
</template>

<script>
import PlayerHeader from "@/views/player/PlayerHeader.vue";
import store from "@/store";
import { updateStreamingFlag, checkSessionId, getPlayerRemainingTime } from "../../services/PlayerService";
import { setFlaskStreaming, getFlaskGameStarted, getFlaskGamePlaying, getXdTypes, setFlaskPodWebTime } from "../../services/FlaskService";
import { global_config } from "@/config/config.js";
import PlayerFooter from "@/views/player/PlayerFooter.vue";
import xb_icon from "../../assets/images/icon_xbox.svg";
import sw_icon from "../../assets/images/icon_switch.svg";
import pc_icon from "../../assets/images/icon_pc.svg";
import ps_icon from "../../assets/images/icon_ps5.svg";
import { sessionActivitySave} from "../../services/HomepageService";
import { handleDocumentHeight } from "../../services/Guard";
import AlertsWidget from "@/components/AlertsWidget.vue";
import SaveSeatWidget from "@/components/SaveSeatWidget.vue";
import OrientationWidget from "@/components/OrientationWidget.vue";
import ReservationWidget from "@/components/ReservationWidget.vue";

export default {
    name: "PlayerDashboard-view",
    components: { PlayerHeader, PlayerFooter, AlertsWidget, SaveSeatWidget, OrientationWidget, ReservationWidget },
    data() {
        return {
            is_streaming: false,
            pod_id_key : "",
            pod_locked: false,
            xds_data: {
                "xd1" : "", 
                "xd2" : "", 
                "xb_disabled": true, 
                "ps_disabled": true, 
                "sw_disabled": true, 
                "pc_disabled": true, 
                "pod_disabled": true
            },
            pod_volume: 0,
            xds_polling: null,
            xds_icons: global_config.XD_ICONS,
            user_data: null,
            xb_icon: xb_icon,
            sw_icon: sw_icon,
            pc_icon: pc_icon,
            ps_icon: ps_icon,
            is_alerts: false,
            alerts: [],
            remaining_time: '',
            remaining_time_zero: false,
            game_polling: null,
            is_paid_lab: 0,
            is_save_seat: false,
            is_orientation: false,
            orientation_type: '',
            is_verification_needed: false,
            is_reserved: false,
            reservation: {},
            pod_sess_data: []
        };
    },

    computed: {
        getMessage() {
            return store.getters.getMessage;
        },
        remain_time_error() {
            return this.$store.getters.getMessageDetail('player-dashboard-remain-time-error');
        },
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        verification_warning() {
            return this.$store.getters.getMessageDetail('login-email-confirm-error-message');
        },
        nav_name() {
            return this.$store.getters.getNavName();
        }
    },

    methods: {
        handleStreamingFlag(value) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id,
                streaming: value
            };
            updateStreamingFlag(params).then(response => {
                if(response.status){
                    this.is_streaming = (value == 'on' ? true : false);
                    this.$root.$emit("toast_message", {'type' : 'success', 'message' : response.message});
                    // send stream default call
                    setFlaskStreaming({is_streaming: (value == 'on') ? true : false}).then(fst => {
                        console.log("STREAMING API CALLED : ", fst);
                    });
                    } else {
                        this.$root.$emit("toast_message", {'type' : 'error', 'message' : response.message});
                    }
            })
        },

        // check is_paid status of the pod in the lab. 
        checkIsPaidLab(){
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            if(pod_data.is_paid != undefined && pod_data.is_paid == 1){
                this.is_paid_lab = pod_data.is_paid;
            }
        },

        getStreamingFlagValue() {
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            checkSessionId(pod_data.session_id_key).then(response => {
                if(response.status){
                    this.is_streaming = response.data.is_streaming;
                } else {
                    this.is_streaming = false;
                }
                pod_data.is_streaming = this.is_streaming;
            })
        },

        makeToast(variant = "default", message) {
            this.$toast.open({
                message: message,
                type: variant,
                position: 'top-right',
                duration: 3000,
                dismissible: true
            });
        },

        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        
        muteUnmuteColume(type) {
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "PlayerHeader") {
                    item.volumeMuteUnmute(type);
                }
            });
        },
        volumeChangeDashboard() {
            let _self = this;
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "PlayerHeader") {
                    item.volumeChange(_self.pod_volume);
                }
            });
        },
        handleLogout(reason='', source, survey_needed=true) {
            document.getElementById('main-header-player').scrollIntoView()
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "PlayerHeader") {
                    item.handleLogout(reason, source, survey_needed);
                }
            });
        },
        pollFlaskXds () {
            this.xds_polling = setInterval(() => {
                // get xd types for buttons
                getXdTypes().then(xd => {
                    if(xd.data !== undefined) {
                        this.xds_data = xd.data;
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_XD_TYPE_CHECK);
        },
        setUserData() {
            if(typeof localStorage.user_sess_data !== 'undefined') {
                let user_data = JSON.parse(localStorage.user_sess_data);
                this.user_data = user_data;
                if(this.user_data.is_verified == 0) {
                    this.is_verification_needed = 1;
                }
            }
            // check if save seat in progress
            if(typeof localStorage.save_seat !== 'undefined') {
                let save_seat_data = JSON.parse(localStorage.getItem('save_seat'));
                if(save_seat_data.active == 1) {
                    this.initSaveSeat(true);
                }
            }
            if(typeof localStorage.pod_sess_data !== 'undefined') {
                this.pod_sess_data = JSON.parse(localStorage.pod_sess_data);
            }
        },
        getButtonIcons(xd) {
            return this[xd+"_icon"];
        },
        alertsComplete(obj) {
            obj;
            setTimeout(() => {
                this.is_alerts = false;
                this.alerts = [];
                handleDocumentHeight();
            }, 2000);
        },
        remainingTime() {
            // call payment save function here
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token,
                user_id: user_data.user_id
            };
            getPlayerRemainingTime(params).then(rmt => {
                this.$root.$emit("lab_mode_message", rmt.data.mode);
                this.is_paid_lab = rmt.data.mode == 'paid' ? 1 : 0;
                // convert into hours and minutes
                let hours = Math.floor(rmt.data.time / 60);
                let minutes = Math.ceil(rmt.data.time % 60); 
                this.remaining_time = hours + "h " + minutes + "m";
                this.remaining_time_zero = rmt.data.time <= 0 ? true : false;
                
                if(this.is_paid_lab){
                    // set the flask web time file save. 
                    setFlaskPodWebTime({web_time: rmt.data.time}).then(fst => {
                        console.log("WEB TIME API CALLED : ", fst);
                    });
                }
                
            });
        },

        // handle game playing time updates
        gamePlayingPolling() {
            getFlaskGamePlaying().then(gp => {
                if (
                    Object.prototype.hasOwnProperty.call(gp, "success") && gp.success &&
                    Object.prototype.hasOwnProperty.call(gp, "data") &&
                    Object.prototype.hasOwnProperty.call(gp.data, "platform") &&
                    gp.data.platform.length > 0
                ) {
                    if(typeof localStorage.game_playing !== 'undefined') {
                        let game_data = JSON.parse(localStorage.game_playing);
                        if (typeof game_data.gid !== 'undefined') {
                            this.recordGamePlayActivity({platform: gp.data.platform, game_id: game_data.gid}, 'loop');
                        }else{
                            // testing
                            console.log(game_data);    
                        }
                    }else{
                        // testing
                        console.log(localStorage.game_playing);
                    }
                }else{
                    // testing
                    console.log(gp);    
                }
            });
        },

        recordGamePlayActivity(params, type) {
            let activity = "";
            if(params.platform == 'xb') {
                activity = global_config.ACTIVITIES.PLAY_XBOX;
            }
            else if(params.platform == 'pc') {
                activity = global_config.ACTIVITIES.PLAY_PC;
            }
            else if(params.platform == 'sw') {
                activity = global_config.ACTIVITIES.PLAY_SWITCH;
            }
            else if(params.platform == 'ps') {
                activity = global_config.ACTIVITIES.PLAY_PS5;
            }
            
            // call game play activity record api
            if(activity !== "") {
                if(type == 'first') {
                    localStorage.setItem('game_playing', JSON.stringify({'gid': params.game_id, 'gname': params.search_title}));
                }
                sessionActivitySave({'activity': activity, 'game_id' : params.game_id}).then((response) => {
                    console.log("ACTIVITY API CALLED : ", response);
                });
            }
        },
        checkGamePlayingPolling(type='') {
            // need to check if game_playing file is exist
            getFlaskGameStarted().then(gs => {
                let game_started = false;
                if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined) {
                    if(gs.data.game_started) {
                        game_started = true;
                    }
                }

                if(!game_started){
                    // when game not started. 
                    // call activity record api
                    sessionActivitySave({'activity': global_config.ACTIVITIES.UI_DASHBOARD}).then((response) => {
                        console.log("ACTIVITY API CALLED : ", response);
                    });
                    clearInterval(this.game_polling);
                }else{
                    // when game playing

                    // need to check if remain time is > 0
                    if(this.remaining_time_zero === false && type == 'poll'){
                        // no need to update the web_activity in that case. need to update game playing time. 
                        this.gamePlayingPolling();
                    }
                    
                }
            });
        },
        initSaveSeat(value) {
            this.is_save_seat = value;
            if(value == false) {
                handleDocumentHeight();
                this.checkGamePlayingPolling();
            }
        },
        initSaveSeatLogout(value) {
            this.handleLogout(value.reason, value.source, false);
        },
        orientationComplete(obj) {
            obj;
            this.is_orientation = false;
        },
        closeVerificationMessage() {
            this.user_data.is_verified = 1;
            this.is_verification_needed = false;
            localStorage.setItem('user_sess_data', JSON.stringify(this.user_data));
        },
        reservationAcknowledged(obj) {
            obj;
            this.is_reserved = false;
            this.reservation = {};
        }
    },

    mounted() {
        handleDocumentHeight();
        this.getStreamingFlagValue();
        this.remainingTime();
        this.checkIsPaidLab();
        this.checkGamePlayingPolling();
        clearInterval(this.game_polling);
        this.game_polling = setInterval(() => {
            // need to check if game_playing file is exist
            this.checkGamePlayingPolling('poll');
        }, process.env.VUE_APP_POLLING_TIME_GAME_PLAYING_UPDATE);

        

        // listen for remaining time event
        this.$root.$on('remaining_time', (message) => {
            this.remaining_time = message.ftime;
            this.remaining_time_zero = message.time <= 0 ? true : false;
        });
        // listen for save seat event
        this.$root.$on('save_seat', (message) => {
            this.initSaveSeat(message);
        });
    },

    beforeDestroy () {
        clearInterval(this.xds_polling);
        clearInterval(this.game_polling);
    },
    created () {
        this.pollFlaskXds();
        this.setUserData();
        this.checkIsPaidLab();
        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation") && params.is_orientation && !this.is_paid_lab) {
            this.orientation_type = 'free';
            this.is_orientation = params.is_orientation;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation_paid") && params.is_orientation_paid && this.is_paid_lab) {
            this.orientation_type = 'paid';
            this.is_orientation = params.is_orientation_paid;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_alerts") && params.is_alerts) {
            this.is_alerts = params.is_alerts;
            this.alerts = params.alerts;
        }
        if(Object.prototype.hasOwnProperty.call(params, "save_seat") && params.save_seat) {
            this.initSaveSeat(params.save_seat);
        }
        if(Object.prototype.hasOwnProperty.call(params, "reservation") && params.reservation.is_started && !params.reservation.is_user_allowed) {
            this.is_reserved = true;
            this.reservation = params.reservation;
        }
    }
};
</script>
<style scoped>
.footer-orientation {
    position: relative;
    z-index: 100;
}
</style>