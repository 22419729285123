<template>
    <div class="main-header main-header-admin" id="main-header-admin">
      <b-navbar toggleable="lg" type="dark" variant="info" class="mb-4 header-bg d-none">
        <b-navbar-brand class="mx-2">Game Lab</b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="float-right w-100" align="right">
            <b-navbar-brand @click="handleLogout" class="main-header-link">Log out</b-navbar-brand>
          </b-navbar-nav>

        </b-collapse>
      </b-navbar>
        <div class="notification-block-outer w-alert-340">
            <div class="notification-block animate__animated animate__fadeInUp w-alert-340" :class="toast_type" v-if="toast_type !== '' && toast_message !== ''">
            <div class="notification-message w-alert-290">
                <h5>{{ toast_message }}</h5>
                <p v-if="toast_message1 !== ''">{{ toast_message1 }}</p>
            </div>
            <div class="icon-success" v-if="toast_type == 'success'">
                <i class="fa-solid fa-circle-check"></i>
            </div>
            <div class="icon-error" v-if="toast_type == 'error'">
                <i class="fa-solid fa-times-circle"></i>
            </div>
            <div class="icon-warning" v-if="toast_type == 'warning'">
                <i class="fa-solid fa-exclamation-circle"></i>
            </div>
            </div>
        </div>

    </div>
</template>
  
<script>
import { adminLogout } from "../../services/AdminService";
import { global_config } from "@/config/config.js";
import { sessionActivitySave } from "../../services/HomepageService";
export default {
    name: "SuperAdminHeader-view",
    data() {
        return {
            toast_message: '',
            toast_type: '',
            toast_message1:'',
        };
    },
    methods: {
        handleLogout() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let params = {
            token: user_data.token
        };
        adminLogout(params).then(response => {
            if(response.status || response.message == "Token is Expired" || response.message == "Token is Invalid"){
                this.$router.push({ name: "Player Logout", params: {'reason': 'you clicked the onscreen logout button'}});
                // localStorage.removeItem('qr_sess_data');
                // localStorage.removeItem('user_sess_data');
                // localStorage.removeItem('pod_sess_data');
                // this.makeToast("success", response.message);
                // this.$router.push("/admin/login");
            }
            else {
              this.makeToast("error", response.message);
            }
        });
        },

        makeToast(message_key, variant = "default", message, message1="") {
            document.getElementById('main-header-admin').scrollIntoView();
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.toast_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.toast_message = smessage.text_head;
                this.toast_message1 = smessage.text_sub;
            }
            else {
                this.toast_type = variant;
                this.toast_message = message;
                this.toast_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.toast_type = "";
                this.toast_message = "";
                this.toast_message1 = "";
            }, 5000);
        },
    },
    mounted() {
        this.$root.$on('toast_message', (message) => {
            if (Object.prototype.hasOwnProperty.call(message, "message1")) {
                this.makeToast(message.message, message.type, message.message, message.message1);
            }
            else {
                this.makeToast(message.message, message.type, message.message);
            }
        });
        // call activity record api
        sessionActivitySave({'activity': global_config.ACTIVITIES.UI_ADMIN}).then((response) => {
            console.log("ACTIVITY API CALLED : ", response);
        });
    }
};
</script>

<style scoped>
.main-header .main-header-link {
  cursor: pointer;
}
.header-bg {
  background-color: #dd550c !important;
}
.main-header .notification-block-outer {
    width: 100%;
    position: absolute;
    left: 0;
}
.main-header .notification-block-outer .notification-block {
    position: relative;
    width: max-content;
    margin: auto;
    z-index: 999999;
}
.main-header .notification-block-outer .notification-block.success {
    background: #00a152;
}
.main-header .notification-block-outer .notification-block.error {
    background: #ff1744b3;
}
.main-header .notification-block-outer .notification-block.warning {
    background: #ffea005e;
}
</style>