<template>
    <div class="main-layout-section admin-portal">
    
        <SuperAdminHeader />

        <div class="w-logo mb-5">
            <img src="../../assets/images/gamelab-logo.svg" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s"/>
        </div>

        <div class="main-content">
            <h2 class="page-heading">Dashboard</h2>
            <h3 class="page-subheading mb-5">Welcome Super Admin!</h3>

            <!-- start of buttons section -->
            <div class="dashboard-buttons-container col-md-3">
                <div class="dashboard-buttons">
                    
                    <router-link to="/superadmin/clients" class="dashboard-button">
                        <i class="animate__animated animate__fadeInUp animate__delay-1s fa-solid fa-user icon-button"></i>
                        Manage Clients
                    </router-link>
                    <router-link to="/superadmin/games" class="dashboard-button">
                        <i class="animate__animated animate__fadeInUp animate__delay-1s fa-brands fa-xbox icon-button"></i>
                        Manage Games
                    </router-link>
                    <router-link to="/common/users" class="dashboard-button">
                        Manage Users
                    </router-link>
                    <router-link to="/common/alerts" class="dashboard-button">
                        Manage Alerts
                    </router-link>
                    <router-link to="/superadmin/system_messages" class="dashboard-button flex-100">
                        Manage System Messages
                    </router-link>
                    <router-link to="/superadmin/db_backups" class="dashboard-button flex-100">
                        Manage DB Backups
                    </router-link>
                    <router-link to="/common/reports/status" class="dashboard-button flex-100">
                        Manage Reports
                    </router-link>
                    <a href="javascript:;" @click="handleLogout()" class="dashboard-button">
                        Logout
                    </a>
                </div>
            </div>
            <!-- end of buttons section -->

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <SuperAdminFooter type="inner" />
        </div>
        <!-- end of footer section -->

    </div>
</template>

<script>
import SuperAdminHeader from "@/views/superadmin/SuperAdminHeader.vue";
import SuperAdminFooter from "@/views/superadmin/SuperAdminFooter.vue";

export default {
  name: "SuperAdminDashboard-view",
  components: { SuperAdminHeader, SuperAdminFooter },
  data() {
    return {
      
    };
  },

  computed: {
  },

  methods: {
    handleLogout() {
        this.$children.forEach(function(item) {
            if(item.$options._componentTag == "SuperAdminHeader") {
                item.handleLogout();
            }
        });
    },
  },
  mounted() {
    document.body.classList.remove('overflow-hidden');
    this.$store.commit("fetchAdminThemeLogo");
  }
};
</script>