<template>
  <div>
    <div class="game-startup-timer" v-if="game_init">
      <span>00:{{ game_startup_duration < 10 ? "0"+game_startup_duration : game_startup_duration }}</span>
    </div>
    <!-- start of game startup modal -->
    <b-modal id="game-startup-modal" header-class="bg-labPrimary text-white px-3 py-2" hide-footer :hide-header-close="hide_header_close" :no-close-on-backdrop="no_close_bd" :no-close-on-esc="no_close_esc">
      <template #modal-title>
        Alert
        <b-icon 
          class="icon" 
          icon="question-circle-fill"
          >
        </b-icon> 
      </template>
      <b-overlay :show="false" rounded="lg">
        <div class="d-flex">
          <p>{{ game_startup_message }}</p>
        </div>
        <div class="d-flex text-center">
          <button
            type="button"
            class="btn btn-info text-white px-5"
            @click="$bvModal.hide('game-startup-modal')"
            v-if="game_startup_button !== ''"
          >
            {{ game_startup_button }}
          </button>
        </div>
      </b-overlay>
    </b-modal>

  </div>
</template>
<script>
import { getPodId, playFlaskGame, getXdTypes, getFlaskGameStarted } from "../../services/FlaskService";
import { getPodDetails } from "../../services/PlayerService";
import { global_config } from "@/config/config.js";
import store from "@/store";
export default {
    name: "FacPollingAndSockets-view",
    data() {
        return {
            pod_id_key : "",
            pod_locked: false,
            pod_data: {},
            xds_data: {
              "xd1" : "", 
              "xd2" : "", 
              "xb_disabled": true, 
              "ps_disabled": true, 
              "sw_disabled": true, 
              "pc_disabled": true, 
              "pod_disabled": true
            },
            game_init: false,
            game_startup_duration: global_config.GAME_STARTUP_DURATION,
            game_polling: null,
            game_startup_message: "",
            game_startup_button: "",
            hide_header_close: false,
            no_close_bd: false,
            no_close_esc: false,
        };
    },
    methods: {
      getPodIdFromFlask() {
        getPodId().then(pod => {
          if(pod.data.pod_id !== undefined && pod.data.pod_id.length > 0) {
            this.pod_id_key = pod.data.pod_id;
            this.$emit('setPodData', {'key' : 'pod_id_key', 'value' : this.pod_id_key});
            this.podDetails();
          }
        });
      },
      podDetails() {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
            session_id_key : pod_data.session_id_key,
            session_id : pod_data.session_id_key,
            token: user_data.token
        };
        getPodDetails(this.pod_id_key, params).then(response => {
          if(response.status) {
            this.pod_data = response.data.pod;
            this.pod_locked = response.data.pod.lock_out;
            this.$emit('setPodData', {'key' : 'pod_locked', 'value' : this.pod_locked});
            this.$emit('setPodData', {'key' : 'pod_data', 'value' : this.pod_data});
            this.$emit('setPodVolume', response.data.pod.volume);
          }
        });
      },

      gameStartInitiated() {
        this.game_init = true;
        this.game_startup_duration = global_config.GAME_STARTUP_DURATION;
        clearTimeout(this.game_polling);
        this.game_polling = setInterval(() => {
          this.game_startup_duration--;
          if(this.game_startup_duration <= 0) {
            clearInterval(this.game_polling);
            this.game_init = false;
            // get xd types for buttons
            let self = this;
            getFlaskGameStarted().then(gs => {
              if(gs.success && gs.data !== undefined && typeof gs.data.game_started !== undefined) {
                if(!gs.data.game_started) {
                  self.game_startup_message = "Still Here? Something must have gone wrong--please start a chat with us!";
                  self.game_startup_button = "Yes";
                  self.$bvModal.show('game-startup-modal');
                }
              }
            });
          }
        }, 1000);
      },

      playGame(gid, search, platform) {
        if(typeof this.pod_data.id !== undefined) {
          this
        //   let user_data = JSON.parse(localStorage.user_sess_data);
        //   let pod_data = JSON.parse(localStorage.pod_sess_data);
          let params = {
            // pod_id : this.pod_id_key,
            // nav_id: this.pod_data.nav_id,
            // session_id : pod_data.session_id_key,
            // user_id: user_data.user_id,
            // game_id: gid,
            // xb1: this.xds_data[this.xds_data.xd1+"_disabled"],
            // xb2: this.xds_data[this.xds_data.xd2+"_disabled"],
            platform: platform,
            select_type: null,
            search_title: search
          };
          playFlaskGame(params).then(response => {
            this.makeToast("success", "facility-games-initiated-message");
            console.log("PLAY GAME API IS CALLED : ", response);
            this.gameStartInitiated();
          })
        }
        else {
          this.makeToast("error", "facility-games-initiated-error");
        }
      },

      getXdsData() {
        // get xd types for buttons
        getXdTypes().then(xd => {
          if(xd.data !== undefined) {
            this.xds_data = xd.data;
            this.$emit('setPodData', {'key' : 'xds_data', 'value' : xd.data});
          }
        });
      },

      makeToast(variant = "default", message) {
        // this.$toast.open({
        //     message: message,
        //     type: variant,
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true
        // });
        this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
      },

    },
    computed: {
      getMessage() {
        return store.getters.getMessage;
      },
    },
    mounted() {
      // listen for modal close
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if(modalId == "game-startup-modal") {
          this.game_startup_message = "";
          this.game_startup_button = "";
          this.hide_header_close = false;
          this.no_close_bd = false;
          this.no_close_esc = false;
        }
      })
    },
    beforeDestroy () {
    },
    created () {
      this.getPodIdFromFlask();
      this.getXdsData();
    }
};
</script>
<style scoped>
  .game-startup-timer {
    /* position: absolute; */
    color: #fff;
    top: 1.5%;
    z-index: 1000;
    left: auto;
    font-size: 25px;
    font-weight: bold;
    margin: auto;
    /* padding-left: 45%; */
    margin: 0;
    padding: 0;
  }
</style>