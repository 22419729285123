import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import store from "@/store";
import VueToast from 'vue-toast-notification';
import VueQRCodeComponent from 'vue-qrcode-component';
import Vidle from 'v-idle';
import { global_config } from "@/config/config.js";
import VueMask from 'v-mask';
import MainKeyboard from "@/components/MainKeyboard";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

import Echo from 'laravel-echo';
window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: global_config.PUSHER_BROADCASTER,
    key: global_config.PUSHER_KEY,
    wsHost: global_config.PUSHER_HOST,
    wsPort: global_config.PUSHER_PORT,
    // wssPort: global_config.PUSHER_PORT,
    forceTLS: global_config.PUSHER_FORCE_TLS,
    disableStates: true, //global_config.PUSHER_DISABLED_STATES,
    encrypted: true,
    enabledTransports: ['ws', 'wss'],
});

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueToast);
Vue.component('qr-code', VueQRCodeComponent);
Vue.component('MainKeyboard', MainKeyboard);
Vue.use(Vidle);
Vue.use(VueMask);
Vue.prototype.$store = store;

new Vue({
  router,
  store,
  render: (h) => h(App),
  data: {
    pod_id: "",
    pod_locked: false,
  },
}).$mount("#app");
