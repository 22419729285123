<template>
    <div class="main-layout-section">
        <FacHeader />
        <FacPollingAndSockets 
        @setPodData="setPodData($event)" />
  
        <div class="main-content" v-if="!pod_locked">
        <!-- start of games container section -->
        <div class="col-md-11 mx-auto">
            <div class="facility-pods-section">
                <div class="facility-pods-section-buttons px-2">
                    <router-link to="/facilitator/dashboard" class="facility-pods-section-button">
                        BACK TO DASHBOARD
                    </router-link>
                </div>
            </div>
            <div class="games-section-container">
                <!-- start of images section -->
                <div class="games-section-images">
                    <div class="games-section-image-item" v-if="game_selected !== null">
                        <img :src="game_selected.ImageURL" />
                    </div>
                </div>
                <!-- end of images section -->
                <!-- start of filters/list section -->
                <div class="games-section-list">
                    <b-input-group class="d-flex text-center align-items-center games-section-list-search">
                        <b-input-group-prepend class="search-icon">
                            <i class="animate__animated animate__fadeInUp animate__delay-1s fa-solid fa-search icon-button"></i>
                        </b-input-group-prepend>
                        <b-form-input
                        type="text"
                        placeholder="search for a title"
                        class="search-box h-53"
                        autocomplete="off"
                        v-model="searchQuery"
                        ></b-form-input>
                        <b-input-group-append class="xbox-icon">
                            <i class="animate__animated animate__fadeInUp animate__delay-1s fa-brands fa-xbox icon-button"></i>
                        </b-input-group-append>
                    </b-input-group>

                    <div class="games-section-list-filters mt-3">
                        <div class="games-section-list-filter-item">
                            <div class="games-section-list-filter-item-header">
                                Sort By
                            </div>
                            <div class="games-section-list-filter-item-body">
                                <span>Newest</span>
                                <span>Popular</span>
                                <span>ABC</span>
                            </div>
                        </div>
                        <div class="games-section-list-filter-item">
                            <div class="games-section-list-filter-item-header">
                                Filters
                            </div>
                            <div class="games-section-list-filter-item-body">
                                <span>Rating</span>
                                <span>Genre</span>
                                <span>Released</span>
                                <span>Storage</span>
                            </div>
                        </div>
                    </div>

                    <div class="games-section-list-data mt-3">
                        <div class="games-section-list-data-title" v-if="game_selected !== null">
                            <h3>{{ game_selected.GameName }}</h3>
                            <div class="games-section-list-data-title-attribs">
                                <div class="games-section-list-data-title-attribs-item">
                                    <span class="heading">Genre</span>
                                    <span class="body">{{ game_selected.GameGenre }}</span>
                                </div>
                                <div class="games-section-list-data-title-attribs-item">
                                    <span class="heading">Released</span>
                                    <span class="body">{{ game_selected.ReleasedDate }}</span>
                                </div>
                                <div class="games-section-list-data-title-attribs-item">
                                    <span class="heading">Storage</span>
                                    <span class="body">{{ game_selected.GameType }}</span>
                                </div>
                                <div class="games-section-list-data-title-attribs-item">
                                    <span class="heading">Platform</span>
                                    <span class="body">{{ game_selected.GamePlatform }}</span>
                                </div>
                            </div>
                        </div>

                        <div class="games-section-list-data-list" id="games-list">
                            <div class="games-section-list-data-list-inner" id="games-listtt">
                                <div class="games-section-list-data-list-item" v-for="game in filteredGames" :key="game.GameId">
                                    <img 
                                    :src="`${game.ImageURL}`" 
                                    alt="" 
                                    @click="selectGame(game)" 
                                    :class="[ game_selected !== null && game_selected.GameId == game.GameId ? 'selected-game' : '' ]">
                                </div>
                                <p class="text-left no-results" v-if="filteredGames.length <= 0" :key="filteredGames.length">No games found at the moment!</p>
                            </div>
                        </div>

                        <div class="games-section-list-data-play" v-if="game_selected !== null">
                            <div class="games-section-list-data-play-buttons">
                                <button 
                                class="btn"
                                @click="playGame(game_selected.GameKey, game_selected.GameName, xds_data.xd1)"
                                :disabled="typeof xds_data.xd1 == undefined || xds_data.xd1 == '' || xds_data[xds_data.xd1+'_disabled']"
                                >
                                Play Selected {{ xds_data.xd1.toUpperCase() }}
                                </button>
                                <button 
                                class="btn"
                                @click="playGame(game_selected.GameKey, game_selected.GameName, xds_data.xd2)"
                                :disabled="typeof xds_data.xd2 == undefined || xds_data.xd2 == '' || xds_data[xds_data.xd2+'_disabled']"
                                >
                                Play Selected {{ xds_data.xd2.toUpperCase() }}
                                </button>
                            </div>
                            <div class="games-section-list-data-play-chat">
                                <div class="w-chat-box" @click="$root.$emit('live-chat-widget-visibility', 'show-hide');">
                                    <!-- <span><i class="fa-brands fa-rocketchat"></i></span> -->
                                    <span><img src="../../assets/images/chat-icon.png" alt="chat with us"></span>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <!-- end of filters/list section -->
                <!-- start of images section -->
                <div class="games-section-info">
                    <h2>About This Game</h2>
                    <p>Ship of Fools is a seafaring roguelite co-op game where you play the Fools,
                    the only creatures fool enough to brave the sea. The Great Lighthouse that
                    once protected the Archipelago is broken and a storm of malice and cor-
                    ruption is coming.</p>
                    <p>Together, you and your ship mates will jump aboard The Stormstrider and
                    make your voyage across the sea. Man the cannons, ready your sails and
                    protect your ship from sea monsters over multiple runs. It’s up to you to
                    defend your home from the almighty Aquapocalypse.</p>
                    <p>In a nutshell, Ship of Fools is a game about blasting away your seaborne
                    foes with mighty cannons as you defend your ship. Inspired by modern
                    classic roguelites and built for co-op, you’ll want to bring your ﬁrst mate on
                    deck and enjoy oarsome combat as you unlock new trinkets and artifacts
                    to help you save the world from catastrophe.</p>
                    <p>Uncover lost treasures and remote shops on the islands of the Archipela-
                    go, but plan your moves carefully! As you chart your way through the
                    sector, the Everlasting Storm shifts and changes, blocking your paths with
                    its fury. You’ll need to adjust your course in reaction to storm movements
                    and decide when you can brave a trip to discover what lurks inside the
                    storm.</p>
                    <p>Man your ship as a solo sailor and ﬁght your way through dangerous
                    waters and keep the glory for yourself, Ship of Fools oﬀers solo play as well
                    as multiplayer. As they sing in old sailor songs: it’s sometimes better to
                    have no crew than a dysfunctional one!</p>
                    <p>With many Fools to play with, unique items to ﬁnd and unlock, combat en-
                    counters to ﬁght in and many islands to visit, each run will bring something
                    new to your voyage. Bolster your strength each time, ready to defeat the
                    monsters of the deep sea.</p>
                </div>
                <!-- end of images section -->
            </div>
        </div>
        
        <!-- end of games container section -->

        </div>
  
        <div class="main-content" v-if="pod_locked">
        <div class="d-flex justify-content-center">
            <h2 class="text-white mt-25">You have been locked out by Admin. Please wait...</h2>
        </div>
        </div>

        <!-- start of footer section -->
        <div class="col-sm-12 mx-auto player-games-footer">
            <FacFooter type="inner" />
        </div>
        <!-- end of footer section -->
  
    </div>
</template>
  
<script>
import FacHeader from "@/views/facilitator/FacHeader.vue";
import FacPollingAndSockets from "@/views/facilitator/FacPollingAndSockets.vue";
import { getGamesList } from "../../services/PlayerService";
import FacFooter from "@/views/facilitator/FacFooter.vue";

export default {
name: "FacGames-view",
components: { FacHeader, FacPollingAndSockets, FacFooter },
data() {
    return {
    games: [],
    original_games: [],
    searchQuery:'',
    pod_id_key : "",
    pod_locked: false,
    pod_data: {},
    api_state: false,
    page: 1,
    last_page: 1,
    game_selected: null,
    window_width: window.innerWidth,
    xds_data: {
        "xd1" : "", 
        "xd2" : "", 
        "xb_disabled": true, 
        "ps_disabled": true, 
        "sw_disabled": true, 
        "pc_disabled": true, 
        "pod_disabled": true
    },
    };
},

computed: {
    filteredGames () {
    if(this.searchQuery) {
        return this.games.filter(item => {
        return item.GameName.toLowerCase().includes(this.searchQuery.toLowerCase())
        });
    }
    else {
        return this.games;
    }
    }
},

methods: {
    gamesList() {
    let user_data = JSON.parse(localStorage.user_sess_data);
    let pod_data = JSON.parse(localStorage.pod_sess_data);
    let params = {
        session_id_key : pod_data.session_id_key,
        token: user_data.token,
        user_id: user_data.user_id,
        type: 'player',
        page: this.page,
        screen: this.window_width < 768 ? 'sm' : 'lg',
    };
    this.api_state = true;
    getGamesList(params).then(response => {
        this.api_state = false;
        this.games = response.data.games;
        this.page = response.data.current_page + 1;
        this.last_page = response.data.last_page;
        this.original_games = JSON.parse(JSON.stringify(this.games));
        this.game_selected = this.games[0];
    })
    },
    setPodData(obj) {
    this[obj.key] = obj.value;
    },
    selectGame(game) {
        if(this.game_selected == null) {
            this.game_selected = game;
        }
        else {
            if(this.game_selected.GameId == game.GameId) {
                this.game_selected = null;
            }
            else {
                this.game_selected = game;
            }
        }
    },
    playGame(gid, search, platform) {
        if(typeof this.xds_data[platform] !== undefined && !this.xds_data[platform+"_disabled"]) {
            document.getElementById('main-header-player').scrollIntoView()
            this.$children.forEach(function(item) {
            if(item.$options._componentTag == "FacPollingAndSockets") {
                item.playGame(gid, search, platform);
            }
            });
        }
    },
},

mounted() {
    // this.gamesList();
    document.getElementById('games-list').onscroll = () => {
    //   let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
    let bottomOfWindow = document.getElementById('games-list').scrollTop + document.getElementById('games-list').offsetHeight === document.getElementById('games-list').scrollHeight;
    if (bottomOfWindow && !this.api_state && this.page <= this.last_page) {
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
        session_id_key : pod_data.session_id_key,
        token: user_data.token,
        user_id: user_data.user_id,
        type: 'player',
        page: this.page,
        screen: this.window_width < 768 ? 'sm' : 'lg',
        };
        this.api_state = true;
        getGamesList(params).then(response => {
        this.api_state = false;
        this.page = response.data.current_page + 1;
        this.last_page = response.data.last_page;
        this.games = this.games.concat(response.data.games);
        })
    }
    };
},
created() {
    this.gamesList();
}
};
</script>