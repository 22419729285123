<template>
    <div class="main-contenttt" v-if="pod_screen_buttons">
        <div class="overlay"></div>
        <div class="idle-touch-container">
            
            <div class="notification-block-outer w-alert-340">
                <div class="m-auto notification-block animate__animated animate__fadeInUp w-alert-340" :class="toast_type" v-if="toast_type !== '' && toast_message !== ''">
                <div class="notification-message w-alert-290">
                    <h5>{{ toast_message }}</h5>
                    <p v-if="toast_message1 !== ''">{{ toast_message1 }}</p>
                </div>
                <div class="icon-success" v-if="toast_type == 'success'">
                    <i class="fa-solid fa-circle-check"></i>
                </div>
                <div class="icon-error" v-if="toast_type == 'error'">
                    <i class="fa-solid fa-times-circle"></i>
                </div>
                <div class="icon-warning" v-if="toast_type == 'warning'">
                    <i class="fa-solid fa-exclamation-circle"></i>
                </div>
                </div>
            </div>

            <div class="w-logo">
                <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
            </div>

            <!-- start of buttons container -->
            <div class="general-buttons-container mt-auto">
                <div class="general-buttons animate__animated animate__fadeInUp animate__delay-2s">
                    <button class="general-button" @click="triggerPushMenu" :class="push_pressed">
                        Return to Menu
                    </button>
                    <button class="general-button" @click="triggerPushEnd" :class="logout_pressed">
                        Logout Now
                    </button>
                </div>
            </div>
            <!-- end of buttons container -->

            <!-- start of volume section -->
            <div class="general-volume-container mt-5 mb-auto animate__animated animate__fadeInUp animate__delay-2s">
                <div class="general-volume">
                    <b-input-group class="d-flex text-center align-items-center">
                        <b-input-group-prepend class="mute-icons">
                            <b-icon 
                            class="icon h4" 
                            icon="volume-down-fill"
                            @click="volumeMuteUnmute('mute')"
                            >
                            </b-icon>
                        </b-input-group-prepend>
                        <b-form-input 
                        type="range" 
                        class="volume-input" 
                        min="0" 
                        max="50" 
                        step="1" 
                        tabindex="-1" 
                        v-model="pod_volume"
                        @change="volumeChangeButtons"
                        v-b-tooltip="{ title: (pod_volume * 2 + '%'), placement: 'top', trigger: 'hover focus', customClass: 'volume-tooltip' }"
                        >
                        </b-form-input>
                        <b-input-group-append class="mute-icons">
                            <b-icon 
                            class="icon h4" 
                            icon="volume-up-fill"
                            @click="volumeMuteUnmute('unmute')"
                            >
                            </b-icon>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </div>
            <!-- end of voulme section -->

        </div>
    </div>

</template>

<script>
import { createFlaskPodPushMenu, createFlaskPodPushEnd, setFlaskPodVolume, getPodVolume } from "../services/FlaskService";
import { setPodVolume, getPodDetails } from "../services/PlayerService";

export default {
    name: "ButtonsWidget-view",
    data() {
        return {
            pre_title: "",
            pod_volume: 0,
            api_state: false,
            volume_polling: null,
            toast_type: '',
            toast_message: '',
            toast_message1: '',
            pod_data: {},
            push_pressed: '',
            logout_pressed: '',
        };
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        },
        pod_screen_buttons() {
            return this.$store.getters.getPodScreenButtonsState();
        },
    },

    watch: {
        pod_screen_buttons(new_val) {
            if(new_val == true) {
                this.podDetails();
            }
            else {
                clearInterval(this.volume_polling);
            }
        }
    },

    methods: {
        triggerPushMenu() {
            this.push_pressed = 'push_pressed';
            createFlaskPodPushMenu().then(pm => {
                if (Object.prototype.hasOwnProperty.call(pm, "success")) {
                    console.log("PM => ", pm);
                }
            });
            setTimeout(() => {
                this.push_pressed = '';
            }, 5000);
        },
        triggerPushEnd() {
            this.logout_pressed = 'logout_pressed';
            createFlaskPodPushEnd().then(pe => {
                if (Object.prototype.hasOwnProperty.call(pe, "success")) {
                    pe;
                }
            });
            setTimeout(() => {
                this.logout_pressed = '';
            }, 5000);
        },
        pollFlaskVolume () {
            this.volume_polling = setInterval(() => {
                // get logout status
                getPodVolume().then(vol => {
                    if(vol.data.vol_now !== undefined && vol.data.vol_now !== "") {
                        this.volumeChangeButtons(vol.data.vol_now, "");
                    }
                });
            }, process.env.VUE_APP_POLLING_TIME_POD_VOLUME_CHECK);
        },
        volumeMuteUnmute(type) {
            if(type == 'mute') {
                this.pod_volume = 0;
                this.volumeChangeButtons(this.pod_volume);
            }
            else if(type == 'unmute') {
                this.pod_volume = 50;
                this.volumeChangeButtons(this.pod_volume);
            }
        },
        volumeChangeButtons(volume, source="web") {
            if(!this.pod_screen_buttons) {
                return;
            }
            // clear interval first
            clearInterval(this.volume_polling);
            this.api_state = true;
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                pod_id_key: pod_data.pod_id_key,
                volume: volume,
            };
            let flag = true;
            if(source == "" && volume == this.pod_volume) {
                flag = false;
            }
        
            if(flag) {
                setPodVolume(params).then(response => {
                    this.api_state = false;
                    if(response.status) {
                        this.pod_volume = volume;
                        // init volume change on pod as well
                        if(source !== "") {
                            this.makeToast(response.message, "success", response.message);
                            let params = {
                                volume : this.pod_volume
                            };
                            setFlaskPodVolume(params).then(vol_web => {
                                setTimeout(() => {
                                    vol_web;
                                    this.pollFlaskVolume();  
                                }, 3000);
                            });
                        }
                        else {
                            this.pollFlaskVolume();
                        }
                    }
                    else {
                        this.pollFlaskVolume();
                    }
                })
            }
            else {
                this.api_state = false;
                this.pollFlaskVolume();
            }
        },
        makeToast(message_key = '', variant = "default", message, message1="") {
            // search for key
            let smessage = this.$store.state.system_messages.list.find(o => o.msg_key === message_key);
            if(smessage !== null && typeof smessage !== 'undefined' && Object.prototype.hasOwnProperty.call(smessage, 'text_head')) {
                this.toast_type = smessage.type == 'failure' ? 'error' : smessage.type;
                this.toast_message = smessage.text_head;
                this.toast_message1 = smessage.text_sub;
            }
            else {
                this.toast_type = variant;
                this.toast_message = message;
                this.toast_message1 = message1;
            }
            // hide error div
            setTimeout(() => {
                this.toast_type = "";
                this.toast_message = "";
                this.toast_message1 = "";
            }, 5000);
        },
        podDetails() {
            clearInterval(this.volume_polling);
            let sess_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : sess_data.session_id_key,
                session_id : sess_data.session_id_key
            };
            getPodDetails(sess_data.pod_id_key, params).then(response => {
                if(response.status) {
                    this.pod_data = response.data.pod;
                    this.pod_volume = response.data.pod.volume;
                }
                this.pollFlaskVolume();
            });
        },
    },
    mounted() {
        clearInterval(this.volume_polling);
    },
    beforeDestroy() {
        clearInterval(this.volume_polling);
    }
};
</script>
<style scoped>
.main-contenttt {
    width: 100%;
    height: 100vh;
    z-index: 10000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: transparent;
    overscroll-behavior-x: none;
}
.main-contenttt .overlay {
    position: absolute;
    background: black;
    width: 100%;
    left: 0;
    height: 100%;
    background: linear-gradient(30deg, var(--client-gradiant), var(--client-accent));
    background-image: linear-gradient(30deg, var(--client-gradiant), var(--client-accent));
    overscroll-behavior-x: none;
}
.idle-touch-container {
    width: 475px;
    height: 100vh;
    z-index: 1000;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: transparent;
    overscroll-behavior-x: none;
}
.notification-block-outer {
    width: 100%;
    position: absolute;
    top: 20px;
    z-index: 10;
}
.notification-block-outer .notification-block.success {
    background: #00a152;
}
.notification-block-outer .notification-block.error {
    background: #ff1744b3;
}
.notification-block-outer .notification-block.warning {
    background: #ffea005e;
}
.general-volume-container .volume-input {
    outline: none !important;
    box-shadow: none !important;
}
.general-buttons-container .general-button {
    padding: 30px 10px;
}
.idle-touch-container .push_pressed, .idle-touch-container .logout_pressed {
    pointer-events: none;
    cursor: not-allowed;
    background: #3f3f3f;
    border-color: #3f3f3f;
    color: rgb(216 216 216 / 60%);
}
/* responsiveness section */
@media screen and (max-width: 475px) {
    .idle-touch-container {
        width: 400px;
    }
}
</style>