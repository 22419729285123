<template>
  <LiveChatWidget :license="license" :customerName="customerName" :customerEmail="customerEmail" v-on:ready="handleReadyEvent" group="0" :visibility="visibility" :chatBetweenGroups="false" v-if="is_widget" />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { LiveChatWidget, useWidgetCustomerData } from "@livechat/widget-vue";
import { global_config } from "../config/config.js";

export default {
  name: "LiveChatWidgetCustom",
  
  components: { LiveChatWidget},

  data() {
    return {
      customerName: "loading...",
      customerEmail: "loading...",
      visibility: "hidden",
      license: global_config.LIVECHAT_LICENSE,
      podValidated: false,
      is_widget: true,
      is_widget_event: false
    };
  },
  
  methods: {
	// eslint-disable-next-line
	handleReadyEvent(event) {
     event;
     this.handleLivechatWidget();
	},
	handleLivechatWidget() {
		var customer_name = ' ';
		var customer_email = ' ';
		if(typeof localStorage.user_sess_data !== 'undefined') {
			let user_data = JSON.parse(localStorage.user_sess_data);
			
			if (user_data.name != undefined && user_data.name != '') {
				customer_name = user_data.name;
				customer_email = user_data.email;
			}
		}
		
		var isPodValidated = false;
		if(typeof localStorage.pod_sess_data !== 'undefined') {
			let pod_data = JSON.parse(localStorage.pod_sess_data);
			
			if(pod_data.pod_id_key != undefined && pod_data.pod_id_key != ''){
				isPodValidated = true;
			}
			var lab_nav_arr = [];
			if (pod_data.lab_name != undefined && pod_data.lab_name != '') {
				lab_nav_arr.push(pod_data.lab_name);
			}
			if (pod_data.nav_name != undefined && pod_data.nav_name != '') {
				lab_nav_arr.push(pod_data.nav_name);
			}
			
			var lab_nav_name = lab_nav_arr.join(':');
			if(customer_name.trim() != ''){
				customer_name = customer_name+' - '+lab_nav_name;
			} else {
				customer_name = lab_nav_name;
			}
		}

		var isLoggedIn = false;
		if(typeof localStorage.user_sess_data !== 'undefined') {
			let user_data = JSON.parse(localStorage.user_sess_data);
			if (user_data.token && user_data.role_id) {
				isLoggedIn = true;
			}
			else {
				isLoggedIn = false;
			}
		}
		else {
			isLoggedIn = false;
		}
        isLoggedIn;

		
		
		
		var visibility = this.is_widget_event ? 'maximized' : 'hidden';
        this.is_widget_event = false;
		if(isPodValidated){
			// visibility = 'minimized';
            this.podValidated = true;
            // let elems = document.getElementsByClassName('w-chat-box');
            // for(let i = 0; i < elems.length; i++) {
            //   elems[i].classList.remove('d-none');
            // }
		}
        else {
            // let elems = document.getElementsByClassName('w-chat-box');
            // for(let i = 0; i < elems.length; i++) {
            //   elems[i].classList.remove('d-none');
            //   elems[i].classList.add('d-none');
            // }
        }
		
		try{
			this.customerName = customer_name;
			this.customerEmail = customer_email;
			this.visibility = visibility;
		} catch(Error){
			console.log('catch Error', Error);
		}
    },
    handleVisibilityEvent() {
        if(this.podValidated || 1==1) {
            let width = document.getElementById('chat-widget-container').offsetWidth;
            let style = document.getElementById('chat-widget-container').style.visibility;
            if(style == 'visible' && width < 100) {
                let visib = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                if(visib == 'maximized') {
                    this.is_widget_event = true;
                    this.is_widget = false;
                    setTimeout(() => {
                        this.is_widget = true;
                        this.visibility = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                    }, 500);
                }
                else {
                    this.visibility = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                }
            }
            else if(style == 'visible' && width > 100) {
                let visib = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                if(visib == 'maximized') {
                    this.is_widget_event = true;
                    this.is_widget = false;
                    setTimeout(() => {
                        this.is_widget = true;
                        this.visibility = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                    }, 500);
                }
                else {
                    this.visibility = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                }
            }
            else {
                let visib = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                if(visib == 'maximized') {
                    this.is_widget_event = true;
                    this.is_widget = false;
                    setTimeout(() => {
                        this.is_widget = true;
                        this.visibility = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                    }, 500);
                }
                else {
                    this.visibility = this.visibility == 'maximized' ? 'hidden' : 'maximized';
                }
            }
        }
    },
   },
   
   mounted: function () { 
    this.$root.$on('live-chat-widget', (message) => {
       console.log(message);
       this.handleLivechatWidget();
    });
    this.$root.$on('live-chat-widget-visibility', (message) => {
        message;
        this.handleVisibilityEvent();
    });
    this.$root.$on('live-chat-widget-hide', (message) => {
        message;
        this.visibility = 'hidden';
        this.is_widget_event = false;
        this.is_widget = false;
    });
  }
}
</script>

<style>
</style>