<template>
  <div class="main-layout-section">
    
    <ReservationWidget v-if="is_reserved" @reservationAcknowledged="reservationAcknowledged($event)" :reservation="reservation" />

    <FacHeader @setPodData="setPodData($event)" />

    <div class="w-logo mb-5">
        <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeInUp animate__delay-1s" v-if="logo_file !== '' && logo_file !== null"/>
    </div>

    <OrientationWidget @orientationComplete="orientationComplete($event)" :otype="orientation_type" v-if="is_orientation" />
    <!-- notifications section -->
    <AlertsWidget @alertsComplete="alertsComplete($event)" :alerts="alerts" v-if="is_alerts && !is_orientation" />

    <div class="main-content" v-if="!is_alerts && !is_orientation">
      <h2 class="page-heading">Dashboard</h2>
      <h3 class="page-subheading mb-5">Welcome {{ (user_data !== null ? user_data.user_name : 'Guest') }}!</h3>

      <!-- start of buttons section -->
      <div class="dashboard-buttons-container col-md-3">
        <div class="dashboard-buttons">
            <router-link to="/facilitator/pods" class="dashboard-button">
            <i class="animate__animated animate__fadeInUp animate__delay-1s fas fa-calendar-check icon-button"></i>
            Manage Event
            </router-link>
            <router-link to="/facilitator/games" class="dashboard-button">
            <i class="animate__animated animate__fadeInUp animate__delay-1s fa-brands fa-xbox icon-button"></i>
            PLAY XBOX/PS5
            </router-link>
            <router-link to="/common/alerts" class="dashboard-button flex-100">
                Manage Alerts
            </router-link>
            <a href="javascript:;" @click="handleLogout('', 'web')" class="dashboard-button">
                Logout
            </a>
        </div>
      </div>
      <!-- end of buttons section -->

    </div>

    <!-- start of footer section -->
    <div class="col-md-12 mx-auto">
        <FacFooter type="inner" />
    </div>
    <!-- end of footer section -->

  </div>
</template>

<script>
import FacHeader from "@/views/facilitator/FacHeader.vue";
import FacFooter from "@/views/facilitator/FacFooter.vue";
import AlertsWidget from "@/components/AlertsWidget.vue";
import OrientationWidget from "@/components/OrientationWidget.vue";
import ReservationWidget from "@/components/ReservationWidget.vue";

export default {
    name: "FacDashboard-view",
    components: { FacHeader, FacFooter, AlertsWidget, OrientationWidget, ReservationWidget },
    data() {
    return {
        user_data: null,
        is_alerts: false,
        alerts: [],
        is_orientation: false,
        orientation_type: '',
        is_reserved: false,
        reservation: {}
    };
    },
    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },
    methods: {
        setPodData(obj) {
            this[obj.key] = obj.value;
        },
        setUserData() {
            if(typeof localStorage.user_sess_data !== 'undefined') {
                let user_data = JSON.parse(localStorage.user_sess_data);
                this.user_data = user_data
            }
        },
        handleLogout() {
            this.$children.forEach(function(item) {
                if(item.$options._componentTag == "FacHeader") {
                    item.handleLogout();
                }
            });
        },
        alertsComplete(obj) {
            obj;
            setTimeout(() => {
                this.is_alerts = false;
                this.alerts = [];
            }, 2000);
        },
        orientationComplete(obj) {
            obj;
            this.is_orientation = false;
        },
        // check is_paid status of the pod in the lab. 
        checkIsPaidLab(){
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            if(pod_data.is_paid != undefined && pod_data.is_paid == 1){
                this.is_paid_lab = pod_data.is_paid;
            }
        },
        reservationAcknowledged(obj) {
            obj;
            this.is_reserved = false;
            this.reservation = {};
        }
    },
    mounted() {
        document.body.classList.remove('overflow-hidden');
        this.setUserData();
    },
    created () {
        this.checkIsPaidLab();
        let params = this.$route.params;
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation") && params.is_orientation && !this.is_paid_lab) {
            this.orientation_type = 'free';
            this.is_orientation = params.is_orientation;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_orientation_paid") && params.is_orientation_paid && this.is_paid_lab) {
            this.orientation_type = 'paid';
            this.is_orientation = params.is_orientation_paid;
        }
        if(Object.prototype.hasOwnProperty.call(params, "is_alerts") && params.is_alerts) {
            this.is_alerts = params.is_alerts;
            this.alerts = params.alerts;
        }
        if(Object.prototype.hasOwnProperty.call(params, "reservation") && params.reservation.is_started && !params.reservation.is_user_allowed) {
            this.is_reserved = true;
            this.reservation = params.reservation;
        }
    }
};
</script>