import Vue from "vue";
import { checkFlaskPodPushMenu, postPodLogoutWeb, checkFlaskPodAsleep, checkFlaskPodScreenButtons } from "./FlaskService";
import router from "@/router/index";
import { sessionActivitySave, recordFlaskActivity, sessionActivityUpdate } from "../services/HomepageService";
import { global_config } from "@/config/config.js";
const axios = require('axios');
import { cloud_api_routes } from '../routes/cloud_api_routes.js';
let cloudAPI = process.env.VUE_APP_CLOUD_API;
import store from "@/store";
import xb_icon from "../assets/images/icon_xbox.svg";
import sw_icon from "../assets/images/icon_switch.svg";
import pc_icon from "../assets/images/icon_pc.svg";
import ps_icon from "../assets/images/icon_ps5.svg";

export function checkIfValidPlayer(to, from, next) {
    if(typeof localStorage.user_sess_data !== 'undefined') {
        let user_data = JSON.parse(localStorage.user_sess_data);
        if (user_data.token && user_data.role_id && user_data.role_id == 1) {
            if(to.path == '/player') {
                next('/player/dashboard');
            }
            else {
                next(true);
            }
        }
        else {
            localStorage.removeItem('user_sess_data');
            localStorage.removeItem('qr_sess_data');
            localStorage.removeItem('pod_sess_data');
            next({path : '/'});
        }
    }
    else {
        localStorage.removeItem('user_sess_data');
        localStorage.removeItem('qr_sess_data');
        localStorage.removeItem('pod_sess_data');
        next({path : '/'});
    }
}

export function checkIfValidFacilitator(to, from, next) {
    if(typeof localStorage.user_sess_data !== 'undefined') {
        let user_data = JSON.parse(localStorage.user_sess_data);
        if (user_data.token && user_data.role_id && user_data.role_id == 2) {
            if(to.path == '/facilitator') {
                next('/facilitator/dashboard');
            }
            else {
                next(true);
            }
        }
        else {
            localStorage.removeItem('user_sess_data');
            localStorage.removeItem('qr_sess_data');
            localStorage.removeItem('pod_sess_data');
            next({path : '/'});
        }
    }
    else {
        localStorage.removeItem('user_sess_data');
        localStorage.removeItem('qr_sess_data');
        localStorage.removeItem('pod_sess_data');
        next({path : '/'});
    }
}

export function checkIfValidAdmin(to, from, next) {
    if(typeof localStorage.user_sess_data !== 'undefined') {
        let user_data = JSON.parse(localStorage.user_sess_data);
        if (user_data.token && user_data.role_id && (user_data.role_id == 3 || user_data.role_id == 5)) {
            if(to.path == '/admin') {
                next('/admin/dashboard');
            }
            else if(user_data.role_id == 5 && to.path !== '/admin/dashboard') {
                next('/admin/dashboard');
            }
            else {
                next(true);
            }
        }
        else {
            localStorage.removeItem('user_sess_data');
            next({path : '/admin/login'});
        }
    }
    else {
        localStorage.removeItem('user_sess_data');
        next({path : '/admin/login'});
    }
}

export function checkIfValidSuperAdmin(to, from, next) {
    if(typeof localStorage.user_sess_data !== 'undefined') {
        let user_data = JSON.parse(localStorage.user_sess_data);
        if (user_data.token && user_data.role_id && user_data.role_id == 4) {
            if(to.path == '/superadmin') {
                next('/superadmin/dashboard');
            }
            else {
                next(true);
            }
        }
        else {
            localStorage.removeItem('user_sess_data');
            next({path : '/admin/login'});
        }
    }
    else {
        localStorage.removeItem('user_sess_data');
        next({path : '/admin/login'});
    }
}

export function checkIfNotTokenIssue(response) {
    if(
        response.message == "Token is Expired" || 
        response.message == "Token is Invalid" || 
        response.message == "Session Id invalid!" || 
        response.message == "Session ID error--reloading app." ||
        response.message == 'Session ID expired! Please reload the app.' ||
        response.message == 'Session Id empty! Please reload the app.'
    ){
        // localStorage.removeItem('qr_sess_data');
        // localStorage.removeItem('user_sess_data');
        // localStorage.removeItem('pod_sess_data');
        // Vue.$toast.open({
        //     message: response.message,
        //     type: "error",
        //     position: 'top-right',
        //     duration: 3000,
        //     dismissible: true,
        //     queue: true
        // });
        // setTimeout(() => {
        //     window.location.reload();
        // }, 1000);
        let user_data = JSON.parse(localStorage.user_sess_data);
        let pod_data = JSON.parse(localStorage.pod_sess_data);
        let params = {
            session_id : pod_data.session_id_key,
            user_id : user_data.user_id,
            pod_id_key: pod_data.pod_id_key,
            logout_reason: 'web'
        };
        postPodLogoutWeb(params).then(log_web => {
            console.log("WEB LOGOUT API CALLED", log_web);
        });
        router.push({ name: "Player Logout", params: {'reason': 'Your session expired!'}});
    }
    return response;
}

export function handleError(error) {
    if(typeof error.code !== undefined && error.code == "ERR_NETWORK") {
        localStorage.removeItem('qr_sess_data');
        localStorage.removeItem('user_sess_data');
        localStorage.removeItem('pod_sess_data');
        Vue.$toast.open({
            message: "Cloud server disconnected, logging out",
            type: "error",
            position: 'top-right',
            duration: 3000,
            dismissible: true
        });
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }
    else {
        return error;
    }
}

export function handleDocumentHeight() {
    // disable body scroll
    document.body.classList.add('overflow-hidden');
    setTimeout(function(){
        let totalHeight = 0;
        let headerHeight = document.getElementsByClassName('main-header');
        let logoHeight = document.getElementsByClassName('w-logo');
        let footerHeight = document.getElementsByClassName('w-footer-block');
        let footerInnerHeight = document.getElementsByClassName('w-footer-block-inner');
        if(headerHeight.length > 0) {
            totalHeight += headerHeight[0].offsetHeight;
            console.log(headerHeight[0].offsetHeight);
        }
        if(logoHeight.length > 0) {
            let computedStyle = getComputedStyle(logoHeight[0]);
            let marginHeights = (parseFloat(computedStyle.height) + parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom));
            if(!isNaN(marginHeights)) {
                totalHeight += marginHeights;
            }
            console.log(parseFloat(computedStyle.height) + parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom));
        }
        if(footerHeight.length > 0) {
            let computedStyle = getComputedStyle(footerHeight[0]);
            totalHeight += (parseFloat(computedStyle.height) + parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom));
        }
        if(footerInnerHeight.length > 0) {
            let computedStyle = getComputedStyle(footerInnerHeight[0]);
            totalHeight += (parseFloat(computedStyle.height) + parseFloat(computedStyle.marginTop) + parseFloat(computedStyle.marginBottom));
        }
        if(totalHeight > 0) {
            document.getElementsByClassName('main-content')[0].style.setProperty('height', 'calc(100vh - '+(totalHeight - 11)+'px)');
            document.getElementsByClassName('main-content')[0].style.setProperty('max-height', 'calc(100vh - '+(totalHeight - 11)+'px)');
            document.getElementsByClassName('main-content')[0].style.setProperty('min-height', 'calc(100vh - '+(totalHeight - 11)+'px)');
        }
    }, 1500);
}

export function handlePushMenuPolling(_self, type='beforelogin') {
    return setInterval(() => {
        // get xd types for buttons
        checkFlaskPodPushMenu().then(pm => {
            if (Object.prototype.hasOwnProperty.call(pm, "success")) {
                if(pm.success && typeof localStorage.pod_sess_data !== 'undefined') {
                    if(type == 'beforelogin' && (typeof localStorage.user_sess_data === 'undefined' || localStorage.user_sess_data === null || localStorage.user_sess_data === '')) {
                        if(_self.$route.path !== '/') {
                            _self.$router.push("/");
                        }
                    }
                    else if(type == 'afterlogin' && typeof localStorage.user_sess_data !== 'undefined') {
                        if(_self.$route.path !== '/player/dashboard' && _self.$route.path !== '/player/logout') {
                            _self.$router.push("/player/dashboard");
                        }
                    }
                }
            }
        });
    }, 2000);
}

export function checkIfValidCommonUser(to, from, next) {
    if(typeof localStorage.user_sess_data !== 'undefined') {
        let user_data = JSON.parse(localStorage.user_sess_data);
        if(to.path == '/common/alerts') {
            if (user_data.token && user_data.role_id && (user_data.role_id == 2 || user_data.role_id == 3 || user_data.role_id == 4)) {
                next(true);
            }
            else {
                localStorage.removeItem('user_sess_data');
                localStorage.removeItem('qr_sess_data');
                localStorage.removeItem('pod_sess_data');
                next({path : '/'});
            }
        }
        else {
            if (user_data.token && user_data.role_id && (user_data.role_id == 3 || user_data.role_id == 4)) {
                if(to.path == '/common') {
                    next('/common/users');
                }
                else {
                    next(true);
                }
            }
            else {
                localStorage.removeItem('user_sess_data');
                localStorage.removeItem('qr_sess_data');
                localStorage.removeItem('pod_sess_data');
                next({path : '/'});
            }
        }
    }
    else {
        localStorage.removeItem('user_sess_data');
        localStorage.removeItem('qr_sess_data');
        localStorage.removeItem('pod_sess_data');
        next({path : '/'});
    }
}

export async function handleIdlePolling() {
    return setInterval(() => {
        console.log("IDLE IS BEING CALLED");
        // check if there is an active session
        if(typeof localStorage.pod_sess_data !== 'undefined') {
            let pod_asleep = localStorage.getItem('pod_asleep');
            let pod_idle = localStorage.getItem('pod_idle');
            // now check for pod idle and see if exists
            return checkFlaskPodAsleep().then(pa => {
                // send cloud api request
                let data = {};
                let pod_data = JSON.parse(localStorage.pod_sess_data);
                data.session_id_key = pod_data.session_id_key;
                data.pod_id_key = pod_data.pod_id_key;
                data.activity = '';
                
                if (Object.prototype.hasOwnProperty.call(pa, "success") && pa.success) {
                    // check if asleep already recorded
                    if(typeof pod_asleep !== 'undefined' && pod_asleep == 'true') {
                        store.commit("setIdleFlag", false);
                        return null;
                    }
                    localStorage.setItem('pod_asleep', 'true');
                    localStorage.removeItem('pod_idle');
                    store.commit("setIdleFlag", false);
                    data.activity = global_config.ACTIVITIES.ASLEEP; 
                    // call flask activity
                    recordFlaskActivity(data);  
                    return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_SAVE}`, data)
                        .then(response => response.data)
                        .catch(error => {
                            console.log(error.response);
                            return error.response;
                        });
                }
                else {
                    // return checkFlaskPodIdle().then(fpi => {
                    //     if (Object.prototype.hasOwnProperty.call(fpi, "success") && fpi.success) {
                    //         // check if idle already recorded
                    //         if(typeof pod_idle !== 'undefined' && pod_idle == 'true') {
                    //             store.commit("setIdleFlag", true);
                    //             return null;
                    //         }
                    //         localStorage.setItem('pod_idle', 'true');
                    //         localStorage.removeItem('pod_asleep');
                    //         store.commit("setIdleFlag", true);
                    //         data.activity = global_config.ACTIVITIES.IDLE;   
                    //         // call flask activity
                    //         recordFlaskActivity(data);
                    //         return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_SAVE}`, data)
                    //         .then(response => response.data)
                    //         .catch(error => {
                    //             console.log(error.response);
                    //             return error.response;
                    //         });
                    //     }
                    //     else {
                    //         localStorage.removeItem('pod_asleep');
                    //         localStorage.removeItem('pod_idle');
                    //         store.commit("setIdleFlag", false);
                    //         if((typeof pod_asleep !== 'undefined' && pod_asleep == 'true') || (typeof pod_idle !== 'undefined' && pod_idle == 'true')) {
                    //             // get last activity so we could restore when idle/asleep deleted
                    //             let last_activity = localStorage.getItem('last_activity_data');
                    //             if(typeof last_activity !== 'undefined' && last_activity !== 'null') {
                    //                 let last_activity_data = JSON.parse(last_activity);
                    //                 sessionActivitySave(last_activity_data).then((response) => {
                    //                     console.log("ACTIVITY API CALLED : ", response);
                    //                 });
                    //             }
                    //             else {
                    //                 sessionActivitySave({activity: global_config.ACTIVITIES.UI_HOME}).then((response) => {
                    //                     console.log("ACTIVITY API CALLED : ", response);
                    //                 });
                    //             }
                    //             return null;
                    //         }
                    //     }
                    // });

                    if(typeof localStorage.user_sess_data !== 'undefined') {
                        localStorage.removeItem('pod_asleep');
                        localStorage.removeItem('pod_idle');
                        store.commit("setIdleFlag", false);
                        if((typeof pod_asleep !== 'undefined' && pod_asleep == 'true') || (typeof pod_idle !== 'undefined' && pod_idle == 'true')) {
                            // get last activity so we could restore when idle/asleep deleted
                            let last_activity = localStorage.getItem('last_activity_data');
                            if(typeof last_activity !== 'undefined' && last_activity !== 'null') {
                                let last_activity_data = JSON.parse(last_activity);
                                sessionActivitySave(last_activity_data).then((response) => {
                                    console.log("ACTIVITY API CALLED : ", response);
                                });
                            }
                            else {
                                sessionActivitySave({activity: global_config.ACTIVITIES.UI_HOME}).then((response) => {
                                    console.log("ACTIVITY API CALLED : ", response);
                                });
                            }
                            return null;
                        }
                    }
                    else {
                        // check if idle already recorded
                        if(typeof pod_idle !== 'undefined' && pod_idle == 'true') {
                            store.commit("setIdleFlag", true);
                            return null;
                        }

                        // update inactive counter
                        let last_activity = Math.floor((new Date()).getTime() - (new Date(localStorage.getItem('last_activity'))).getTime());
                        store.commit("setInactiveCounter", last_activity);
                        if(last_activity >= process.env.VUE_APP_INACTIVITY_DURATION) {
                            localStorage.setItem('pod_idle', 'true');
                            localStorage.removeItem('pod_asleep');
                            store.commit("setIdleFlag", true);
                            data.activity = global_config.ACTIVITIES.IDLE;   
                            // call flask activity
                            recordFlaskActivity(data);
                            return axios.post(`${cloudAPI}${cloud_api_routes.SESSION_SAVE}`, data)
                            .then(response => response.data)
                            .catch(error => {
                                console.log(error.response);
                                return error.response;
                            });
                        }
                        else {
                            localStorage.removeItem('pod_asleep');
                            localStorage.removeItem('pod_idle');
                            store.commit("setIdleFlag", false);
                            if((typeof pod_asleep !== 'undefined' && pod_asleep == 'true') || (typeof pod_idle !== 'undefined' && pod_idle == 'true')) {
                                // get last activity so we could restore when idle/asleep deleted
                                let last_activity = localStorage.getItem('last_activity_data');
                                if(typeof last_activity !== 'undefined' && last_activity !== 'null') {
                                    let last_activity_data = JSON.parse(last_activity);
                                    sessionActivitySave(last_activity_data).then((response) => {
                                        console.log("ACTIVITY API CALLED : ", response);
                                    });
                                }
                                else {
                                    sessionActivitySave({activity: global_config.ACTIVITIES.UI_HOME}).then((response) => {
                                        console.log("ACTIVITY API CALLED : ", response);
                                    });
                                }
                                return null;
                            }
                        }
                    }
                }
            });
        }
    }, 2000);
}

export async function handleActivityUpdatePolling() {
    return setInterval(() => {
        sessionActivityUpdate().then((response) => {
            console.log("ACTIVITY UPDATE API CALLED : ", response);
        });
    }, 60000);
}

export function getXdButtonIcons(xd) {
    let xd_icons = {
        xb_icon: xb_icon,
        sw_icon: sw_icon,
        pc_icon: pc_icon,
        ps_icon: ps_icon
    };
    return xd_icons[xd+"_icon"];
}

export async function handlePodButtonsPolling() {
    return setInterval(() => {
        // check if there is an active session
        if(typeof localStorage.pod_sess_data !== 'undefined') {
            return checkFlaskPodScreenButtons().then(pa => {
                if (Object.prototype.hasOwnProperty.call(pa, "success") && pa.success) {
                    if(pa.data !== undefined && typeof pa.data.value !== undefined && (pa.data.value == 2 || pa.data.value == 3 || pa.data.value == 4)) {
                        store.commit("setPodScreenButtonsFlag", true);
                        document.title = 'GameLab - Buttons';
                    }
                    else {
                        store.commit("setPodScreenButtonsFlag", false);
                        if(!store.getters.getIdleState()) {
                            if (
                                Object.prototype.hasOwnProperty.call(router, "history") && 
                                Object.prototype.hasOwnProperty.call(router.history, "current") && 
                                Object.prototype.hasOwnProperty.call(router.history.current, "meta") && 
                                Object.prototype.hasOwnProperty.call(router.history.current.meta, "title")
                            ) {
                                document.title = router.history.current.meta.title;
                            }
                        }
                        else {
                            document.title = 'GameLab - Idle';
                        }
                    }
                }
                else {
                    store.commit("setPodScreenButtonsFlag", false);
                    if(!store.getters.getIdleState()) {
                        if (
                            Object.prototype.hasOwnProperty.call(router, "history") && 
                            Object.prototype.hasOwnProperty.call(router.history, "current") && 
                            Object.prototype.hasOwnProperty.call(router.history.current, "meta") && 
                            Object.prototype.hasOwnProperty.call(router.history.current.meta, "title")
                        ) {
                            document.title = router.history.current.meta.title;
                        }
                    }
                    else {
                        document.title = 'GameLab - Idle';
                    }
                }
            });
        }
    }, 2000);
}