<template>
    <div class="main-layout-section admin-portal secondary">
        <AdminHeader 
        @setPodData="setPodData($event)" />
        
        <div class="w-logo mb-3">
            <img :src="logo_file" alt="logo" class="w-input-fields animate__animated animate__fadeIn animate__fast" v-if="logo_file !== '' && logo_file !== null"/>
        </div>

        <div class="main-content">
            <h3 class="page-subheading mb-3 animate__animated animate__fadeIn animate__fast">Create New Reservation</h3>

            <div class="col-md-11 mx-auto">

                <div class="admin-general-section">
                    <div class="admin-general-section-buttons">
                        <router-link :to="`/admin/reservations`" class="admin-general-section-button">
                            Back To Reservations Management
                        </router-link>
                    </div>
                </div>

                <div class="row m-0 p-0 col-md-12 col-sm-12 m-auto">
                    <!-- start of pod form -->
                    <b-form inline @submit.stop.prevent class="m-0 p-0">

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Lab</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.lab_id.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-select
                                        v-model="form.lab_id"
                                        :options="labs"
                                        text-field="value"
                                        value-field="id"
                                        id="lab_id"
                                        class="" 
                                        @change="podsByLab"
                                        :class="[ $v.form.lab_id.$error ? 'is-invalid' : '', form.lab_id == '' ? 'text-grey' : '' ]"
                                        placeholder="Select a lab"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Select Pods</label>
                                <div class="pods-list-container animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.pods_list.$error }">
                                    <div v-if="pods.length > 0" class="w-100">
                                        <span>Select All</span>
                                        <label class="gen-switch tickcross">
                                            <input type="checkbox" 
                                            ref="select_all_pods" 
                                            @change="handleSelectPods('all')"
                                            >
                                            <span class="gen-switch-slider gen-switch-round tickcross"></span>
                                        </label>
                                    </div>

                                    <div v-for="pod in pods" :key="pod.id">
                                        <span>{{pod.license}}</span>
                                        <label class="gen-switch tickcross">
                                            <input type="checkbox" 
                                            :ref="pod.pod_id_key+'_select_pod'" 
                                            :class="pod.id+'-pod-id'"
                                            :checked="selected_pods.indexOf(pod.pod_id_key) !== -1" 
                                            @change="handleSelectPods('', pod.pod_id_key, pod.pod_id_key+'_select_pod')"
                                            >
                                            <span class="gen-switch-slider gen-switch-round tickcross"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div class="row">
                            <div class="d-flex mb-1 col-md-4 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Start Date</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.start_date.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <input
                                        type="text"
                                        value=""
                                        placeholder="MM/DD/YYYY"
                                        v-model="form.start_date" 
                                        v-mask="date_mask"
                                    />
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">Start Time</label>
                                <!-- <b-form-timepicker :reset-button="true" :no-flip="true" v-model="form.start_time" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.start_time.$error }"></b-form-timepicker> -->
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeInUp animate__delay-2s">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="startContext"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="form.start_time"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.form.start_time.$error }" 
                                        v-mask="'?##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                            <div class="d-flex mb-1 col-md-4 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">End Date</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.end_date.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <input
                                        type="text"
                                        value=""
                                        placeholder="MM/DD/YYYY"
                                        v-model="form.end_date" 
                                        v-mask="date_mask"
                                    />
                                </div>
                            </div>
                            <div class="d-flex mb-1 col-md-2 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeInUp animate__delay-2s w-100">End Time</label>
                                <!-- <b-form-timepicker :reset-button="true" :no-flip="true" v-model="form.end_time" dropup class="overflow-visible timepicker-schedule w-form-control w-input-fields animate__animated animate__fadeInUp animate__delay-2s w-100" :class="{ 'is_invalid': $v.form.end_time.$error }"></b-form-timepicker> -->
                                <b-input-group class="timepicker-schedule animate__animated animate__fadeInUp animate__delay-2s">
                                    <b-input-group-append>
                                        <b-form-timepicker
                                        button-only 
                                        dropup 
                                        left 
                                        @context="endContext"
                                        ></b-form-timepicker>
                                    </b-input-group-append>
                                    <b-form-input
                                        v-model="form.end_time"
                                        type="text"
                                        placeholder="HH:mm AM/PM" 
                                        class="w-form-control w-input-fields mb-0" 
                                        :class="{ 'is_invalid': $v.form.end_time.$error }" 
                                        v-mask="'?##:## AA'"
                                    ></b-form-input>
                                </b-input-group>
                            </div>

                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Group Name</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.group_name.$error }">
                                    <span><i class="fa-solid fa-file"></i></span>
                                    <b-form-input
                                        v-model="form.group_name"
                                        id="rss-url"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 h-53"
                                        :class="{ 'is-invalid': $v.form.group_name.$error }"
                                        placeholder="Enter group name"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-flex mb-1 col-md-12 flex-column">
                                <label for="" class="form-main-label animate__animated animate__fadeIn animate__fast w-100">Emails List</label>
                                <div class="w-form-control w-input-fields animate__animated animate__fadeIn animate__fast w-100" :class="{ 'is_invalid': $v.form.emails_list.$error }">
                                    <b-form-textarea
                                        v-model="form.emails_list"
                                        id="note"
                                        class="mb-2 mr-sm-2 mb-sm-0 user-name bg-lightBlack border-0 w-textarea"
                                        :class="{ 'is-invalid': $v.form.emails_list.$error }"
                                        placeholder="Emails List"
                                        no-resize
                                    ></b-form-textarea>
                                </div>
                            </div>
                        </div>

                        <div
                        class="
                        w-form-control
                        animate__animated animate__fadeIn animate__fast m-0 p-0
                        "
                        >
                            <a
                            href="javascript:void(0)"
                            @click="handleReservationReq"
                            class="flex-fill"
                            >Save</a
                            >
                        </div>

                    </b-form>
                    <!-- end of pod form -->
                </div>

            </div>

        </div>

        <!-- start of footer section -->
        <div class="col-md-12 mx-auto">
            <AdminFooter type="inner" />
        </div>
        <!-- end of footer section -->
        
    </div>
</template>

<script>
import AdminHeader from "@/views/admin/AdminHeader.vue";
import { adminSaveReservation, adminGetLabsList, adminGetPodsList } from "../../services/AdminService";
import Vue from 'vue'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import { required } from 'vuelidate/lib/validators';
import AdminFooter from "@/views/admin/AdminFooter.vue";

export default {
    name: "AdminNewReservation-view",
    components: { AdminHeader, AdminFooter },
    data() {
        return {
            labs: [],
            pods: [],
            selected_pods: [],
            form: {
                client_id : '',
                lab_id : '',
                start_date : '',
                start_time : '',
                end_date : '',
                end_time : '',
                pods_list : '',
                emails_list : '',
                group_name : ''
            },
            date_mask: '##/##/##?#?#',
        };
    },

    validations: {
        form : {
            lab_id : {required},
            start_date : {required},
            start_time : {required},
            end_date : {required},
            end_time : {required},
            pods_list : {required},
            emails_list : {required},
            group_name: {required}
        }
    },

    computed: {
        logo_file() {
            return this.$store.getters.getThemeLogo().logo;
        }
    },

    methods: {
        labsList() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: user_data.client_id
            };
            adminGetLabsList(params).then(response => {
                this.labs = response.data.labs;
                // add empty option as placeholder
                this.labs.splice(0, 0, {'id' : '', 'value' : 'Select a lab'});
            });
        },

        podsByLab() {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: user_data.client_id,
                lab_id: this.form.lab_id
            };
            adminGetPodsList(params).then(response => {
                this.pods = response.data.pods;
            });
        },

        handleSelectPods(type="", pod_id, ref_id) {
            let _self = this;
            if(type == "all") {
                this.selected_pods = [];
                if(this.$refs.select_all_pods.checked) {
                    this.pods.forEach((item) => {
                        _self.selected_pods.push(item.pod_id_key);
                    });
                }
            }
            else {
                if(this.$refs[ref_id][0].checked) {
                    this.selected_pods.push(pod_id);
                }
                else {
                    this.selected_pods.splice(this.selected_pods.indexOf(pod_id), 1);
                }
                if(this.pods.length == this.selected_pods.length) {
                    this.$refs.select_all_pods.checked = true;
                }
                else {
                    this.$refs.select_all_pods.checked = false;
                }
            }
            if(this.selected_pods.length > 0) {
                this.form.pods_list = this.selected_pods.join(',');
            }
            else {
                this.form.pods_list = '';
            }
        },

        handleReservationReq() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.makeToast("error", "generic-required-message");
            } 
            else {
                let user_data = JSON.parse(localStorage.user_sess_data);
                let data = this.form;
                data.token = user_data.token;
                data.client_id = user_data.client_id
                data.start_datetime = data.start_date + " " + data.start_time;
                data.end_datetime = data.end_date + " " + data.end_time;

                adminSaveReservation(data).then(response => {
                    if(response.status){
                        this.makeToast("success", response.message);
                        this.selected_pods = [];
                        this.resetForm();
                        this.$v.$reset();
                    } else {
                        this.makeToast("error", response.message);
                    }
                }); 
            }
        },

        resetForm() {
            var self = this;
            //Iterate through each object field, key is name of the object field`
            Object.keys(this.form).forEach(function(key) {
                self.form[key] = '';
            });
        },

        makeToast(variant = "default", message) {
            this.$root.$emit("toast_message", {'type' : variant, 'message' : message});
        },

        setPodData(obj) {
            this[obj.key] = obj.value;
        },

        startContext(ctx) {
            if(ctx.minutes >= 0) {
                let ts = ctx.formatted.split(" ");
                if(ts.length > 1 && ts[0].includes(':')) {
                    let ts1 = ts[0].split(":");
                    let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                    this.form.start_time = ts_final;
                }
            }
        },
        endContext(ctx) {
            if(ctx.minutes >= 0) {
                let ts = ctx.formatted.split(" ");
                if(ts.length > 1 && ts[0].includes(':')) {
                    let ts1 = ts[0].split(":");
                    let ts_final = (ts1[0] < 10 ? '0'+ts1[0] : ts1[0]) + ":" + ts1[1] + " " + ts[1];
                    this.form.end_time = ts_final;
                }
            }
        },
  },

  mounted() {
    this.labsList();
  }

};
</script>
<style scoped>
.pods-list-container {
    display: flex;
    flex-direction: row;
    padding: 10px 10px;
    column-gap: 20px;
    background: #d8d8d8;
    margin: 10px 0px;
    border-radius: 8px;
    min-height: 56px;
    flex-wrap: wrap;
    row-gap: 15px;
}
.pods-list-container > div {
    display: flex;
    flex-direction: row;
    width: fit-content;
    color: #191919;
    font-weight: 700;
    align-items: center;
    column-gap: 10px;
}
/* start of general switches */
.gen-switch {
    position: relative;
    display: inline-block;
    width: 70px;
    height: 34px;
}
.gen-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.gen-switch-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7f7f7f;
    -webkit-transition: .4s;
    transition: .4s;
}  
.gen-switch-slider:before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    left: 0px;
    bottom: -2px;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: var(--client-hover);
    font-family: "FontAwesome";
    display: flex;
    align-items: center;
    justify-content: center;;
    color: #d8d8d8;
}
.gen-switch input:checked + .gen-switch-slider:before {
    -webkit-transform: translateX(31px);
    -ms-transform: translateX(31px);
    transform: translateX(31px);
    background-color: var(--client-primary);
}
.gen-switch-slider.gen-switch-round {
    border-radius: 34px;
}
.gen-switch-slider.gen-switch-round:before {
    border-radius: 50%;
}
.gen-switch-slider.lockunlock:before {
    content: "\f023";
}
.gen-switch.lockunlock input:checked + .gen-switch-slider.lockunlock:before {
    content: "\f13e";
}
.gen-switch-slider.tickcross:before {
    content: "\f00d";
}
.gen-switch.tickcross input:checked + .gen-switch-slider.tickcross:before {
    content: "\f00c";
}
.gen-switch-slider.playpause:before {
    content: "\f04c";
}
.gen-switch.playpause input:checked + .gen-switch-slider.playpause:before {
    content: "\f04b";
}
/* end of general switches */
</style>