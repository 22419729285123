import { getThemeLogoDetails } from "../../../services/PlayerService";
import { adminGetThemeAndLogo } from "../../../services/AdminService";
import { getters } from "./getters.js";
import default_logo from "../../../assets/images/gamelab-logo.svg";
import { handleActivityUpdatePolling, handlePodButtonsPolling, handleIdlePolling } from "../../../services/Guard";
import { global_config } from "@/config/config.js";
import { getPodId } from "../../../services/FlaskService";

export default {
    state() {
        return {
            logo: default_logo,
            theme: '',
            styles: '',
            is_fetched: false,
            is_idle: false,
            inactive_counter: -1,
            session_activity_updater: null,
            qr_refresh: false,
            qr_counter: global_config.QR_MAIN_TIMEOUT,
            qr_url: "",
            focused_element: null,
            focused_element_form: {'form': null, 'target': null},
            pod_screen_buttons_updater: null,
            is_pod_screen_buttons: false,
            idle_flag_updater: null,
        };
    },
    getters,
    mutations: {
        setThemeLogo(state, data) {
            if(data.logo !== '' && data.logo !== null) {
                state.logo = data.logo;
            }
            state.theme = data.theme;
            state.styles = data.styles;
            // replace styles in tag
            let elem = document.getElementById('client-styles');
            if(typeof elem != 'undefined' && elem != null) {
                elem.innerText = data.styles;
            }
        },
        fetchPlayerFacThemeLogo(state) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let pod_data = JSON.parse(localStorage.pod_sess_data);
            let params = {
                session_id_key : pod_data.session_id_key,
                token: user_data.token
            };
            getThemeLogoDetails(params).then(response => {
                if(response.status) {
                    state.logo = response.data.themelogo.logo_url;
                    state.theme = response.data.themelogo.theme;
                    state.styles = response.data.styles;
                    // replace styles in tag
                    let elem = document.getElementById('client-styles');
                    if(typeof elem != 'undefined' && elem != null && response.data.styles !== '') {
                        elem.innerText = response.data.styles;
                    }
                }
            });
        },
        fetchAdminThemeLogo(state) {
            let user_data = JSON.parse(localStorage.user_sess_data);
            let params = {
                token: user_data.token,
                client_id: user_data.client_id
            };
            adminGetThemeAndLogo(params).then(response => {
                if(response.status) {
                    state.theme = response.data.themelogo.theme;
                    state.styles = response.data.styles;
                    // check for logo
                    if(response.data.themelogo.logo_url !== '' && response.data.themelogo.logo_url !== null) {
                        state.logo = response.data.themelogo.logo_url;
                    }
                    // replace styles in tag
                    let elem = document.getElementById('client-styles');
                    if(typeof elem != 'undefined' && elem != null && response.data.styles !== '') {
                        elem.innerText = response.data.styles;
                    }
                }
                else {
                    state.logo = default_logo;
                    // replace styles in tag
                    let elem = document.getElementById('client-styles');
                    if(typeof elem != 'undefined' && elem != null) {
                        elem.innerText = '';
                    }
                }
            });
        },
        setIdleFlag(state, data) {
            state.is_idle = data;
        },
        setInactiveCounter(state, data) {
            state.inactive_counter = data;
        },
        setSessionActivityUpater(state, data) {
            data;
            if(state.session_activity_updater == null) {
                state.session_activity_updater = handleActivityUpdatePolling();
                console.log("This is activity udpater : ", state.session_activity_updater);
            }
        },
        setQRRefresh(state, data) {
            state.qr_refresh = data;
        },
        setQRCounter(state, data) {
            state.qr_counter = data;
        },
        setQRUrl(state, data) {
            state.qr_url = data;
        },
        setFocusedElement(state, data) {
            state.focused_element = data;
        },
        setFocusedElementForm(state, data) {
            state.focused_element_form = data;
        },
        setPodScreenButtonsUpater(state, data) {
            data;
            if(state.pod_screen_buttons_updater == null) {
                state.pod_screen_buttons_updater = handlePodButtonsPolling();
            }
        },
        setPodScreenButtonsFlag(state, data) {
            state.is_pod_screen_buttons = data;
        },
        setIdleFlagUpater(state, data) {
            data;
            if(state.idle_flag_updater == null) {
                getPodId().then((pod) => {
                    if (
                        pod !== null && typeof pod !== 'undefined' && 
                        Object.prototype.hasOwnProperty.call(pod, "success") &&
                        pod.success &&
                        Object.prototype.hasOwnProperty.call(pod, "data") &&
                        Object.prototype.hasOwnProperty.call(pod.data, "pod_id") &&
                        pod.data.pod_id.length > 0
                    ) {
                        state.idle_flag_updater = handleIdlePolling();
                    }
                });
            }
        },
    },
};
